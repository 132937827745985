import React, { useEffect, useState } from "react";
import VendorList from "./VendorList";
import { axiosPost } from "../../utils/AxiosApi";
import { URL, fileUrl } from "../../utils/Constants";
import Button from "../../components/common/Button";
import CommonModal from "../../components/common/Modal";
import swal from "sweetalert";

const Vendor = () => {
  const [VendorDataList, setVendorDataList] = useState();
  const [isOpen, setIsopen] = useState(false);
  const toggleModal = item => {
    setIsopen(!isOpen);
    setName(item?.name);
    setPanNumber(item?.pan_vat_no);
    setContactNumber(item?.contact_number);
    setContactPerson(item?.contact_person);
    setNatureOfBusiness(item?.nature_of_business);
    setPaymentTerms(item?.payment_terms);
    setEditId(item?.id);
    setUploadFile(item.media[0]);
  };
  const [editId, setEditId] = useState();
  const [name, setName] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [natureOfBusiness, setNatureOfBusiness] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [uploadFile, setUploadFile] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const getAllVendorList = () => {
    axiosPost(URL.getAllVendor, {}, response => {
      if (response.status === 200) {
        setVendorDataList(response?.data);
      }
    });
  };

  const handleNameChange = e => {
    setName(e.target.value);
  };
  const handlePanChange = e => {
    setPanNumber(e.target.value);
  };
  const handleContactNumberChange = e => {
    setContactNumber(e.target.value);
  };

  const handleContactPersonChange = e => {
    setContactPerson(e.target.value);
  };

  const handleNatureOfBusiness = e => {
    setNatureOfBusiness(e.target.value);
  };

  const handlePaymentTermsChange = e => {
    setPaymentTerms(e.target.value);
  };

  const handleFileChange = e => {
    setUploadFile(e.target.files[0]);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (name === undefined) {
      swal("Please Enter Name");
      return;
    } else if (panNumber === undefined) {
      swal("Please Enter Pan Number");
      return;
    } else if (contactNumber === undefined) {
      swal("Please Enter Contact Number");
      return;
    } else if (contactPerson === undefined) {
      swal("Please Enter Contact Person");
      return;
    } else if (natureOfBusiness === undefined) {
      swal("Please Enter Nature of Business");
      return;
    } else if (paymentTerms === undefined) {
      swal("Please Enter Payment Terms");
      return;
    } else if (uploadFile === "" || undefined) {
      swal("Please upload a logo ");
      return;
    }
    const params = {
      name: name,
      pan_vat_no: panNumber,
      contact_number: contactNumber,
      contact_person: contactPerson,
      nature_of_business: natureOfBusiness,
      payment_terms: paymentTerms,
      id: isEdit ? editId : null
    };

    var formData = new FormData();
    formData.append("data", JSON.stringify(params));
    if (isEdit) {
      formData.append("new_file", uploadFile);
    } else {
      formData.append("file", uploadFile);
    }

    const url = isEdit === false ? URL.createNewVendor : URL.editVendor;
    axiosPost(url, formData, response => {
      if (response.status === 201 || 200) {
        toggleModal();
        getAllVendorList();
      }
    });
  };

  useEffect(() => {
    getAllVendorList();
  }, []);

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Vendor List</div>
        <div>
          <Button name="Add Vendor" onClick={toggleModal} />
        </div>
        <VendorList
          VendorData={VendorDataList ? VendorDataList : null}
          getAllVendorList={getAllVendorList}
          setIsEdit={setIsEdit}
          toggleModal={toggleModal}
        />
      </div>
      <CommonModal
        modal={isOpen}
        toggleModal={toggleModal}
        size={"lg"}
        modalHeader={isEdit ? "Edit a vendor" : "Add a Vendor"}
        modalBody={
          <>
            <form onSubmit={e => handleSubmit(e)}>
              <div className="row mb-2">
                <div className="col-md-6">
                  <div className="input-group ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="name"
                      onChange={e => handleNameChange(e)}
                      value={name}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      id="basic-url"
                      name="panNumber"
                      aria-describedby="basic-addon3"
                      onChange={e => handlePanChange(e)}
                      placeholder="Pan/Vat Number"
                      value={panNumber}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      id="basic-url"
                      name="contactNumber"
                      aria-describedby="basic-addon3"
                      onChange={e => handleContactNumberChange(e)}
                      placeholder="Contact Number"
                      value={contactNumber}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="basic-url"
                      name="contactPerson"
                      aria-describedby="basic-addon3"
                      onChange={e => handleContactPersonChange(e)}
                      placeholder="Contact Person"
                      value={contactPerson}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="basic-url"
                      name="natureOfBusiness"
                      aria-describedby="basic-addon3"
                      onChange={e => handleNatureOfBusiness(e)}
                      placeholder="Nature of business"
                      value={natureOfBusiness}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="basic-url"
                      name="paymentTerms"
                      aria-describedby="basic-addon3"
                      onChange={e => handlePaymentTermsChange(e)}
                      placeholder="Payment Terms"
                      value={paymentTerms}
                    />
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control"
                      id="basic-url"
                      name="fileUpLoad"
                      aria-describedby="basic-addon3"
                      onChange={e => handleFileChange(e)}
                      // value={uploadFile ? uploadFile : ''}
                    />
                  </div>
                </div>
                {uploadFile.id ? (
                  <div className="row">
                    <div className="col-md-6">
                      <img
                        src={`${fileUrl}/${uploadFile?.id}/${uploadFile?.file_name}`}
                        height="100px"
                        width="100px"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="mt-2">
                <Button name="Submit" type="submit" />
              </div>
            </form>
          </>
        }
      />
    </>
  );
};
export default Vendor;
