import React, { Component, lazy } from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";
import DashboardTemplate from "../pages/DashboardTemplate";
import PrivateRoute from "./PrivateRoute";
import NotFound from "../components/NotFound";
import AcceptCompanyGuidelines from "../pages/DepartmentStaff/AcceptCompanyGuidelines";
import ContactSupport from "../pages/ContactSupport";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ForgotPassword/ResetPassword";
import DevFaq from "../pages/DevFaq";
// import LoginPage from "../pages/LoginPage/LoginPage";

const LazyLoginPage = lazy(() => import("../pages/LoginPage/LoginPage"));

class Routes extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            path="/"
            element={
              <LoginPath>
                <LazyLoginPage />
              </LoginPath>
            }
          />
          <Route
            path="/login"
            element={
              <LoginPath>
                <LazyLoginPage />
              </LoginPath>
            }
          />
          <Route
            path="dashboard/*"
            element={
              <PrivateRoute>
                <DashboardTemplate />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/acceptGuideline"
            element={<AcceptCompanyGuidelines />}
          />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:email" element={<ResetPassword />} />
          <Route path="/contactSupport" element={<ContactSupport />} />
          <Route path="/faq" element={<DevFaq />} />
        </Switch>
      </div>
    );
  }
}

function LoginPath({ children }) {
  let token = localStorage.getItem("token");
  let guidelineAccepted = JSON.parse(localStorage.getItem("guideLineAccepted"));

  if (token) {
    if (guidelineAccepted) {
      return <Navigate to="/dashboard" replace />;
    } else {
      return <Navigate to="/acceptGuideline" replace />;
    }
  } else {
    return children;
  }
}

function CheckGuideline({ children }) {
  let token = localStorage.getItem("token");
  let guidelineAccepted = JSON.parse(localStorage.getItem("guideLineAccepted"));
  if (token) {
    if (guidelineAccepted) {
      return <Navigate to="/dashboard" replace />;
    } else {
      return children;
    }
  } else {
    return <Navigate to="/" replace />;
  }
}

export default Routes;
