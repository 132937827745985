import Select from "react-select";
import Button from "../../components/common/Button";

const GroupEmailForm = ({ grpMail, users, setGrpMail, handleSubmit }) => {
  return (
    <>
      <div className="whiteContainer p-4">
        <form onSubmit={handleSubmit}>
          <>
            <label style={{ display: "contents" }} className="newTextColor">
              Group Email <span className="required">*</span>
            </label>
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                name="email"
                value={grpMail?.email}
                onChange={(e) => {
                  setGrpMail((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }));
                }}
                placeholder="Type your mail address here"
              />
            </div>
            <label style={{ display: "contents" }} className="newTextColor">
              Select Users <span className="required">*</span>
            </label>

            <div>
              <Select
                classNamePrefix="select"
                menuPosition={"fixed"}
                placeholder="User Mail"
                isMulti={true}
                isClearable={true}
                value={grpMail?.users}
                options={users}
                onChange={(e) => {
                  setGrpMail((prevState) => ({ ...prevState, users: e }));
                }}
                closeMenuOnSelect={false}
                closeMenuOnScroll={false}
              />
            </div>

            <br />
          </>

          <div className="mt-4 text-end">
            <Button name="Create" type="submit" />
          </div>
        </form>
      </div>
    </>
  );
};

export default GroupEmailForm;
