import React from "react";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

const ListLeaveCategory = (props) => {
  const {
    leaveCategory,
    handleDelete,
    handleEdit,
    isLoading,
    assignedPermission,
  } = props;

  // if (isLoading) {
  //   return (
  //     <div className="text-center">
  //       <Spinner />
  //     </div>
  //   );
  // }

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12 ml-auto mr-auto">
          <div className="table-responsive whiteContainer p-4">
            <table className="table table-sm table-custom">
              <thead>
                <tr>
                  <th className="text-center">SN.</th>
                  <th className="text-center">Leave Type</th>
                  <th className="text-center">Leave Basis</th>
                  <th className="text-center">Number of Days</th>
                  {assignedPermission &&
                  (assignedPermission.update || assignedPermission.delete) ? (
                    <th className="text-center">Actions</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {leaveCategory && leaveCategory.length > 0 ? (
                  leaveCategory.map((leave, id) => (
                    <tr key={id}>
                      <td className="text-center">{id + 1}</td>
                      <td className="text-center">{leave.leave_type}</td>
                      <td className="text-center">{leave.basis}</td>
                      <td className="text-center">{leave.number_of_days}</td>
                      {assignedPermission &&
                      (assignedPermission.update ||
                        assignedPermission.delete) ? (
                        <td className="text-center">
                          {assignedPermission && assignedPermission?.update ? (
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-outline-info rounded-circle btn-sm me-2"
                              data-original-title=""
                              title="Delete"
                              onClick={() => {
                                handleEdit(leave?.id);
                              }}
                            >
                              <i className="material-symbols-outlined">edit</i>
                            </button>
                          ) : null}
                          {assignedPermission && assignedPermission?.delete ? (
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-outline-danger rounded-circle btn-sm me-2"
                              data-original-title=""
                              title="Delete"
                              onClick={() => handleDelete(leave?.id)}
                            >
                              <i className="material-symbols-outlined">
                                delete
                              </i>
                            </button>
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td
                      colSpan={
                        assignedPermission &&
                        (assignedPermission.update || assignedPermission.delete)
                          ? 5
                          : 4
                      }
                    >
                      {isLoading ? (
                        <LoadingAnimation spinner />
                      ) : (
                        "No leave category added."
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListLeaveCategory;
