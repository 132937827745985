import React from "react";
import FileViewer from "react-file-viewer";
import ErrorFallback from "../../utils/ErrorFallback";

function CustomFileViewer(props) {
  const imgTypes = ["png", "jpeg", "jpg", "tiff"];
  const pdfType = "pdf";

  const fileExtension = props.fileExtension?.toLowerCase();

  return (
    <div className="col text-center">
      {imgTypes.includes(fileExtension) ? (
        <img
          src={props.filePath}
          style={{ position: "relative", width: "41vw" }}
          alt="File Preview"
        />
      ) : fileExtension === pdfType ? (
        <iframe
          src={props.filePath}
          width="100%"
          height="500px"
          title="PDF Preview"
        />
      ) : fileExtension && props.filePath ? (
        <FileViewer
          fileType={fileExtension}
          filePath={props.filePath}
          errorComponent={ErrorFallback}
        />
      ) : (
        null
      )}
    </div>
  );
}

export default CustomFileViewer;