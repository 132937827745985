import React, { useEffect, useState } from "react";
import "../../../assets/scss/departmentStaff.scss";
import CompanyDetails from "./CompanyDetails";
import ContactDetails from "./ContactDetails";
import User from "./User";
import swal from "sweetalert";
import { URL } from "../../../utils/Constants";
import { axiosPost, axiosGet } from "../../../utils/AxiosApi";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import { Spinner } from "reactstrap";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  checkIfEmpty,
  phoneValidate,
  emailValidate,
  CheckIsValidDomain,
  capitalizeFirstLetter,
  checkIsValidPassword,
  convertToCamelCase,
} from "../../../utils/Validation";
import {
  checkRolePermissions,
  checkRoles,
} from "../../../utils/PermissionChecker";

const defaultContactDetails = [
  {
    firstName: "",
    middleName: "",
    lastName: "",
    address: "",
    personContactNumber: "",
    email: "",
    designation: "",
    profilePic: "",
  },
];

const CompanyForm = ({
  toggleModal,
  getCompany,
  companyData,
  setCompanyData,
  editParam,
  // industryData,
  editableCmp,
  setEditableCmp,
  companyFiles,
  handleRemoveCmpFile,
  companyInfo,
  updateCompanyProfile,
  updateCompany,
  SetCompanyProfileInfo,
  fetchCompanyInfo,
}) => {
  const [step, setStep] = useState(1);
  const [submitSpinner, setSubmitSpinner] = useState(false);

  const [company, setCompany] = useState(null);
  const [contactDetails, setContactDetails] = useState([
    ...defaultContactDetails,
  ]);
  const [user, setUser] = useState(null);
  const [rolePermission, setRolePermission] = useState(false);
  const [updatedMediaFiles, setUpdatedMediaFiles] = useState(null);
  const [industryData, setIndustryData] = useState(null);
  const [reg, setRegFile] = useState(null);

  const handleRemoveCompanyInfo = (e) => {
    const cloneFileName = { ...reg };
    setRegFile(null);
  };

  useEffect(() => {
    const reg = companyInfo?.company?.media?.find(
      (cmp) => cmp.collection_name === "company_registration"
    );
    if (reg) setRegFile(reg);
  }, [companyInfo?.id]);

  const getIndustry = () => {
    axiosGet(
      URL.getAllIndustry,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;
          setIndustryData(modules);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  useEffect(() => {
    setContactDetails([
      {
        firstName: "",
        middleName: "",
        lastName: "",
        address: "",
        personContactNumber: "",
        email: "",
        designation: "",
        profilePic: "",
      },
    ]);
    getIndustry();
  }, []);

  const checkRolePermissions = () => {
    let rolePermission = checkRoles("Developer Admin");
    setRolePermission(rolePermission);
  };

  const handleNext = () => {
    if (step === 1) {
      if (editParam?.isEdit) {
        const cloneCmpData = [...editableCmp];
        const { contact, media, setting, user, ...requiredData } =
          cloneCmpData[editParam?.selectedIndex];
        const {
          parent_company_id,
          updated_at,
          user_id,
          domain_name,
          is_domain,
          isDomain,
          created_at,
          id,
          ...dataToBeChecked
        } = requiredData;
        const reqValue = checkIfEmpty(dataToBeChecked);
        if (reqValue?.isEmpty) {
          let msg = capitalizeFirstLetter(reqValue?.emptyKey);
          swal("Warning", `${msg} field missing`, "warning");
          return;
        }

        if (!isValidPhoneNumber(dataToBeChecked.contact_number)) {
          swal("Warning", `Invalid contact number `, "warning");
          return;
        }

        if (!emailValidate(dataToBeChecked.email_address)) {
          swal("Warning", "Invalid email address", "warning");
          return;
        }
        // check domain_name,logo amnd registration file

        if (isDomain === false && domain_name === "") {
          if (domain_name === "") {
            swal("Warning", "Missing domain name", "warning");
            return;
          }
        }

        if (isDomain === false && domain_name !== "") {
          if (!CheckIsValidDomain(domain_name)) {
            swal("Warning", "Invalid domain", "warning");
            return;
          }
        }

        //files exists on companyFiles and updatedMEdiaFiles
        // const a = updatedMediaFiles;
        if (!companyFiles?.registration) {
          if (updatedMediaFiles === null) {
            swal("Warning", "Registration file missing", "warning");
            return;
          }
          if (!("registration" in updatedMediaFiles)) {
            swal("Warning", "Registration file missing", "warning");
            return;
          }
        }

        setStep((prevState) => prevState + 1);
      } else if (!editParam?.isEdit && !updateCompanyProfile) {
        if (company === null) {
          swal("Warning", "Field with asterisk(*) are mandatory", "warning");
          return;
        }

        const {
          hasSisterConcern,
          canHaveBranch,
          domainName,
          isDomain,
          ...reqCompany
        } = company;

        if (Object.keys(reqCompany).length < 8) {
          swal("Warning", "Field with asterisk(*) are mandatory", "warning");
          return;
        }

        const { contactNumber, emailAddress } = reqCompany;

        if (contactNumber === undefined) {
          swal("Warning", "Missing phone number", "warning");
          return;
        }
        if (contactNumber !== null && contactNumber !== "") {
          if (!isValidPhoneNumber(contactNumber)) {
            swal("Warning", "Invalid phone number", "warning");
            return;
          }
        }
        if (emailAddress !== null && emailAddress !== "") {
          if (!emailValidate(emailAddress)) {
            swal("Warning", "Invalid email address", "warning");
            return;
          }
        }

        if (!company?.isDomain) {
          if (domainName !== null || domainName !== "") {
            if (!CheckIsValidDomain(domainName)) {
              swal("Warning", "Invalid domain", "warning");
              return;
            }
          }
        }

        let reqValue = checkIfEmpty(reqCompany);
        if (reqValue?.isEmpty) {
          let msg = capitalizeFirstLetter(reqValue?.emptyKey);
          swal("Warning", `Missing ${msg} field`, "warning");
        } else {
          setStep((prevState) => prevState + 1);
        }
      } else {
        const cloneCmpInfo = { ...companyInfo.company };
        const { contact, media, setting, user, ...requiredData } = cloneCmpInfo;
        const {
          parent_company_id,
          updated_at,
          user_id,
          domain_name,
          is_domain,
          isDomain,
          created_at,
          id,
          ...dataToBeChecked
        } = requiredData;
        const reqValue = checkIfEmpty(dataToBeChecked);
        if (reqValue?.isEmpty) {
          let msg = capitalizeFirstLetter(reqValue?.emptyKey);
          swal("Warning", `${msg} field missing`, "warning");
          return;
        }

        if (!isValidPhoneNumber(dataToBeChecked.contact_number)) {
          swal("Warning", `Invalid contact number `, "warning");
          return;
        }

        if (!emailValidate(dataToBeChecked.email_address)) {
          swal("Warning", "Invalid email address", "warning");
          return;
        }
        // check domain_name,logo amnd registration file
        if (isDomain === false && domain_name === "") {
          if (domain_name === "") {
            swal("Warning", "Missing domain name", "warning");
            return;
          }
        }

        if (isDomain === false && domain_name !== "") {
          if (!CheckIsValidDomain(domain_name)) {
            swal("Warning", "Invalid domain", "warning");
            return;
          }
        }

        //files exists on companyFiles and updatedMEdiaFiles
        // const a = updatedMediaFiles;
        if (!reg) {
          if (updatedMediaFiles === null) {
            swal("Warning", "Registration file missing", "warning");
            return;
          }
          if (!("registration" in updatedMediaFiles)) {
            swal("Warning", "Registration file missing", "warning");
            return;
          }
        }

        setStep((prevState) => prevState + 1);
      }
    }

    if (step === 2) {
      if (editParam?.isEdit) {
        const cloneCmpData = [...editableCmp];
        const { contact } = cloneCmpData[editParam?.selectedIndex];
        let requiredContactDetails = [];
        contact.forEach((cd) => {
          const {
            middle_name,
            company_id,
            created_at,
            id,
            updated_at,
            ...reqCD
          } = cd;
          requiredContactDetails.push(reqCD);
        });

        let reqValue;
        let isEmpty = false;
        for (let contDt of requiredContactDetails) {
          reqValue = checkIfEmpty(contDt);
          if (reqValue?.isEmpty) {
            isEmpty = true;
            let msg = capitalizeFirstLetter(reqValue?.emptyKey);
            const finalMsg = convertToCamelCase(msg);
            swal("Warning", `Missing ${finalMsg} field`, "warning");
          }
          if (contDt.email !== null && contDt.email !== "") {
            if (!emailValidate(contDt.email)) {
              swal("Warning", "Invalid email address", "warning");
              return;
            }
          }

          if (
            contDt.person_contact_number !== null &&
            contDt.person_contact_number !== ""
          ) {
            if (!isValidPhoneNumber(contDt.person_contact_number)) {
              swal("Warning", "Invalid person contact number", "warning");
              return;
            }
          }
          //check for media files
          if (contDt?.media === "") {
            swal("Warning", "Image field is missing", "warning");
            return;
          }
        }

        !isEmpty && setStep((prevState) => prevState + 1);
      } else if (!editParam?.isEdit && !updateCompanyProfile) {
        let requiredContactDetails = [];
        contactDetails.forEach((cd) => {
          const { middleName, ...reqCD } = cd;
          requiredContactDetails.push(reqCD);
        });
        let reqValue;
        for (let contDt of requiredContactDetails) {
          reqValue = checkIfEmpty(contDt);
          if (contDt.email !== null && contDt.email !== "") {
            if (!emailValidate(contDt.email)) {
              swal("Warning", "Invalid email address", "warning");
              return;
            }
          }

          if (contDt.personContactNumber === undefined) {
            swal("Warning", "Missing contact number", "warning");
            return;
          }
          if (
            contDt.personContactNumber !== null &&
            contDt.personContactNumber !== ""
          ) {
            if (!isValidPhoneNumber(contDt.personContactNumber)) {
              swal("Warning", "Invalid contact number", "warning");
              return;
            }
          }

          if (reqValue?.isEmpty) {
            let msg = capitalizeFirstLetter(reqValue?.emptyKey);
            swal("Warning", `Missing ${msg} field`, "warning");
          }
        }

        if (reqValue?.isEmpty) {
          let msg = capitalizeFirstLetter(reqValue?.emptyKey);
          swal("Warning", `Missing ${msg} Field`, "warning");
        } else {
          setStep((prevState) => prevState + 1);
        }
      } else {
        const cloneCmpInfo = { ...companyInfo.company };
        const { contact } = cloneCmpInfo;
        let requiredContactDetails = [];
        contact.forEach((cd) => {
          const {
            middle_name,
            company_id,
            created_at,
            id,
            updated_at,
            ...reqCD
          } = cd;
          requiredContactDetails.push(reqCD);
        });

        let reqValue;
        let isEmpty = false;
        for (let contDt of requiredContactDetails) {
          reqValue = checkIfEmpty(contDt);
          if (reqValue?.isEmpty) {
            isEmpty = true;
            let msg = capitalizeFirstLetter(reqValue?.emptyKey);
            const finalMsg = convertToCamelCase(msg);
            swal("Warning", `Missing ${finalMsg} field`, "warning");
          }
          if (contDt.email !== null && contDt.email !== "") {
            if (!emailValidate(contDt.email)) {
              swal("Warning", "Invalid email address", "warning");
              return;
            }
          }

          if (
            contDt.person_contact_number !== null &&
            contDt.person_contact_number !== ""
          ) {
            if (!isValidPhoneNumber(contDt.person_contact_number)) {
              swal("Warning", "Invalid person contact number", "warning");
              return;
            }
          }
          //check for media files
          if (contDt?.media === "") {
            swal("Warning", "Image field is missing", "warning");
            return;
          }
        }

        !isEmpty && setStep((prevState) => prevState + 1);
      }
    }
  };

  const handlePrevious = () => {
    setStep((prevState) => prevState - 1);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    let formData = new FormData();
    if (editParam?.isEdit) {
      const {
        contact,
        created_at,
        isDomain,
        domain_name,
        media,
        setting,
        updated_at,
        user,
        user_id,
        id,
        ...finalCompanyDetails
      } = editableCmp[editParam.selectedIndex];

      const companyStng = {
        has_sister_concern: setting.has_sister_concern ? 1 : 0,
        can_have_branch: setting.can_have_branch ? 1 : 0,
      };

      const compSetting = JSON.stringify(companyStng);
      formData.append("company_settings", compSetting);

      // if (companyFiles === null || Object.keys(companyFiles).length === 0) {
      updatedMediaFiles?.logo &&
        formData.append("logo[]", updatedMediaFiles?.logo);
      updatedMediaFiles?.registration &&
        formData.append("registration[]", updatedMediaFiles?.registration);
      // }

      let finalContactDetails = [];
      let pictureDetails = [];
      contact.forEach((cD, id) => {
        const {
          media,
          company_id,
          created_at,
          updated_at,
          middle_name,
          ...reqcD
        } = cD;
        !Array.isArray(media) && pictureDetails.push({ index: id });
        finalContactDetails.push({
          ...reqcD,
          middle_name: middle_name ?? null,
        });
        !Array.isArray(media) && formData.append("profile_picture[]", media);
      });

      const finalCompany = {
        ...finalCompanyDetails,
        // id: user_id,
        id: id,
        domain_name: !isDomain ? domain_name : null,
        contactPerson: finalContactDetails,
      };
      var companyDetail = JSON.stringify(finalCompany);
      formData.append("company", companyDetail);

      if (pictureDetails.length > 0) {
        var picDetail = JSON.stringify(pictureDetails);
        formData.append("picture_details", picDetail);
      }
    } else {
      const {
        contact_persons,
        created_at,
        isDomain,
        domain_name,
        media,
        has_settings,
        updated_at,
        user,
        user_id,
        id,
        ...finalCompanyDetails
      } = companyInfo?.company;
      //data req
      const companyStng = {
        has_sister_concern: has_settings.has_sister_concern ? 1 : 0,
        can_have_branch: has_settings.can_have_branch ? 1 : 0,
      };

      const compSetting = JSON.stringify(companyStng);
      formData.append("company_settings", compSetting);

      if (reg === null || Object.keys(reg).length === 0) {
        updatedMediaFiles?.registration &&
          formData.append("registration[]", updatedMediaFiles?.registration);
      }

      if (updatedMediaFiles?.logo) {
        formData.append("logo[]", updatedMediaFiles?.logo);
      }

      let finalContactDetails = [];
      let pictureDetails = [];
      contact_persons.forEach((cD, id) => {
        const {
          media,
          company_id,
          created_at,
          updated_at,
          middle_name,
          ...reqcD
        } = cD;
        !Array.isArray(media) && pictureDetails.push({ index: id });
        finalContactDetails.push({
          ...reqcD,
          middle_name: middle_name ?? null,
        });
        !Array.isArray(media) && formData.append("profile_picture[]", media);
      });

      const finalCompany = {
        ...finalCompanyDetails,
        // id: user_id,
        id: id,
        domain_name: !isDomain ? domain_name : null,
        contactPerson: finalContactDetails,
      };
      var companyDetail = JSON.stringify(finalCompany);
      formData.append("company", companyDetail);

      if (pictureDetails.length > 0) {
        var picDetail = JSON.stringify(pictureDetails);
        formData.append("picture_details", picDetail);
      }
    }

    setSubmitSpinner(true);
    axiosPost(
      URL.updateCompany,
      formData,
      (res) => {
        if (res.data.success) {
          setSubmitSpinner(false);
          toggleModal();
          swal("Success", "Company updated successfully", "success");
          updateCompanyProfile ? fetchCompanyInfo() : getCompany();
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleSubmit = (e) => {
    //check user
    e.preventDefault();
    if (user === null) {
      swal("Warning", "Field with asterisk(*) are mandatory", "warning");
      return;
    }
    const { middleName, ...reqUser } = user;
    if (Object.keys(reqUser) < 5) {
      swal("Warning", "Field with asterisk(*) are mandatory", "warning");
      return;
    }
    const { email, password, confirmPassword } = reqUser;
    if (email !== null && email !== "") {
      if (!emailValidate(email)) {
        swal("Warning", "Invalid email address", "warning");
        return;
      }
    }
    if (confirmPassword === undefined) {
      swal("Warning", "Missing confirmn password field", "warning");
      return;
    }

    let reqValue = checkIfEmpty(reqUser);
    if (reqValue?.isEmpty) {
      let msg = capitalizeFirstLetter(reqValue?.emptyKey);
      swal("Warning", `Missing ${msg} field `, "warning");
      return;
    }

    let formData = new FormData();

    const {
      logo,
      registration,
      parentCompanyId,
      hasSisterConcern,
      canHaveBranch,
      ...finalCompanyDetail
    } = company;

    const companyStng = {
      has_sister_concern: hasSisterConcern ? 1 : 0,
      can_have_branch: canHaveBranch ? 1 : 0,
    };

    const compSetting = JSON.stringify(companyStng);
    formData.append("company_settings", compSetting);

    formData.append("logo[]", logo);
    formData.append("registration[]", registration);

    var userDetail = JSON.stringify(user);
    formData.append("user", userDetail);

    let finalContactDetails = [];
    let pictureDetails = [];
    contactDetails.forEach((cD, id) => {
      const { profilePic, ...reqcD } = cD;
      pictureDetails.push({ index: id });
      finalContactDetails.push(reqcD);
      formData.append("profile_picture[]", profilePic);
    });

    let finalCompany;
    if (parentCompanyId === "") {
      finalCompany = {
        ...finalCompanyDetail,
        parentCompanyId: null,
        contactPerson: finalContactDetails,
      };
    } else {
      finalCompany = {
        ...finalCompanyDetail,
        parentCompanyId,
        contactPerson: finalContactDetails,
      };
    }

    var companyDetail = JSON.stringify(finalCompany);
    formData.append("company", companyDetail);

    var picDetail = JSON.stringify(pictureDetails);
    formData.append("picture_details", picDetail);

    setSubmitSpinner(true);
    axiosPost(
      URL.insertCompany,
      formData,
      (res) => {
        if (res.data.success) {
          setSubmitSpinner(false);
          toggleModal();
          swal("Success", "Company created successfully", "success");
          getCompany();
        } else {
          setSubmitSpinner(false);
          displayErrorAlert(res);
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  const getComponent = () => {
    switch (step) {
      case 1:
        return (
          <CompanyDetails
            handleNext={handleNext}
            company={company}
            setCompany={setCompany}
            companyData={companyData}
            setCompanyData={setCompanyData}
            editParam={editParam}
            industryData={industryData}
            rolePermission={rolePermission}
            updatedMediaFiles={updatedMediaFiles}
            setUpdatedMediaFiles={setUpdatedMediaFiles}
            editableCmp={editableCmp}
            setEditableCmp={setEditableCmp}
            companyFiles={companyFiles}
            handleRemoveCmpFile={handleRemoveCmpFile}
            companyInfo={companyInfo}
            updateCompanyProfile={updateCompanyProfile}
            SetCompanyProfileInfo={SetCompanyProfileInfo}
            reg={reg}
            setRegFile={setRegFile}
            handleRemove={handleRemoveCompanyInfo}
          />
        );

      case 2:
        return (
          <ContactDetails
            handleNext={handleNext}
            contactDetails={contactDetails}
            setContactDetails={setContactDetails}
            handlePrevious={handlePrevious}
            companyData={companyData}
            setCompanyData={setCompanyData}
            editParam={editParam}
            editableCmp={editableCmp}
            setEditableCmp={setEditableCmp}
            companyFiles={companyFiles}
            handleRemoveCmpFile={handleRemoveCmpFile}
            handleUpdate={handleUpdate}
            companyInfo={companyInfo}
            updateCompanyProfile={updateCompanyProfile}
            SetCompanyProfileInfo={SetCompanyProfileInfo}
          />
        );

      case 3:
        return editParam?.isEdit ?? updateCompany ? null : (
          <User
            user={user}
            companyData={companyData}
            setCompanyData={setCompanyData}
            setUser={setUser}
            handlePrevious={handlePrevious}
            handleSubmit={handleSubmit}
            editParam={editParam}
            editableCmp={editableCmp}
            setEditableCmp={setEditableCmp}
            companyFiles={companyFiles}
            handleRemoveCmpFile={handleRemoveCmpFile}
          />
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    checkRolePermissions();
  }, []);

  return (
    <>
      <div className="container" style={{ padding: "1rem" }}>
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-md-offset-3">
              <form id="msform">
                <ul id="progressbar" className="ps-4 pe-4 ">
                  <li
                    className={`${step === 1 ? "active" : ""}`}
                    // onClick={() => {
                    //   setStep(1);
                    // }}
                  >
                    Company Details
                  </li>
                  <li
                    className={`${step === 2 ? "active" : null}`}
                    // onClick={() => {
                    //   setStep(2);
                    // }}
                  >
                    Contact Details
                  </li>
                  {editParam?.isEdit ?? updateCompany ? null : (
                    <li
                      className={`${step === 3 ? "active" : null}`}
                      onClick={() => {
                        setStep(3);
                      }}
                    >
                      User
                    </li>
                  )}
                </ul>
                {/* /* Switch the components as rerquired  */}
                <fieldset>{getComponent()}</fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyForm;
