import React, { useState } from "react";
import { bytesToMegaBytes, fileUrl } from "../../../utils/Constants";
import swal from "sweetalert";
import Button from "../../../components/common/Button";
import CommonModal from "../../../components/common/Modal";
import CustomFileViewer from "../../../components/common/CustomFileViewer";

const Training = (props) => {
  const [modal, setmodal] = useState({
    fileModal: false,
    fileToBeViewed: null,
  });

  const toggleModalFile = (e, tra) => {
    if (tra === undefined) {
      setmodal((prevState) => ({
        ...prevState,
        fileModal: false,
        fileToBeViewed: null,
      }));
    } else {
      setmodal((prevState) => ({
        ...prevState,
        fileModal: true,
        fileToBeViewed:
          tra?.media.length === 0 ? tra?.uploadedMedia : tra?.media,
      }));
    }
  };
  const {
    trainings,
    setTrainings,
    handlePrevious,
    editParam,
    handleSubmit,
    handleSubmitProfile,
    addProfile,
    editableDptStf,
    setEditableDptStf,
    handleRemoveFile,
    userInfo,
    setUserInfo,
    updateDepartmentStaff,
  } = props;

  const traDetReq = [
    {
      training_name: null,
      training_institute: null,
      duration: null,
      completed_date: null,
    },
  ];

  const traToBeEdited =
    editableDptStf &&
    editableDptStf[editParam?.selectedDepartment]?.staff[
      editParam?.selectedIndex
    ]?.training;

  const traForProfile = userInfo?.training;

  const requiredTraining = editParam?.isEdit
    ? traToBeEdited.length === 0
      ? traDetReq
      : traToBeEdited
    : addProfile
    ? traForProfile?.length === 0
      ? traDetReq
      : traForProfile
    : trainings;

  const reqTrainingSetterFunc = editParam?.isEdit
    ? setEditableDptStf
    : addProfile
    ? setUserInfo
    : setTrainings;

  const staffDetails =
    editParam?.isEdit &&
    editableDptStf[editParam.selectedDepartment].staff[editParam.selectedIndex];

  const handleAddTra = (e) => {
    e.preventDefault();
    let cloneTra = [...requiredTraining];
    if (editParam?.isEdit || addProfile) {
      cloneTra.push({
        training_name: null,
        training_institute: null,
        duration: null,
        completed_date: null,
        // media: null
      });
    } else {
      cloneTra.push({
        trainingName: null,
        trainingInstitute: null,
        duration: null,
        completedDate: null,
        training_docs: null,
      });
    }
    if (editParam?.isEdit) {
      const cloneUserInfo = [...editableDptStf];
      cloneUserInfo[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].training = cloneTra;
      setEditableDptStf(cloneUserInfo);
    } else {
      reqTrainingSetterFunc(cloneTra, "tra");
    }
  };
  const handleRemoveCustomTraBtnClick = (e, index) => {
    let cloneTra = [...requiredTraining];
    cloneTra.splice(index, 1);
    if (editParam?.isEdit) {
      const cloneUserInfo = [...editableDptStf];
      cloneUserInfo[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].training = cloneTra;
      setEditableDptStf(cloneUserInfo);
    } else {
      reqTrainingSetterFunc(cloneTra, "tra");
    }
  };

  const handleCustomInputChange = (e, index) => {
    const { name, value } = e.target;
    let cloneTra = [...requiredTraining];
    const fileName = ["training_docs", "uploadedMedia"];
    if (!fileName.includes(name)) {
      cloneTra[index][name] = value;
    } else {
      let data = e.target.files;
      if (data && data[0] && bytesToMegaBytes(data[0].size) > 4) {
        swal(
          "warning",
          "File size must be equal or less than 4 MB.",
          "warning"
        );
        return;
      }
      data && data[0] && (cloneTra[index][name] = data[0]);
    }
    if (editParam?.isEdit) {
      const cloneUserInfo = [...editableDptStf];
      cloneUserInfo[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].training = cloneTra;
      setEditableDptStf(cloneUserInfo);
    } else {
      reqTrainingSetterFunc(cloneTra, "tra");
    }
  };

  return (
    <>
      <h2 className="fs-title">Trainings Details</h2>
      <h3 className="fs-subtitle">All fields are required </h3>

      <div className="container border" style={{ paddingTop: "20px" }}>
        {requiredTraining && requiredTraining.length > 0
          ? requiredTraining.map((tra, id) => {
              return (
                <React.Fragment key={id}>
                  <div className="row">
                    {id != 0 ? (
                      <div className="col text-end">
                        <Button
                          name="Remove"
                          className="mb-3"
                          onClick={(e) => handleRemoveCustomTraBtnClick(e, id)}
                        ></Button>
                      </div>
                    ) : null}
                  </div>

                  <div className="row">
                    <div className="col">
                      <label className="floating">Training Name</label>

                      <input
                        type="text"
                        placeholder="Training Name"
                        name={
                          editParam?.isEdit || addProfile
                            ? "training_name"
                            : "trainingName"
                        }
                        value={tra.trainingName ?? tra.training_name}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">Training Institute</label>
                      <input
                        type="text"
                        placeholder="Training Institute"
                        name={
                          editParam?.isEdit || addProfile
                            ? "training_institute"
                            : "trainingInstitute"
                        }
                        value={tra.trainingInstitute ?? tra.training_institute}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col text-start">
                      <label className="floating">Completed Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name={
                          editParam?.isEdit || addProfile
                            ? "completed_date"
                            : "completedDate"
                        }
                        value={tra.completedDate ?? tra.completed_date}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">Duration</label>
                      <input
                        type="text"
                        name="duration"
                        placeholder="Duration"
                        value={tra.duration}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    {(tra?.media && tra.media.length === 0) ||
                    !tra?.academics_docs ? (
                      <div className="col">
                        <label className="floating">File</label>
                        <input
                          type="file"
                          name={
                            editParam?.isEdit || addProfile
                              ? "uploadedMedia"
                              : "training_docs"
                          }
                          onChange={(e) => handleCustomInputChange(e, id)}
                        />
                      </div>
                    ) : (
                      <div className="col-6 justify-content-start mt-4">
                        <Button
                          name="Remove Training Doc "
                          onClick={(e) =>
                            handleRemoveFile(e, "training_docs", id)
                          }
                        />
                      </div>
                    )}

                    {((tra?.media &&
                      (tra?.media?.length !== 0 ?? tra?.media !== "")) ||
                      (tra?.training_docs && tra?.training_docs !== "") ||
                      (tra?.uploadedMedia && tra?.uploadedMedia !== "")) && (
                      <div className="col-4 mt-4">
                        <Button
                          name="View Training Doc file"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleModalFile(e, tra);
                          }}
                        ></Button>
                      </div>
                    )}
                  </div>
                  <hr />
                </React.Fragment>
              );
            })
          : null}
        <div className="row mb-3">
          <div className="col text-end">
            <Button name="Add Training" onClick={handleAddTra}></Button>
          </div>
        </div>
      </div>

      <input
        type="button"
        name="previous"
        className="action-button-previous"
        value="Previous"
        onClick={handlePrevious}
      />

      <input
        type="button"
        name="next"
        className="next action-button"
        value={editParam?.isEdit || addProfile ? "Update" : "Submit"}
        onClick={
          addProfile
            ? handleSubmitProfile
            : editParam?.isEdit
            ? updateDepartmentStaff
            : handleSubmit
        }
      />

      <CommonModal
        modal={modal.fileModal}
        modalHeader={"Training Doc"}
        modalBody={
          modal?.fileModal && (
            <CustomFileViewer
              fileExtension={
                Array.isArray(modal?.fileToBeViewed)
                  ? modal?.fileToBeViewed[0]?.file_name?.split(".").pop()
                  : modal?.fileToBeViewed?.name?.split(".").pop()
              }
              filePath={
                Array.isArray(modal?.fileToBeViewed)
                  ? `${fileUrl}/${modal?.fileToBeViewed[0]?.id}/${modal?.fileToBeViewed[0]?.file_name}`
                  : modal?.fileToBeViewed &&
                    URL.createObjectURL(modal?.fileToBeViewed)
              }
            />
          )
        }
        size="xl"
        toggleModal={toggleModalFile}
      />
    </>
  );
};

export default Training;
