import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import swal from "sweetalert";
import { moduleName, URL } from "../../utils/Constants";
import { checkRolePermissions } from "../../utils/PermissionChecker";
import CommonModal from "../../components/common/Modal";
import Pagination from "react-js-pagination";

const ListGatePass = ({
  gatePassData,
  setSubmitSpinner,
  fetchRequestedGatePass,
  updatePermission,
  deletePermission,
  isFetching,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [remarks, setRemarks] = useState();
  const [isAccepted, setIsAccepted] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [individualId, setIndividualId] = useState();
  const toogleRemarksmodal = (id) => {
    setIsOpen(!isOpen);
    setIndividualId(id);
  };
  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };

  useEffect(() => {
    if (isOpen === false) {
      setRemarks("");
    }
  }, [isOpen]);
  const handleApprove = (e, id) => {
    setSubmitSpinner(true);
    let params = {
      id: individualId,
      approval_status: "Accepted",
      remarks: remarks,
    };
    axiosPost(
      URL.acceptRejectGatePass,
      params,
      (response) => {
        if (response.status === 200) {
          swal("Success", "Gate Pass approved succesfully", "success");
          fetchRequestedGatePass();
          setSubmitSpinner(false);
          toogleRemarksmodal();
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleReject = () => {
    setSubmitSpinner(true);
    let params = {
      id: individualId,
      approval_status: "Rejected",
      remarks: remarks,
    };
    axiosPost(
      URL.acceptRejectGatePass,
      params,
      (response) => {
        if (response.status === 200) {
          swal("Success", "Gate Pass rejected succesfully", "success");
          fetchRequestedGatePass();
          setSubmitSpinner(false);
          toogleRemarksmodal();
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  if (gatePassData) {
    var { current_page, per_page, total } = gatePassData;
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12 ml-auto mr-auto">
          <div className="table-responsive whiteContainer p-4">
            <table className="table table-sm table-custom">
              <thead>
                <tr>
                  <th className="text-center">SN</th>
                  <th className="text-center">Requested By</th>
                  <th className="text-center">Date</th>
                  <th className="text-center">Time From</th>
                  <th className="text-center">Time To</th>
                  <th className="text-center">Reason</th>
                  <th className="text-center">Duration (Hours:Minute)</th>

                  <th className="text-center">Status</th>

                  <th className="text-center">Approved By</th>
                  {updatePermission || deletePermission ? (
                    <th className="text-center">Actions</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {gatePassData?.data && gatePassData?.data?.length > 0 ? (
                  gatePassData?.data.map((gP, id) => (
                    <tr key={id}>
                      <td className="text-center">{id + 1}</td>
                      <td className="text-center">
                        {gP.user.first_name}
                        {gP.user.middle_name ? ` ${gP.user.middle_name}` : " "}
                        {gP.user.last_name}
                      </td>
                      <td className="text-center">{gP.pass_date}</td>
                      <td className="text-center">{gP.time_from}</td>
                      <td className="text-center">{gP.time_to}</td>
                      <td className="text-center">{gP.reason}</td>
                      <td className="text-center">
                        {`0${(gP.duration / 60) ^ 0}`.slice(-2) +
                          ":" +
                          ("0" + (gP.duration % 60)).slice(-2)}
                      </td>
                      <td className="text-center">{gP.approval_status}</td>
                      <td className="text-center">
                        {gP?.approve_by?.first_name
                          ? gP?.approve_by?.first_name
                          : "-"}
                      </td>
                      {updatePermission || deletePermission ? (
                        <td className="text-center">
                          {updatePermission ? (
                            gP.approval_status === "Accepted" ? null : (
                              <button
                                type="button"
                                rel="tooltip"
                                className="btn btn-outline-info rounded-circle btn-sm me-2"
                                data-original-title=""
                                title="Approve"
                                // onClick={e => handleApprove(e, gP.id)}
                                onClick={() => {
                                  toogleRemarksmodal(gP.id);
                                  setIsRejected(false);
                                  setIsAccepted(true);
                                }}
                              >
                                <i className="material-symbols-outlined">
                                  done_all
                                </i>
                              </button>
                            )
                          ) : null}
                          {deletePermission || updatePermission ? (
                            gP.approval_status === "Rejected" ? null : (
                              <button
                                type="button"
                                rel="tooltip"
                                className="btn btn-outline-danger rounded-circle btn-sm me-2 mt-1"
                                data-original-title=""
                                title="Reject"
                                // onClick={(e) => handleReject(e, gP.id)}
                                onClick={() => {
                                  toogleRemarksmodal(gP.id);
                                  setIsRejected(true);
                                  setIsAccepted(false);
                                }}
                              >
                                <i className="material-symbols-outlined">
                                  remove_done
                                </i>
                              </button>
                            )
                          ) : null}
                        </td>
                      ) : null}
                    </tr>
                  ))
                ) : (
                  <tr className="text-center">
                    <td colSpan={8}>
                      {isFetching ? (
                        <div className="text-center">
                          <Spinner color="whiteSmoke" size="sm" />
                        </div>
                      ) : (
                        "No Gate Pass found"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {gatePassData?.total <= 5 ? (
              <></>
            ) : (
              <div className="paginationContainer">
                <Pagination
                  activePage={current_page}
                  itemsCountPerPage={per_page}
                  totalItemsCount={total}
                  onChange={(pageNumber) => fetchRequestedGatePass(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First"
                  lastPageText="Last"
                />
              </div>
            )}
          </div>
        </div>
        <CommonModal
          modal={isOpen}
          toggleModal={toogleRemarksmodal}
          modalHeader="Remarks"
          size="md"
          modalBody={
            <>
              <div className="row">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="remarks"
                  onChange={(e) => handleRemarksChange(e)}
                  value={remarks}
                />
              </div>
              <div className="row ">
                <div
                  className="d-flex align-items-center"
                  style={{ float: "right", marginTop: "0.5rem" }}
                >
                  <div className="col-md-2">
                    <button
                      className="custom-btn all btn-9 enableBtn"
                      onClick={() => {
                        if (isAccepted) {
                          handleApprove();
                        } else if (isRejected) {
                          handleReject();
                        }
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                  <div className="col-md-2 ms-5">
                    <button
                      className="custom-btn all btn-9 enableBtn "
                      onClick={() => toogleRemarksmodal()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </div>
    </>
  );
};

const ViewRequestedGatePass = () => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [gatePassData, setGatePassData] = useState(null);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);

  useEffect(() => {
    fetchRequestedGatePass();
    permissionConfig();
  }, []);

  const fetchRequestedGatePass = (pageNumber = 1) => {
    setIsFetching(true);
    setGatePassData(null);
    axiosGet(
      URL.gatePassRequested + "?" + "page=" + pageNumber,
      (response) => {
        if (response.status === 200) {
          setIsFetching(false);
          let modules = response.data;
          setGatePassData(modules);
        }
      },
      (err) => {
        setIsFetching(false);
        displayErrorAlert(err);
      }
    );
  };

  const permissionConfig = () => {
    setUpdatePermission(checkRolePermissions("update", moduleName.gatePass));
    setDeletePermission(checkRolePermissions("delete", moduleName.gatePass));
  };

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">View Requested GatePass </div>

        {submitSpinner && (
          <div
            className="container"
            style={{ padding: "1rem", height: "100%" }}
          >
            <div className="fullWindow-Spinner">
              <div>
                <Spinner color="white"></Spinner>
              </div>
              <div style={{ fontSize: "16px", marginTop: "15px" }}>
                Please Wait. Submitting ...
              </div>
            </div>
          </div>
        )}

        <div className="row justify-content-center d-flex text-center">
          {gatePassData?.data?.length > 0 ? (
            <div>
              <ListGatePass
                gatePassData={gatePassData}
                fetchRequestedGatePass={fetchRequestedGatePass}
                setSubmitSpinner={setSubmitSpinner}
                updatePermission={updatePermission}
                deletePermission={deletePermission}
                isFetching={isFetching}
              />
            </div>
          ) : isFetching ? (
            <div className="text-center">
              <Spinner></Spinner>
            </div>
          ) : (
            <div className="newTextColor text-center">No GatePass Request</div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewRequestedGatePass;
