import React, { useState, useEffect } from "react";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import Button from "../../components/common/Button";
import parse from "html-react-parser";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";

const AcceptCompanyGuideline = () => {
  const navigate = useNavigate();
  const [companyGuideline, setCompanyGuideline] = useState(null);
  const [acceptCompanyGuideline, setAcceptCompanyGuideline] = useState(null);
  const [submitSpinner, setSubmitSpinner] = useState(false);

  useEffect(() => {
    getCompanyGuideline();
  }, []);

  const getCompanyGuideline = () => {
    axiosGet(
      URL.getCompanyGuideline,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data && response.data.data?.item;
          setCompanyGuideline(modules);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setAcceptCompanyGuideline((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      accepted:
        acceptCompanyGuideline && acceptCompanyGuideline?.accepted ? 1 : 0,
      company_guideline_id: companyGuideline && companyGuideline.id,
    };
    setSubmitSpinner(true);
    axiosPost(
      URL.acceptGuideline,
      params,
      (response) => {
        if (response.data.success) {
          setSubmitSpinner(false);
          swal("Success", "Successfully accepted company guideline", "success");
          setAcceptCompanyGuideline((prevState) => ({
            ...prevState,
            accepted: false,
          }));
          navigate(`${process.env.PUBLIC_URL}/dashboard`);
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };
  return (
    <>
      <div className="container" style={{ padding: "1rem", height: "100%" }}>
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="container mt-4">
            <nav
              className="navbar"
              style={{ backgroundColor: "#5d93cf", color: "white" }}
            >
              <span
                className="navbar-brand mb-0 h1 "
                style={{ margin: "auto", backgroundColor: "" }}
              >
                Accept CompanyGuideline
              </span>
            </nav>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <div className="jumbotron">
              <div className="container">
                <div className="mt-2">
                  <h5 className="newTextColor">{companyGuideline?.header}</h5>
                </div>
                <hr />
                <div className="newTextColor">
                  {parse(`${companyGuideline?.subject}`)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div>
              <div className="container">
                <hr></hr>
                <form onSubmit={handleSubmit}>
                  <div className="mt-2">
                    <input
                      type="checkbox"
                      name="accepted"
                      onChange={handleChange}
                      ceheked={
                        acceptCompanyGuideline &&
                        acceptCompanyGuideline?.accepeted
                      }
                    ></input>
                    <label htmlFor="acceptTerms">
                      I acknowledge that I have read, and do hereby accept the
                      terms and conditions mentioned above.
                    </label>
                  </div>
                  <div style={{ float: "right" }}>
                    <Button name="submit" type="submit" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptCompanyGuideline;
