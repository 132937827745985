import React, { useState } from "react";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import loginIcon from "../../assets/images/loginIconWhite.png";
import Button from "../../components/common/Button";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import FullPageSpinner from "../../components/common/FullPageSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { checkIsValidPassword } from "../../utils/Validation";
const ResetPassword = () => {
  const [loader, setLoader] = useState(false);
  const { email } = useParams();

  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [inputType, setInputType] = useState({
    userPassword: "password",
    userCPassword: "password",
  });
  const [userPwd, setUserPwd] = useState(null);
  const [capsOn, setCapsOn] = useState({ pwdCaps: false, cpwdCaps: false });
  const [errors, setErrors] = useState({
    isErrorPwd: false,
    isErrorCpwd: false,
    validationMsg: null,
  });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setUserPwd((prevState) => ({ ...prevState, [name]: value }));
  //   if (name === "password") {
  //     if (value !== null && value !== "") {
  //       if (!checkIsValidPassword(value)) {
  //         setErrors({
  //           ...errors,
  //           isErrorPwd: true,
  //           validationMsg:
  //             "Password must contain Special characters, Numbers, Uppercase and Lowercase letter",
  //         });
  //       } else if (value.length < 8) {
  //         setErrors({
  //           ...errors,
  //           isErrorPwd: true,
  //           validationMsg: "Password must be 8 characters long",
  //         });
  //       } else {
  //         setErrors({
  //           ...errors,
  //           isErrorPwd: false,
  //           validationMsg: null,
  //         });
  //       }
  //     }

  //     if (name === "confirmPassword") {
  //       if (value !== userPwd?.password) {
  //         setErrors({
  //           ...errors,
  //           isErrorCpwd: true,
  //           validationMsg: "Password don't match",
  //         });
  //       } else {
  //         setErrors({
  //           ...errors,
  //           isErrorCpwd: false,
  //           validationMsg: null,
  //         });
  //       }
  //     }
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserPwd((prevState) => ({ ...prevState, [name]: value }));

    if (name === "password") {
      if (value !== null && value !== "") {
        if (!checkIsValidPassword(value)) {
          setErrors({
            ...errors,
            isErrorPwd: true,
            validationMsg:
              "Password must contain at least one special character, one number, one uppercase letter, and one lowercase letter.",
          });
        } else if (value.length < 8) {
          setErrors({
            ...errors,
            isErrorPwd: true,
            validationMsg: "Password must be at least 8 characters long.",
          });
        } else {
          setErrors({
            ...errors,
            isErrorPwd: false,
            validationMsg: null,
          });
        }
      }
    }

    if (name === "confirmPassword") {
      if (value !== userPwd?.password) {
        setErrors({
          ...errors,
          isErrorCpwd: true,
          validationMsg: "Passwords do not match.",
        });
      } else {
        setErrors({
          ...errors,
          isErrorCpwd: false,
          validationMsg: null,
        });
      }
    }
  };

  const handleKeydown = (e, status) => {
    // if (e.key === "Enter") {
    //   //   handleSubmit();
    // }

    if (status === "pwd") {
      if (e.getModifierState("CapsLock")) {
        setCapsOn((prevState) => ({ ...prevState, pwdCaps: true }));
      } else {
        setCapsOn((prevState) => ({ ...prevState, pwdCaps: false }));
      }
    } else {
      if (e.getModifierState("CapsLock")) {
        setCapsOn((prevState) => ({ ...prevState, cpwdCaps: true }));
      } else {
        setCapsOn((prevState) => ({ ...prevState, cpwdCaps: false }));
      }
    }
  };

  const handleInputTypeChange = (status) => {
    if (status === "pwd") {
      if (inputType?.userPassword === "password") {
        setInputType((prevState) => ({ ...prevState, userPassword: "text" }));
      } else {
        setInputType((prevState) => ({
          ...prevState,
          userPassword: "password",
        }));
      }
    } else {
      if (inputType?.userCPassword === "password") {
        setInputType((prevState) => ({ ...prevState, userCPassword: "text" }));
      } else {
        setInputType((prevState) => ({
          ...prevState,
          userCPassword: "password",
        }));
      }
    }
  };

  const handleConfirmChange = (e) => {
    const { name, value } = e.target;

    setUserPwd((prevState) => ({ ...prevState, [name]: value }));
    if (value !== userPwd?.password) {
      setErrors({
        ...errors,
        isErrorCpwd: true,
        validationMsg: "Password don't match",
      });
    } else {
      setErrors({
        ...errors,
        isErrorCpwd: false,
        validationMsg: null,
      });
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   let data = {
  //     email: email,
  //     password: userPwd.password,
  //     confirm_password: userPwd.confirmPassword,
  //   };

  //   if (
  //     email !== "" &&
  //     userPwd.password !== "" &&
  //     userPwd.confirmPassword !== ""
  //   ) {
  //     if (userPwd?.password !== userPwd?.confirmPassword) {
  //       swal("Warning", "Passwords don't match", "warning");
  //       return;
  //     }
  //     else{
  //       setLoader(true);
  //       axiosPost(
  //         URL.resetPassword,
  //         data,
  //         (response) => {
  //           if (response.status === 200) {
  //             setLoader(false);
  //             swal({
  //               title: "Password changed successfully!!",
  //               text: "Your password in the system has been replaced with new password. Please proceed to login with new password.",
  //               icon: "success",
  //             }).then((ok) => {
  //               if (ok) {
  //                 window.close();
  //                 window.open(`${process.env.PUBLIC_URL}/login`, "_blank");
  //               }
  //             });
  //           }
  //           else {
  //             swal("Error", response.data.message, "error");
  //             setLoader(false);
  //           }
  //         },
  //         (err) => {
  //           if (err.response?.status === 403) {
  //             swal({
  //               title: 'Forbidden',
  //               text:
  //                 err.response.data.message,
  //               icon: 'error'
  //             }).then((redirectConfirm) => {
  //               if (redirectConfirm) {
  //                 window.close();
  //                 window.open(`${process.env.PUBLIC_URL}/login`, "_blank");
  //               }
  //             });
  //           }
  //         }
  //       );
  //     }

  //   } else {
  //     setLoader(false);
  //     swal("Error", "Password and confirm password cannot be empty");
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    let data = {
      email: email,
      password: userPwd.password,
      confirm_password: userPwd.confirmPassword,
    };
    if (
      email !== "" &&
      userPwd.password !== "" &&
      userPwd.confirmPassword !== ""
    ) {
      if (userPwd.password !== userPwd.confirmPassword) {
        swal("Warning", "Passwords don't match", "warning");
        return;
      }
      else if (!checkIsValidPassword(userPwd.password)) {
        swal("Warning", "Password must contain at least one special character, one number, one uppercase letter, and one lowercase letter.", "warning");
        return;
      }
      else {
        setLoader(true);
        axiosPost(
          URL.resetPassword,
          data,
          (response) => {
            if (response.status === 200) {
              setLoader(false);
              swal({
                title: "Password changed successfully!!",
                text: "Your password in the system has been replaced with a new password. Please proceed to login with the new password.",
                icon: "success",
              }).then((ok) => {
                if (ok) {
                  window.close();
                  window.open(`${process.env.PUBLIC_URL}/login`, "_blank");
                }
              });
            } else {
              swal("Error", response.data.message, "error");
              setLoader(false);
            }
          },
          (err) => {
            if (err.response?.status === 403) {
              swal({
                title: 'Forbidden',
                text: err.response.data.message,
                icon: 'error'
              }).then((redirectConfirm) => {
                if (redirectConfirm) {
                  window.close();
                  window.open(`${process.env.PUBLIC_URL}/login`, "_blank");
                }
              });
            }
          }
        );
      }

    } else {
      setLoader(false);
      swal("Error", "Email and passwords cannot be empty");
    }
  };

  return (
    <div>
      <div>
        <div className="container-fluid p-0 m-0">
          {loader ? (
            <FullPageSpinner text=" Password reset link is being forwarded to your email ..." />
          ) : null}

          <div className="justify-content-center d-flex loginBox">
            <div className="row main-content text-center  ">
              <div className="col-md-4 text-center company__info">
                <span className="company__logo">
                  <img src={loginIcon} />
                </span>
              </div>
              <div className="col-md-8 col-xs-12 col-sm-12 login_form ">
                <div className="loginFormHolder">
                  <div className="container-fluid">
                    <div className="row">
                      <h2 className="sectionTitle text-blue">Reset Password</h2>
                    </div>
                    <div className="row">
                      <form onSubmit={handleSubmit}>
                        <div className="row ">
                          <div className="col p-1">
                            <label
                              style={{ display: "contents" }}
                              className="text-blue"
                            >
                              Password
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                type={inputType?.userPassword}
                                className="form-control"
                                placeholder="xxxxxxx"
                                name="password"
                                onChange={handleChange}
                                onKeyDown={(e) => handleKeydown(e, "pwd")}
                              />
                              <span>
                                <FontAwesomeIcon
                                  className="text-blue"
                                  icon={
                                    inputType?.userPassword === "password"
                                      ? faEye
                                      : faEyeSlash
                                  }
                                  style={{
                                    position: "absolute",
                                    right: "15px",
                                    top: "13px",
                                  }}
                                  onClick={() => handleInputTypeChange("pwd")}
                                />
                              </span>
                            </div>
                            {userPwd?.password && errors?.isErrorPwd ? (
                              <small
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  display: "block",
                                }}
                              >
                                {errors?.validationMsg}
                              </small>
                            ) : null}

                            {capsOn?.pwdCaps ? (
                              <div>
                                <strong>Caps Lock is On</strong>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col p-1">
                            <label
                              style={{ display: "contents" }}
                              className="text-blue"
                            >
                              {" "}
                              Confirm Password
                            </label>
                            <div style={{ position: "relative" }}>
                              <input
                                type={inputType?.userCPassword}
                                className="form-control"
                                placeholder="xxxxxxx"
                                name="confirmPassword"
                                onChange={handleConfirmChange}
                                onKeyDown={(e) => handleKeydown(e, "cPwd")}
                              />
                              {userPwd?.confirmPassword &&
                                errors?.isErrorCpwd ? (
                                <small
                                  style={{
                                    color: "red",
                                    marginTop: "5px",
                                    display: "block",
                                  }}
                                >
                                  {errors?.validationMsg}
                                </small>
                              ) : null}
                              <span>
                                <FontAwesomeIcon
                                  className="text-blue"
                                  icon={
                                    inputType?.userCPassword === "password"
                                      ? faEye
                                      : faEyeSlash
                                  }
                                  style={{
                                    position: "absolute",
                                    right: "15px",
                                    top: "13px",
                                  }}
                                  onClick={() => handleInputTypeChange("cPwd")}
                                />
                              </span>
                            </div>

                            {capsOn?.cpwdCaps ? (
                              <div>
                                <strong>Caps Lock is On</strong>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col text-end p-2">
                            <Button name="Submit" type="submit" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
