import React, { useState, useEffect } from "react";
import Button from "../../components/common/Button";
import CommonModal from "../../components/common/Modal";
import { Spinner } from "reactstrap";
import DepartmentStaffForm from "./DepartmentStaffForm/DepartmentStaffForm";
import DepartmentStaffList from "./DepartmentStaffList";
import { URL, moduleName } from "../../utils/Constants";
import { axiosPost, axiosGet, axiosDelete } from "../../utils/AxiosApi";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import swal from "sweetalert";
import {
  checkRolePermissions,
  checkModulePermission,
} from "../../utils/PermissionChecker";
import DepartmentStaffDetails from "./DepartmentStaffDetails";
import Contract from "../Contract/Contract";

const DepartmentStaff = () => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [departmentName, setDepartmentName] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [departmentStaff, setDepartmentStaff] = useState(null);
  const [departmentStaffToBeViewed, setDepartmentStaffToBeViewed] =
    useState(null);
  const [allowedPermission, setAllowedPermission] = useState(null);
  const [assignedPermission, setAssignedPermission] = useState(null);
  const [isView, setIsView] = useState(false);
  const [editableDptStf, setEditableDptStf] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [contractModal, setContractModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [roles, setRoles] = useState();

  const [editParam, setEditParam] = useState({
    selectedIndex: null,
    isEdit: false,
    selectedDepartment: null,
    selectedDesignation:null,
  });

  useEffect(() => {
    if (departmentStaff !== null) {
      const data = JSON.parse(JSON.stringify(departmentStaff));
      setEditableDptStf(data);
    }
  }, [departmentStaff]);

  useEffect(() => {
    if (editParam.isEdit === true) {
      const ctz = editableDptStf[editParam.selectedDepartment]?.staff[
        editParam.selectedIndex
      ]?.media?.find((el) => el.collection_name === "citizenship_files");

      const pan = editableDptStf[editParam.selectedDepartment]?.staff[
        editParam.selectedIndex
      ]?.media?.find((el) => el.collection_name === "pan_card_files");

      const sig = editableDptStf[editParam.selectedDepartment]?.staff[
        editParam.selectedIndex
      ]?.media?.find((el) => el.collection_name === "signature_files");

      const edu = editableDptStf[editParam.selectedDepartment]?.staff[
        editParam.selectedIndex
      ]?.media?.filter((el) => el.collection_name === "academic_docs_files");

      const exp = editableDptStf[editParam.selectedDepartment]?.staff[
        editParam.selectedIndex
      ]?.media?.find((el) => el.collection_name === "experience_docs_files");

      const tra = editableDptStf[editParam.selectedDepartment]?.staff[
        editParam.selectedIndex
      ]?.media?.find((el) => el.collection_name === "training_docs_files");
      ctz && setFileName((prevState) => ({ ...prevState, citizenship: ctz }));
      pan && setFileName((prevState) => ({ ...prevState, panCard: pan }));
      sig &&
        setFileName((prevState) => ({ ...prevState, digitalSignature: sig }));
      edu &&
        setFileName((prevState) => ({ ...prevState, academics_docs: edu }));
      exp &&
        setFileName((prevState) => ({ ...prevState, experience_docs: exp }));
      tra && setFileName((prevState) => ({ ...prevState, training_docs: tra }));
    }
  }, [editParam && editParam?.isEdit]);

  const toggleModal = () => {
    // editParam
    const { isEdit } = editParam;
    if (modal && isEdit) {
      setEditParam((prevState) => ({
        ...prevState,
        isEdit: false,
        selectedDepartment: null,
        selectedIndex: null,
        selectedDesignation:null,
      }));
      const data = JSON.parse(JSON.stringify(departmentStaff));
      setEditableDptStf(data);
      setFileName(null);
    }
    if (modal && isView) {
      setIsView(false);
    }
    setModal(!modal);
  };

  const toggleModalView = () => {
    // editParam
    setModalView(!modalView);
  };

  useEffect(() => {
    let value = checkModulePermission("departmentStaff");
    if (value?.view) {
      getDepartmentStaff();
    }
    setAssignedPermission(value);
  }, []);

  const getDepartmentStaff = () => {
    setIsLoading(true);
    axiosGet(
      URL.getDepartmentStaff,
      (response) => {
        setIsLoading(false);
        if (response && response.data.success) {
          let modules = response?.data?.data?.items;
          setDepartmentStaff(modules);
        }
      },
      (err) => {
        setIsLoading(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleDeleteDepartmentStaff = (e, id, enabled) => {
    swal({
      text:
        enabled === 1
          ? "Are you sure, you want to disable this staff from the system? "
          : "Are you sure, you want to enable the staff from the system? ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const params = {
          id: id,
        };
        setSubmitSpinner(true);
        //need to fix the provided api -to destroy the company
        axiosDelete(
          URL.deleteDepartmentStaff,
          { data: params },
          (response) => {
            if (response.data.success) {
              swal(
                "Success",
                enabled === 1
                  ? "Department staff disabled successfully"
                  : "Department staff enabled successfully",
                "success"
              );
              setSubmitSpinner(false);
              getDepartmentStaff();
            }
          },
          (err) => {
            displayErrorAlert(err);
            setSubmitSpinner(false);
          }
        );
      }
    });
  };

  const handleEditDepartmentStaff = (e, sIndex, dIndex) => {
    const designationId = e?.staff?.filter((_, index) => index === sIndex)[0]?.designation_id;
    setEditParam((prevState) => ({
      ...prevState,
      isEdit: true,
      selectedDepartment: dIndex,
      selectedIndex: sIndex,
      selectedDesignation:designationId
    }));
    toggleModal();
    setRoles(e);
  };

  const handleUploadContract = (e, sIndex, dIndex) => {
    let dptStf = departmentStaff[dIndex].staff[sIndex];
    setUserId(dptStf.user_id);
    setContractModal(!contractModal);
  };

  const handleViewDepartmentStaff = (e, index, id) => {
    let dptStf = departmentStaff[id].staff[index];
    setDepartmentName(departmentStaff[id]?.roles);
    setDepartmentStaffToBeViewed(dptStf);
    setIsView(true);

    toggleModalView();
  };

  const toggleContractModal = () => {
    setContractModal(!contractModal);
  };

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Department Staff</div>
      </div>
      <div className="container-fluid p-0 m-0" style={{ height: "100%" }}>
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}

        {assignedPermission && assignedPermission?.store ? (
          <div>
            <Button name="Add Department Staff" onClick={toggleModal} />
          </div>
        ) : null}

        <DepartmentStaffList
          allowedPermission={assignedPermission}
          departmentStaff={departmentStaff}
          handleDelete={handleDeleteDepartmentStaff}
          handleEdit={handleEditDepartmentStaff}
          handleUpload={handleUploadContract}
          submitSpinner={submitSpinner}
          setSubmitSpinner={setSubmitSpinner}
          handleView={handleViewDepartmentStaff}
          isLoading={isLoading}
        />
      </div>

      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        size="xl"
        modalHeader={
          editParam && editParam.isEdit
            ? "Edit Department Staff"
            : "Add a Department Staff"
        }
        modalBody={
          <>
            <DepartmentStaffForm
              toggleModal={toggleModal}
              editParam={editParam}
              departmentStaff={departmentStaff}
              setDepartmentStaff={setDepartmentStaff}
              getDepartmentStaff={getDepartmentStaff}
              editableDptStf={editableDptStf}
              setEditableDptStf={setEditableDptStf}
              fileName={fileName}
              setFileName={setFileName}
              roles1={roles}
            />
          </>
        }
      />

      <CommonModal
        modal={modalView}
        toggleModal={toggleModalView}
        size="xl"
        modalHeader={"Staff Details"}
        modalBody={
          <>
            <DepartmentStaffDetails
              dptStf={departmentStaffToBeViewed}
              departmentName={departmentName}
            />
          </>
        }
      />
      <CommonModal
        modal={contractModal}
        toggleModal={toggleContractModal}
        modalHeader="Contract Upload"
        modalBody={
          <Contract
            getMyContract={getDepartmentStaff}
            toggleContractModal={toggleContractModal}
            userId={userId}
            fromStaff={true}
          ></Contract>
        }
      ></CommonModal>
    </>
  );
};

export default DepartmentStaff;
