import React, { useState } from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";
const BranchUser = (prop) => {
  const { handlePrevious, setBranchUser, branchUser, handleSubmit } = prop;

  const [inputType, setInputType] = useState("password");
  const [capsOn, setCapsOn] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBranchUser((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleKeydown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
    if (e.getModifierState("CapsLock")) {
      setCapsOn(true);
    } else {
      setCapsOn(false);
    }
  };

  const handleInputTypeChange = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };
  return (
    <>
      <h2 className="fs-title">User</h2>
      <h3 className="fs-subtitle">Fill the required field</h3>

      <div className="row">
        <div className="col">
          <label className="floating">
            First Name<span className="required">*</span>{" "}
          </label>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            // value={company?.name}
            onChange={handleChange}
          />
        </div>
        <div className="col">
          <label className="floating">
            Middle Name<span>*</span>{" "}
          </label>
          <input
            type="text"
            name="middleName"
            placeholder="Middle Name"
            // value={company?.addressLine}
            onChange={handleChange}
          />
        </div>

        <div className="col">
          <label className="floating">
            Last Name<span className="required">*</span>{" "}
          </label>
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            // value={company?.contactNumber}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row">
        <label className="floating">
          EmailAddress<span className="required">*</span>{" "}
        </label>
        <div className="col">
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            // value={company?.emailAddress}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label className="floating">
            Username<span className="required">*</span>{" "}
          </label>
          <input
            type="text"
            name="userName"
            placeholder="User Name"
            // value={company?.emailAddress}
            onChange={handleChange}
          />
        </div>
        <div className="col">
          <label className="floating">
            Password<span className="required">*</span>{" "}
          </label>
          <input
            type={inputType}
            name="password"
            placeholder="Password"
            // value={company?.emailAddress}
            onChange={handleChange}
            onKeyDown={handleKeydown}
          />
          <span>
            <FontAwesomeIcon
              icon={inputType === "password" ? faEye : faEyeSlash}
              style={{
                position: "absolute",
                right: "37px",
                top: "66%",
                // padding: "10px"
              }}
              onClick={handleInputTypeChange}
            />
          </span>
          {capsOn ? (
            <div>
              <strong>Caps Lock is On</strong>
            </div>
          ) : null}
        </div>
      </div>
      <input
        type="button"
        name="previous"
        className="action-button-previous"
        value="Previous"
        onClick={handlePrevious}
      />
      <input
        type="button"
        name="next"
        className="next action-button"
        value="Submit"
        onClick={handleSubmit}
      />
    </>
  );
};

export default BranchUser;
