import axios from "axios";
import swal from "sweetalert";
import { serverLocation, headers, loginUrl } from "./Constants";
import history from "./History";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error.response && (error.response.status === 401 || error.response.status === 405)) {
      try {
        await refreshToken(originalRequest);

        return axios(originalRequest);
        
      } catch (refreshError) {
        handleFailedRefresh(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const refresh_token = localStorage.getItem("refreshToken");

    if (token) {
      if (config.url === `${serverLocation}/refreshToken`) {
        config.headers["refresh_token"] = refresh_token;
        config.headers["Authorization"] = null;
      } else {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function refreshToken(config) {
  const response = await axios.post(`${serverLocation}/refreshToken`, {}, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('refreshToken')}`
    }
  });

  if (response.status === 200) {
    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("refreshToken", response.data.refresh_token);
    return true;
  }

  throw new Error('Failed to refresh token');
}

function handleFailedRefresh(error) {
  console.error('Failed to refresh token:', error);
  localStorage.clear();
  history.push('/login');
  window.location.reload();
  swal({
    title: "Session Expired",
    text: "Please log in again.",
    icon: "warning",
    buttons: false,
    timer: 3000
  });
}

function handleError(error) {
  let errorResponse = error.response ? error.response.data : error;
  if (errorResponse.status === 400) {
    displayValidationError(errorResponse);
  } else {
    displayGenericError(errorResponse);
  }
}

function displayValidationError(errorResponse) {
  let errorMessage = '';
  if (errorResponse.errors) {
    errorResponse.errors.forEach((error) => {
      errorMessage += `${capitalizeFirstLetter(error.field)} ${error.defaultMessage}\n`;
    });
  } else {
    errorMessage = errorResponse.error || 'An unknown error occurred';
  }
  swal(errorMessage, '', 'error');
}

function displayGenericError(errorResponse) {
  swal(
    errorResponse.error || 'Network Error',
    errorResponse.message || 'Could not connect to the server.',
    'error'
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Function to test session expiration
async function testSessionExpiration() {
  try {
    // localStorage.clear();
    // console.log("Local storage cleared");

    const response = await axios.get('/protected-endpoint');

    console.log("Successfully accessed protected endpoint");
  } catch (error) {
    console.error("Error during testing:", error.response?.data);
    if (error.response && error.response.status === 401) {
      console.log("Session expired as expected");
    }
  }
}

export const axiosGet = (url, successCallback, failureCallback) => {
  axios.get(url, { headers })
    .then(successCallback)
    .catch(failureCallback);
};

export const axiosPost = (url, params, successCallback, failureCallback) => {
  axios.post(url, params, { headers })
    .then(successCallback)
    .catch(failureCallback);
};

export const axiosPut = (url, params, successCallback, failureCallback) => {
  axios.put(url, params, { headers })
    .then(successCallback)
    .catch(failureCallback);
};

export const axiosDelete = (url, params, successCallback, failureCallback) => {
  axios.delete(url, params, { headers })
    .then(successCallback)
    .catch(failureCallback);
};

export { testSessionExpiration };
