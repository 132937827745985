import React, { useState, useEffect, useMemo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { bytesToMegaBytes } from "../../../utils/Constants";
import { phoneValidate, convertToSnakeCase } from "../../../utils/Validation";
import { fileUrl } from "../../../utils/Constants";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import Button from "../../../components/common/Button";

const ContactDetails = (props) => {
  const {
    handleNext,
    contactDetails,
    setContactDetails,
    handlePrevious,
    companyData,
    setCompanyData,
    editParam,
    editableCmp,
    setEditableCmp,
    handleUpdate,
    companyInfo,
    updateCompanyProfile,
    SetCompanyProfileInfo,
  } = props;

  const finalContactDetails =
    editParam && editParam.isEdit
      ? editableCmp[editParam.selectedIndex].contact
      : updateCompanyProfile
      ? companyInfo.company.contact_persons
      : contactDetails;

  const handleAdd = (e) => {
    e.preventDefault();
    if (!editParam?.isEdit && !updateCompanyProfile) {
      const cloneContactDtl = [...contactDetails];
      cloneContactDtl.push({
        firstName: "",
        middleName: "",
        lastName: "",
        address: "",
        personContactNumber: "",
        email: "",
        designation: "",
        profilePic: "",
      });
      setContactDetails(cloneContactDtl);
    } else if (editParam?.isEdit) {
      const cloneCompanyData = [...editableCmp];
      cloneCompanyData[editParam?.selectedIndex]?.contact.push({
        first_name: "",
        middle_name: "",
        last_name: "",
        address: "",
        person_contact_number: "",
        email: "",
        designation: "",
        media: "",
      });
      setEditableCmp(cloneCompanyData);
    } else {
      const cloneCompanyData = { ...companyInfo?.company };
      cloneCompanyData?.contact_persons?.push({
        first_name: "",
        middle_name: "",
        last_name: "",
        address: "",
        person_contact_number: "",
        email: "",
        designation: "",
        media: "",
      });
      SetCompanyProfileInfo(cloneCompanyData);
    }
  };

  const handleRemoveCustomEduBtnClick = (e, index) => {
    e.preventDefault();
    if (!editParam?.isEdit && !updateCompanyProfile) {
      const cloneContactDtl = [...contactDetails];
      cloneContactDtl?.splice(index, 1);
      setContactDetails(cloneContactDtl);
    } else if (editParam?.isEdit) {
      const cloneCompanyData = [...editableCmp];
      cloneCompanyData[editParam?.selectedIndex]?.contact?.splice(index, 1);
      setEditableCmp(cloneCompanyData);
    } else {
      const cloneCompanyData = { ...companyInfo?.company };
      cloneCompanyData?.contact_persons?.splice(index, 1);
      SetCompanyProfileInfo(cloneCompanyData);
    }
  };

  const handleCustomPhoneChange = (e, index) => {
    if (!editParam?.isEdit && !updateCompanyProfile) {
      const cloneContactDtl = [...contactDetails];
      if (e === undefined) {
        return;
      }
      cloneContactDtl[index].personContactNumber = e;
      setContactDetails(cloneContactDtl);
    } else if (editParam?.isEdit) {
      const cloneCnt = [...editableCmp];
      cloneCnt[editParam?.selectedIndex].contact[index].person_contact_number =
        e;
      setEditableCmp(cloneCnt);
    } else {
      const cloneCnt = { ...companyInfo?.company };
      cloneCnt.contact_persons[index].person_contact_number = e;
      SetCompanyProfileInfo(cloneCnt);
    }
  };

  const handleCustomInputChange = (e, index) => {
    const { name, value, files } = e.target;
    if (!editParam?.isEdit && !updateCompanyProfile) {
      const cloneContactDtl = [...contactDetails];
      if (name !== "profilePic") {
        cloneContactDtl[index][name] = value;
      } else {
        let data = e.target.files;
        if (data && data[0] && bytesToMegaBytes(data[0].size) > 4) {
          swal(
            "warning",
            "File size must be equal or less than 4 MB.",
            "warning"
          );
          return;
        }
        if (data && data[0]) {
          cloneContactDtl[index][name] = data[0];
          // setContactDetails(cloneContactDtl);
        }
      }
      setContactDetails(cloneContactDtl);
    } else if (editParam?.isEdit) {
      const cloneCmpData = [...editableCmp];
      const reqTypo = convertToSnakeCase(name);
      if (name !== "profilePic") {
        cloneCmpData[editParam?.selectedIndex].contact[index][reqTypo] = value;
      } else {
        if (files && files[0] && bytesToMegaBytes(files[0].size) > 4) {
          swal(
            "warning",
            "File size must be equal or less than 4 MB.",
            "warning"
          );
          return;
        }

        if (files && files[0]) {
          cloneCmpData[editParam?.selectedIndex].contact[index].media =
            files[0];
        }
      }
      setEditableCmp(cloneCmpData);
    } else {
      const cloneCmpData = { ...companyInfo.company };
      const reqTypo = convertToSnakeCase(name);
      if (name !== "profilePic") {
        cloneCmpData.contact_persons[index][reqTypo] = value;
      } else {
        if (files && files[0] && bytesToMegaBytes(files[0].size) > 4) {
          swal(
            "warning",
            "File size must be equal or less than 4 MB.",
            "warning"
          );
          return;
        }

        if (files && files[0]) {
          cloneCmpData.contact_persons[index].media = files[0];
        }
      }
      SetCompanyProfileInfo(cloneCmpData);
    }
  };

  const removeImage = (e, id) => {
    const cloneContactDtl = [...contactDetails];
    cloneContactDtl[id].profilePic = null;
    setContactDetails(cloneContactDtl);
  };

  const getImage = (cd, id) => {
    const contactPersonFile = cd?.media;
    if (editParam?.isEdit) {
      if (contactPersonFile !== "" && !Array.isArray(contactPersonFile)) {
        return URL.createObjectURL(contactPersonFile);
      }
      if (contactPersonFile) {
        const { id, file_name } = contactPersonFile[0];
        return fileUrl + "/" + id + "/" + file_name;
      }
    }
    if (cd?.profilePic) {
      return URL.createObjectURL(cd?.profilePic);
    }
    if (updateCompanyProfile) {
      if (contactPersonFile !== "" && !Array.isArray(contactPersonFile)) {
        return URL.createObjectURL(contactPersonFile);
      }
      if (contactPersonFile) {
        const { id, file_name } = contactPersonFile[0];
        return fileUrl + "/" + id + "/" + file_name;
      }
    }
    return "https://media.istockphoto.com/vectors/default-gray-placeholder-man-vector-id871752462?s=612x612";
  };

  return (
    <>
      <h2 className="fs-title">Contact Details</h2>
      <h3 className="fs-subtitle">Fill the required field</h3>
      <div className="container border">
        {finalContactDetails && finalContactDetails.length > 0
          ? finalContactDetails.map((cd, id) => {
              return (
                <React.Fragment key={id}>
                  <div className="row">
                    {id != 0 ? (
                      <div className="col text-end">
                        <Button
                          name="Remove"
                          onClick={(e) => handleRemoveCustomEduBtnClick(e, id)}
                        ></Button>
                      </div>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="profile-img">
                        <img src={getImage(cd, id)} alt="" />
                        {cd?.profilePic ? (
                          <FontAwesomeIcon
                            className="deleteImg"
                            icon={faTimesCircle}
                            onClick={(e) => removeImage(e, id)}
                          ></FontAwesomeIcon>
                        ) : null}

                        <div className="file btn ">
                          Upload Image
                          <input
                            type="file"
                            name="profilePic"
                            onChange={(e) => handleCustomInputChange(e, id)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <label className="floating">
                        First Name<span className="required">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        name={"firstName"}
                        placeholder="First Name"
                        onChange={(e) => handleCustomInputChange(e, id)}
                        value={cd?.firstName ?? cd?.first_name}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">
                        Middle Name<span>*</span>{" "}
                      </label>
                      <input
                        type="text"
                        // name={isEdit ? "middle_name" : "middleName"}
                        name={"middleName"}
                        placeholder="Middle Name"
                        value={cd?.middleName ?? cd?.middle_name}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">
                        Last Name<span className="required">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        // name={isEdit ? "last_name" : "lastName"}
                        name={"lastName"}
                        placeholder="Last Name"
                        value={cd?.lastName ?? cd?.last_name}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col text-start"
                      style={{ position: "relative" }}
                    >
                      <label
                        className="floating"
                        style={{ position: "absolute", left: "10.5%" }}
                      >
                        Contact Number<span className="required">*</span>{" "}
                      </label>
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        // name={
                        //   isEdit
                        //     ? "person_contact_number"
                        //     : "personContactNumber"
                        // }
                        name={"personContactNumber"}
                        placeholder="Personal Number"
                        onChange={(e) => handleCustomPhoneChange(e, id)}
                        defaultCountry="NP"
                        value={
                          cd?.personContactNumber ?? cd?.person_contact_number
                        }
                      />
                      {cd?.personContactNumber || cd?.person_contact_number ? (
                        isValidPhoneNumber(
                          cd?.personContactNumber ?? cd?.person_contact_number
                        ) ? undefined : (
                          <small
                            style={{
                              color: "red",
                              marginTop: "-20px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Phone number is not valid
                          </small>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col">
                      <label className="floating">
                        Email<span className="required">*</span>{" "}
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={cd?.email}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <label className="floating">
                        Address<span className="required">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        name="address"
                        placeholder="Address"
                        value={cd?.address}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">
                        Designation<span className="required">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        name="designation"
                        placeholder="Designation"
                        value={cd?.designation}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                  </div>
                  <hr />
                </React.Fragment>
              );
            })
          : null}
        <div className="row mb-3">
          <div className="col text-end">
            <Button name="Add Contact Details" onClick={handleAdd}></Button>
          </div>
        </div>
      </div>
      <input
        type="button"
        name="previous"
        className="action-button-previous"
        value="Previous"
        onClick={handlePrevious}
      />

      <input
        type="button"
        name="next"
        className="next action-button"
        // value="Next"
        value={editParam?.isEdit || updateCompanyProfile ? "Update" : "Next"}
        onClick={
          editParam?.isEdit || updateCompanyProfile ? handleUpdate : handleNext
        }
      />
    </>
  );
};

export default ContactDetails;
