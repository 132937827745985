import React, { useState } from 'react'
import { useEffect } from 'react'
import { axiosPost } from '../../utils/AxiosApi';
import { URL } from '../../utils/Constants';
import swal from "sweetalert";
import { displayErrorAlert } from '../../utils/DisplayMessage';
import { Spinner } from 'reactstrap';

function Evaluation() {
    const [submitSpinner, setSubmitSpinner] = useState(false);
    const [evaluationIframe, setEvaluationIframe] = useState("");


    const getEvaluationData = (e) => {
        let params = {};

        setSubmitSpinner(true);
        axiosPost(
            URL.getEvaluation,
            params,
            (res) => {
                if (res.data.success) {
                    setSubmitSpinner(false);
                    setEvaluationIframe(res?.data?.data?.items?.loginUrl);
                }
            },
            (err) => {
                setSubmitSpinner(false);
                displayErrorAlert(err);
            }
        );
    };

    useEffect(() => {
        getEvaluationData();
    }, [])

    return (
        <div className="mt-2">
            <div className="pageTitle">Evaluation</div>

            {submitSpinner ? (
                <div className="container" style={{ padding: "1rem", height: "100%" }}>
                    <div className="fullWindow-Spinner">
                        <div>
                            <Spinner color="white"></Spinner>
                        </div>
                        <div style={{ fontSize: "16px", marginTop: "15px" }}>
                            Loading ...
                        </div>
                    </div>
                </div>
            ) :
                (
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <iframe
                                    src={evaluationIframe}
                                    width="600"
                                    height="800"
                                    style={{ width: "100%",height: "75vh" }}
                                >
                                </iframe>
                            </div>
                        </div>
                    </>)}

        </div>
    )
}

export default Evaluation