import React, { useState } from "react";
import Button from "../../components/common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faEye,
  faCheck,
  faCross,
  faTimesCircle,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import CommonModal from "../../components/common/Modal";
import { axiosPost } from "../../utils/AxiosApi";
import swal from "sweetalert";
import { URL } from "../../utils/Constants";
import moment from "moment";
import { faXbox } from "@fortawesome/free-brands-svg-icons";

const RequistionList = props => {
  const {
    requistionData,
    setIsEdit,
    toggleModal,
    getAllRequistionData
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [viewData, setViewData] = useState();
  const [isViewOpen, setIsViewOpen] = useState(false);
  const toggleViewModal = items => {
    setIsViewOpen(!isViewOpen);
    setViewData(items);
  };
  const [rejectId, setRejectId] = useState();
  const [isReject, setIsReject] = useState(false);
  const toggleRejectModal = items => {
    setIsReject(!isReject);
    setRemarks('')
    setRejectId(items?.id);
  };
  const toggleDeleteModal = () => setIsOpen(!isOpen);
  const [deleteId, setDeleteId] = useState();
  const [remarks,setRemarks] = useState();

  const handleRemarksChange=(e)=>{
    setRemarks(e.target.value)
  }

  const handleDelete = id => {
    const params = {
      id: id
    };
    axiosPost(URL.deleteRequistion, params, response => {
      if (response.status === 200) {
        swal("Data delete successfully");
        toggleDeleteModal();
        getAllRequistionData();
      }
    });
  };

  const handleApprove = items => {
    let params;
    if (items.status === "Pending") {
      params = {
        id: items?.id,
        remarks: null,
        type: "initial"
      };
    } else if (items.status === "Initial") {
      params = {
        id: items?.id,
        remarks: null,
        type: "final"
      };
    } 

    axiosPost(URL.approveRequistion, params, response => {
      if (response.status === 200) {
        swal("Requisition Approved");
        getAllRequistionData();
      }
    });
  };

  const handleReject=(e)=>{
   e.preventDefault()
     const  params = {
        id: rejectId,
        remarks: remarks,
        type: "reject"
      };
    
    axiosPost(URL.approveRequistion, params, response => {
      if (response.status === 200) {
        swal("Requisition Rejected");
        getAllRequistionData();
        toggleRejectModal()
      }
    });
  }

  return (
    <>
      <div className="table-responsive whiteContainer p-4">
        <table className="table table-sm table-custom">
          <thead>
            <tr>
              <th>SN.</th>
              <th>User</th>
              <th>Department</th>
              <th>Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {requistionData && requistionData.length > 0 ? (
              requistionData?.map((items, id) => {
                return (
                  <>
                    <tr key={id}>
                      <td>{id + 1}</td>
                      <td>{`${items?.user.first_name} ${
                        items?.user?.middle_name ? items?.user?.middle_name : ""
                      } ${items?.user.last_name}`}</td>
                      <td>{items?.department?.name}</td>
                      <td>{moment(items?.created_at).format("LL")}</td>
                      <td>{items?.status}</td>
                      <td>
                        {items.status !== "Initial" &&
                        items?.status !== "Final" ? (
                          <>
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-danger btn-just-icon btn-sm"
                              style={{ marginLeft: "0.75rem" }}
                              data-original-title=""
                              title="delete"
                              onClick={e => {
                                toggleDeleteModal();
                                setDeleteId(items.id);
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-secondary btn-just-icon btn-sm"
                              style={{ marginLeft: "0.75rem" }}
                              data-original-title=""
                              title="update"
                              onClick={e => {
                                setIsEdit(true);
                                toggleModal(items);
                              }}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                          </>
                        ) : (
                          ""
                        )}

                        <button
                          type="button"
                          rel="tooltip"
                          className="btn btn-primary btn-just-icon btn-sm"
                          style={{ marginLeft: "0.75rem" }}
                          data-original-title=""
                          title="view"
                          onClick={e => {
                            toggleViewModal(items);
                          }}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                        <button
                          type="button"
                          rel="tooltip"
                          className="btn btn-success btn-just-icon btn-sm"
                          style={{ marginLeft: "0.75rem" }}
                          data-original-title=""
                          title="Approve"
                          disabled={items.status === 'Final'}
                          onClick={e => {
                            handleApprove(items);
                          }}
                        >
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </button>

                        <button
                          type="button"
                          rel="tooltip"
                          className="btn btn-danger btn-just-icon btn-sm"
                          style={{ marginLeft: "0.75rem" }}
                          data-original-title=""
                          title="Reject"
                          disabled={items.status === 'Final'}
                          onClick={e => {
                            toggleRejectModal(items);
                          }}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </button>
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <span>No Data Found</span>
            )}
          </tbody>
        </table>
      </div>
      <CommonModal
        modal={isOpen}
        toggleModal={toggleDeleteModal}
        size={"md"}
        modalHeader={"Delete"}
        modalBody={
          <>
            Are you sure want to delete ?
            <div className="d-flex align-items-center mt-2 ">
              <Button name="Delete" onClick={e => handleDelete(deleteId)} />
              <Button name="Cancel" onClick={toggleDeleteModal} />
            </div>
          </>
        }
      />

      <CommonModal
        modal={isViewOpen}
        toggleModal={toggleViewModal}
        size={"md"}
        modalHeader={"View"}
        modalBody={
          <>
            <table className="table table-sm table-custom">
              <thead>
                <tr>
                  <th>SN.</th>
                  <th>Particulars</th>
                  <th>Quantity</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {viewData?.inventory_items &&
                viewData?.inventory_items?.length > 0
                  ? viewData?.inventory_items?.map((items, id) => {
                      return (
                        <>
                          {
                            <tr>
                              <td>{id + 1}</td>
                              <td>{items?.name}</td>
                              <td>{items?.quantity}</td>
                              <td>{items?.remarks}</td>
                            </tr>
                          }
                        </>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </>
        }
      />

      <CommonModal
        modal={isReject}
        toggleModal={toggleRejectModal}
        size={"md"}
        modalHeader={"Reject"}
        modalBody={
          <>
            <form>
              <div className="input-group">
                <textarea
                  type="text"
                  className="form-control"
                  id="basic-url"
                  name="remarks"
                  aria-describedby="basic-addon3"
                  placeholder="Remarks"
                  value={remarks}
                  onChange={e => handleRemarksChange(e)}
                />
              </div>
              <div className="mt-2">
                <Button name="Submit" onClick={(e)=>handleReject(e)} />
              </div>
            </form>
          </>
        }
      />
    </>
  );
};
export default RequistionList;
