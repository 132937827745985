import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import { cryptoSecretKey, URL } from "../utils/Constants";
import CommonModal from "../components/common/Modal";
import PasswordChange from "./DepartmentStaff/DepartmentStaffForm/PasswordChange";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { axiosGet } from "../utils/AxiosApi";

const Dashboard = () => {
  const [modal, setModal] = useState(false);
  const data = localStorage.getItem("roles");
  const userRole = ["Company Admin", "Developer Admin", "Super Admin"];
  const roles = JSON.parse(
    CryptoJS.AES.decrypt(data, cryptoSecretKey).toString(CryptoJS.enc.Utf8)
  );

  const isDepartmnetStaff = !userRole?.includes(roles[0]);
  const [yearlyLeaveData, setYearlyleaveData] = useState(null);

  const getAllAttendance = () => {
    axiosGet(URL.getMyAllAttendance, (res) => {
      if (res.status === 200) {
        let datum = res.data.data;
        let pendingLeaves =
          datum.total_count -
          (datum.accepted_count +
            datum.breached_count +
            datum.rejected_count +
            datum.revoked_count);
        let backgroundColor = [];
        for (let i = 0; i < 5; i++) {
          // let redV = Math.floor(Math.random() * 255) + 1;
          // let greenV = Math.floor(Math.random() * 255) + 1;
          // let blueV = Math.floor(Math.random() * 255) + 1;
          var max = 150;
          var min = 100;
          var green = Math.floor(Math.random() * (max - min + 1)) + min;
          let randomColor = `rgb(0,${green},0)`;
          backgroundColor.push(randomColor);
        }
        let stats = {
          labels: ["Accepted", "Rejected", "Pending", "Breached", "Revoked"],
          datasets: [
            {
              label: "Leaves (in Days)",
              backgroundColor: backgroundColor,
              data: [
                datum.accepted_count,
                datum.rejected_count,
                pendingLeaves,
                datum.breached_count,
                datum.revoked_count,
              ],
            },
          ],
        };
        setYearlyleaveData(stats);
      }
    });
  };

  const getChartData = () => {
    getAllAttendance();
    getMonthlyAttendance();
  };

  const getMonthlyAttendance = () => {
    axiosGet(URL.getMyMonthlyAttendance, (res) => {
      if (res.status === 200) {
      }
    });
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (
      isDepartmnetStaff &&
      JSON.parse(localStorage.getItem("isPasswordChanged")) === false
    ) {
      setModal(true);
    }
  }, [isDepartmnetStaff]);

  useEffect(() => {
    if (isDepartmnetStaff) {
      getChartData();
    }
  }, []);

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Dashboard </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            {yearlyLeaveData ? (
              <div className="whiteContainer p-4">
                <div className="newTextColor">Total Leaves</div>
                <Chart type="bar" data={yearlyLeaveData} />
              </div>
            ) : null}
          </div>
          <div className="col-md-2"></div>
          {/* <div className="col-md-4">Here wil  l be Monthly Leaves</div> */}
        </div>
      </div>

      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        size="lg"
        modalHeader="Change Pasword"
        modalBody={
          <>
            <PasswordChange toggleModal={toggleModal} />
          </>
        }
      />
    </>
  );
};
export default Dashboard;
