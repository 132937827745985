import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Button from "../../components/common/Button";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import FullPageSpinner from "../../components/common/FullPageSpinner";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CommonModal from "../../components/common/Modal";
import moment from "moment";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

const ReportFeedbackForm = ({ setLoader, toggleModal, fetchFeedbacks }) => {
  const [reportData, setReportData] = useState({
    module: "",
    problemType: "",
    description: "",
    isAnonymous: false,
  });

  const [module, setModules] = useState([]);

  const [problemType, setProblemType] = useState([
    { id: 1, name: "Technical Problem" },
    { id: 2, name: "Leave Request Problem" },
    { id: 3, name: "Others" },
  ]);

  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    axiosGet(
      URL.getModules,
      (response) => {
        setDataLoading(false);
        if (response.status === 200) {
          setModules(response.data);
        }
      },
      (err) => {
        setDataLoading(false);
        displayErrorAlert(err);
      }
    );
  }, []);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setReportData((prevState) => ({
      ...prevState,
      [name]: name === "isAnonymous" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let params = {
      module_id: reportData.module,
      problem_type: reportData.problemType,
      description: reportData.description,
      is_anonymous: reportData.isAnonymous,
    };

    if (reportData.module !== "" && reportData.problemType !== "") {
      setLoader(true);
      axiosPost(
        URL.storeFeedbacks,
        params,
        (response) => {
          if (response.status === 200) {
            swal("Success", "Your feedback/complain is sent successfully.");
            setLoader(false);
            setReportData({
              module: "",
              problemType: "",
              description: "",
              isAnonymous: false,
            });

            toggleModal();
            fetchFeedbacks();
          }
        },
        (err) => {
          setLoader(false);
          displayErrorAlert(err);
          swal("Error", err.response.data);
        }
      );
    } else {
      setLoader(false);
      swal("Error", "Select module and problem type first", "error");
      return;
    }
  };

  return (
    <div className="whiteContainer p-4">
      <form id="msform" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col text-start">
            <label className="floating">
              {" "}
              Module
              <span style={{ color: "red" }}>*</span>
            </label>
            <select
              className="formSelect"
              name="module"
              value={reportData.module}
              onChange={handleChange}
            >
              <option value="" disabled selected>
                Choose Module
              </option>
              {module.length > 0 ? (
                module?.map((module, idx) => (
                  <option value={module?.id} key={idx}>
                    {module?.name}
                  </option>
                ))
              ) : (
                <option value="error" disabled>
                  {dataLoading ? "Loading Data" : "No Data"}
                </option>
              )}
            </select>
          </div>
          <div className="col text-start">
            <label className="floating">
              Feedback / Complain Type <span style={{ color: "red" }}>*</span>
            </label>
            <select
              className="formSelect"
              name="problemType"
              onChange={handleChange}
              value={reportData.problemType}
            >
              <option value="" disabled selected>
                Choose Feedback / Complain Type
              </option>
              {problemType?.map((problemType, idx) => (
                <option value={problemType?.name} key={idx}>
                  {problemType?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <label className="floating">Description</label>
            <textarea
              rows={5}
              className="textArea"
              placeholder="Enter your description"
              name="description"
              onChange={handleChange}
              value={reportData.description}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-1">
            <input
              type="checkbox"
              name="isAnonymous"
              onChange={handleChange}
              checked={reportData.isAnonymous}
              id="anonymous"
            />
            <label className="floating" htmlFor="anonymous">
              Is Anonymous ?
            </label>
          </div>
        </div>
        <div className="text-end mt-2">
          <Button name="Send Feedback / Complain" type="submit" />
        </div>
      </form>
    </div>
  );
};

const FeedbackList = ({ feedbacks, loader }) => {
  return (
    <div>
      <div className="table-responsive whiteContainer p-4">
        <table className="table table-sm table-custom">
          <thead>
            <tr>
              <th>SN.</th>
              <th>Name</th>
              <th>Module</th>
              <th>Problem Type</th>
              <th>Description</th>
              <th>Response</th>
              <th>Responded Time</th>
              <th>Responded By</th>
            </tr>
          </thead>
          <tbody>
            {feedbacks && feedbacks.length > 0 ? (
              feedbacks.map((fb, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    {fb.is_anonymous ? (
                      <td>Anonymous</td>
                    ) : (
                      <td>
                        {fb.user.first_name} {fb.user.last_name}
                      </td>
                    )}
                    <td>{fb.module.name}</td>
                    <td>{fb.type}</td>
                    <td>{fb.description}</td>
                    <td>{fb.response ?? "-"}</td>
                    <td>
                      {fb.responded_time
                        ? moment.utc(fb.responded_time).local().format("ll")
                        : "-"}
                    </td>
                    <td>{fb?.approve_by?.first_name ?? "-"}</td>
                  </tr>
                );
              })
            ) : (
              <tr className="text-center">
                <td colSpan={9}>
                  {loader ? (
                    <LoadingAnimation spinner />
                  ) : (
                    "No any feedbacks received !!"
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ReportProblem = () => {
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    fetchFeedbacks();
  }, []);

  const fetchFeedbacks = () => {
    setIsFetching(true);
    axiosGet(
      URL.getFeedbacks,
      (response) => {
        if (response.status === 200) {
          setFeedbacks(response.data);
          setIsFetching(false);
        }
      },
      (err) => {
        setIsFetching(false);
      }
    );
  };

  return (
    <>
      {loader ? <FullPageSpinner text=" Processing ..." /> : null}
      <div className="container-fluid mt-4">
        <div className="pageTitle"> Feedback / Complain </div>

        {/* {assignedPermission?.store && ( */}
        <div className="">
          <Button name="Add Feedback / Complain" onClick={toggleModal} />
        </div>
        {/* )} */}
      </div>

      <div>
        <FeedbackList feedbacks={feedbacks} loader={isFetching} />
      </div>

      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        modalHeader={"Add Feedback"}
        size="lg"
        modalBody={
          <div className="container p-0 m-0">
            <ReportFeedbackForm
              loader={loader}
              setLoader={setLoader}
              toggleModal={toggleModal}
              fetchFeedbacks={fetchFeedbacks}
            />
          </div>
        }
      />
    </>
  );
};

export default ReportProblem;
