import React, { useEffect, useState } from "react";
import "../../assets/scss/departmentStaffList.scss";
import { URL } from "../../utils/Constants";
import { Spinner } from "reactstrap";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";
import { axiosGet } from "../../utils/AxiosApi";
import { displayErrorAlert } from "../../utils/DisplayMessage";

const DepartmentStaffList = (props) => {
  const {
    departmentStaff,
    handleDelete,
    handleEdit,
    handleUpload,
    allowedPermission,
    handleView,
    isLoading,
  } = props;

  const [roles, setRoles] = useState();

  const [designationlist, setDesignationlist] = useState(null);

  const getDesignation = () => {
    axiosGet(
      URL.getDesignationStaff,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;
          setDesignationlist(modules);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  useEffect(() => {
    getDesignation();
  }, []);

  // if (isLoading) {
  //   return <Spinner style={{ margin: "auto", display: "flex" }} />;
  // }
  return (
    <>
      <div className="mt-3">
        {departmentStaff && departmentStaff.length > 0 ? (
          departmentStaff.map((dpt, idx) => {
            return (
              <div key={idx} style={idx === 0 ? null : { marginTop: "10px" }}>
                <div className="departmentHeader">
                  {dpt.name}{" "}
                  {dpt.name.toLowerCase().includes("department")
                    ? null
                    : "Department"}{" "}
                  {dpt?.company.name ? `[ ${dpt?.company.name} ]` : null}
                </div>
                {dpt.staff.length > 0 ? (
                  <div className="table-responsive whiteContainer p-4 mt-0">
                    <table className="table table-sm table-custom">
                      <thead>
                        <tr>
                          {/* <td>S.N.</td> */}
                          <td>Staff Name</td>
                          <td>Office Email</td>
                          <td>Job Title</td>
                          <td>Designation</td>
                          <td>Corporate Number</td>
                          <td>User Enabled</td>
                          {/* <td>Gender</td> */}
                          <td>Actions</td>
                        </tr>
                      </thead>
                      <tbody>
                        {dpt.staff.map((dStf, dIdx) => {
                          return (
                            <tr key={dIdx}>
                              {/* <td>{dIdx + 1}</td> */}
                              <td>
                                {dStf.first_name} {dStf.last_name}
                                {dStf.status ? (
                                  <>
                                    <br></br>
                                    <span
                                      className={
                                        "statusChip " +
                                        (dStf.status === "Current"
                                          ? "statusChipCurrent"
                                          : "")
                                      }
                                    >
                                      {dStf.status}
                                    </span>
                                  </>
                                ) : null}
                              </td>
                              <td>{dStf.office_email}</td>
                              <td>{dStf.job_title}</td>
                              <td>{dStf?.designation_id ? designationlist?.filter((item) => item.id === dStf?.designation_id)[0]?.name : "-"}</td>
                              <td>{dStf.corporate_number}</td>
                              <td>
                                {dStf.user.is_enabled === 1 ? "True" : "False"}
                              </td>
                              {/* <td>{dStf.gender ?? "-"}</td> */}
                              <td>
                                <button
                                  type="button"
                                  rel="tooltip"
                                  className="btn btn-outline-primary rounded-circle btn-sm me-2"
                                  data-original-title=""
                                  title="View"
                                  onClick={(e) => handleView(e, dIdx, idx)}
                                >
                                  <i className="material-symbols-outlined">
                                    visibility
                                  </i>
                                </button>

                                {allowedPermission?.delete ? (
                                  <button
                                    type="button"
                                    rel="tooltip"
                                    className="btn btn-outline-danger rounded-circle btn-sm me-2"
                                    data-original-title={
                                      dStf.user.is_enabled === 1
                                        ? "Disable Staff"
                                        : "Enable Staff"
                                    }
                                    title={
                                      dStf.user.is_enabled === 1
                                        ? "Disable Staff"
                                        : "Enable Staff"
                                    }
                                    onClick={(e) =>
                                      handleDelete(
                                        e,
                                        dStf.id,
                                        dStf.user.is_enabled
                                      )
                                    }
                                  >
                                    <i className="material-symbols-outlined">
                                      {dStf.user.is_enabled === 1
                                        ? "person_off"
                                        : "person"}
                                    </i>
                                  </button>
                                ) : null}

                                {allowedPermission?.update ? (
                                  <button
                                    type="button"
                                    rel="tooltip"
                                    className="btn btn-outline-secondary rounded-circle btn-sm me-2"
                                    data-original-title=""
                                    title="Edit"
                                    onClick={(e) => {
                                      setRoles(dpt);
                                      handleEdit(dpt, dIdx, idx);
                                    }}
                                  >
                                    <i className="material-symbols-outlined">
                                      edit
                                    </i>
                                  </button>
                                ) : null}
                                {allowedPermission?.update &&
                                dStf.contract_file == null ? (
                                  <button
                                    type="button"
                                    rel="tooltip"
                                    className="btn btn-outline-secondary rounded-circle btn-sm me-2"
                                    data-original-title=""
                                    title="Upload Contract"
                                    onClick={(e) => handleUpload(e, dIdx, idx)}
                                  >
                                    <i className="material-symbols-outlined">
                                      upload_file
                                    </i>
                                  </button>
                                ) : null}

                                <a
                                  href={URL.downloadStaffZip + "/" + dStf.id}
                                  target="_blank"
                                >
                                  <button
                                    type="button"
                                    rel="tooltip"
                                    className="btn btn-outline-info rounded-circle btn-sm me-2"
                                    data-original-title=""
                                    title="Download"
                                  >
                                    <i className="material-symbols-outlined">
                                      file_download
                                    </i>
                                  </button>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="container ps-4 py-2">
                    <div className="row">
                      <div className="col newTextColor">
                        No department staff added for this department
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })
        ) : isLoading ? (
          <LoadingAnimation spinner />
        ) : (
          "No department staff"
        )}
      </div>
    </>
  );
};

export default DepartmentStaffList;
