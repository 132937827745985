import React from "react";
import "../../assets/scss/checkbox.scss";

const Checkbox = ({ name, handleChange, checked, className, disabled }) => {
  return (
    <>
      <input
        type="checkbox"
        onClick={handleChange}
        name={name}
        checked={checked}
        className={className}
        disabled={disabled}
      ></input>
    </>
  );
};

export default Checkbox;
