import React, { useState, useEffect } from "react";
import Button from "../../components/common/Button";
import CreateNoticeForm from "./CreateNoticeForm";
import CommonModal from "../../components/common/Modal";
import { URL, moduleName } from "../../utils/Constants";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import NoticesList from "./NoticesList";
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import { checkRolePermissions } from "../../utils/PermissionChecker";
import swal from "sweetalert";

const Notices = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [sendAll, setSendAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);
  const [storePermission, setStorePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [viewOwnPermission, setViewOwnPermission] = useState(false);
  const [modal, setModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [company, setCompany] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [departmentStaff, setDepartmentStaff] = useState(null);
  const [staffValue, setStaffValue] = useState();
  const [allNotices, setAllNotices] = useState(null);
  const [ownNotices, setOwnNotices] = useState(null);
  const [department, setDepartment] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [noticeType, setNoticeType] = useState(null);
  const [file, setFile] = useState(null);
  const [type] = useState([
    {
      name: "Notice",
      value: "Notice",
    },
    {
      name: "Circular",
      value: "Circular",
    },
  ]);

  const getViewPermission = () => {
    setViewPermission(
      checkRolePermissions("view", moduleName.notice) ||
        checkRolePermissions("viewAll", moduleName.notice)
    );
  };

  const getStorePermission = () => {
    setStorePermission(checkRolePermissions("store", moduleName.notice));
  };

  const getDeletePersmission = () => {
    setDeletePermission(checkRolePermissions("delete", moduleName.notice));
  };

  const getViewOwnPermission = () => {
    setViewOwnPermission(checkRolePermissions("viewOwn", moduleName.notice));
  };

  useEffect(() => {
    getStorePermission();
    getDeletePersmission();
    getViewOwnPermission();
    getViewPermission();
    getCompany();
    // getDepartment();
    getAllNoticesList("Notice");
  }, []);

  useEffect(() => {
    getDepartmentStaff();
  }, [selectedData?.department]);

  useEffect(() => {
    selectedData?.company && getDepartment();
  }, [selectedData?.company]);

  useEffect(() => {
    if (!modal) {
      setTitle(null);
      setNoticeType(null);
      setDescription(null);
      setSelectedData(null);
    }
  }, [modal]);

  useEffect(() => {
    setAllNotices(null);
    setLoading(false);
    const type = activeTab == "1" ? "Notice" : "Circular";
    getAllNoticesList(type);
  }, [activeTab]);

  const handleStaffChange = (e) => {
    setStaffValue(e);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "department") {
      setSelectedData((prevState) => ({
        ...prevState,
        ["department"]: value,
      }));
    } else if (name === "company") {
      setSelectedData((prevState) => ({
        ...prevState,
        ["company"]: value,
      }));
      setDepartment(null);
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  // const toggle = (tab) => {
  //   if (activeTab != tab) {
  //     setAllNotices(null);
  //     setActiveTab(tab);
  //   }
  // };

  const handleTextChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "files" && files && files[0]) {
      setFile(files[0]);
    } else {
      if (name === "title") {
        setTitle(value);
      } else if (name === "description") {
        setDescription(value);
      } else if (name === "type") {
        setNoticeType(value);
      }
    }
  };

  const handleSubmit = () => {
    if (title === null) {
      swal("Warning", "Please insert title.", "warning");
    } else if (type === null) {
      swal("Warning", "Please insert type.", "warning");
    } else if (!file && (!description || description === "")) {
      swal("Warning", "Please insert file or description.", "warning");
    } else if (
      sendAll === false &&
      selectedData.company !== null &&
      selectedData.department === undefined
    ) {
      swal("Warning", "Please Insert Department.", "warning");
    } else {
      setSpinner(true);
      let deptStaff = [];
      let formData = new FormData();
      if (sendAll === true) {
        formData.append("sendAll", sendAll);
      } else {
        deptStaff.push(selectedData.departmentStaff);
        let data = [];
        if (staffValue) {
          data = staffValue?.map((items) => items.value);
          formData.append("staffId[]", data);
        } else {
          // formData.append("staffId", JSON.stringify(data));
        }

        formData.append("companyId", selectedData?.company);
        formData.append("departmentId", selectedData?.department);
      }
      formData.append("title", title);
      formData.append("description", description);
      formData.append("type", noticeType);
      formData.append("files", file);

      axiosPost(
        URL.createNotices,
        formData,
        (response) => {
          if (response.status === 200) {
            swal("Success", `${noticeType} sent successfully.`, "success");
            setSpinner(false);
            setModal(false);
            setSelectedData(null);
            setTitle(null);
            setDescription(null);
            setNoticeType(null);
            setFile(null);
            getAllNoticesList(noticeType);
            // getOwnNoticesList(noticeType);
            setStaffValue(null);
            setSendAll(false);
            setActiveTab(noticeType === "Notice" ? "1" : "2");
          }
        },
        (err) => {
          displayErrorAlert(err);
          setSpinner(false);
        }
      );
    }
  };

  const getCompany = () => {
    axiosGet(
      URL.getRespectiveCompany,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;
          setCompany(modules);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const getDepartment = () => {
    let url =
      selectedData?.company === undefined
        ? URL.getDepartment
        : `${URL.getResDepartment}/${selectedData?.company}`;

    if (!selectedData?.company) {
      axiosGet(
        url,
        (response) => {
          if (response.data.success) {
            let modules = response.data.data.items;
            setDepartment(modules);
          }
        },
        (err) => {
          displayErrorAlert(err);
        }
      );
    } else {
      axiosGet(
        url,
        (response) => {
          if (response.data.success) {
            let modules = response.data.data.items;
            setDepartment(modules);
          }
        },
        (err) => {
          displayErrorAlert(err);
        }
      );
    }
  };

  const getDepartmentStaff = () => {
    axiosGet(
      `${URL.getDepartmentStaffLeave}/${selectedData?.department}`,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;
          modules &&
            modules.length > 0 &&
            modules.forEach((item) => {
              item.label = item?.first_name;
              item.value = item?.id;
            });
          setDepartmentStaff(modules);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const getAllNoticesList = (type) => {
    setLoading(true);
    const params = {
      type: type,
    };
    axiosPost(
      URL.getAllNotices,
      params,
      (response) => {
        if (response.status === 200) {
          setAllNotices(response.data.data.data);
          setLoading(false);
        }
      },
      (error) => {
        displayErrorAlert(error);
        setLoading(false);
      }
    );
  };

  // const getOwnNoticesList = (type) => {
  //   const params = {
  //     type: type,
  //   };
  //   axiosPost(
  //     URL.getOwnNotices,
  //     params,
  //     (response) => {
  //       if (response.status === 200) {
  //         setOwnNotices(response.data.data.values);
  //       }
  //     },
  //     (error) => {
  //       displayErrorAlert(error);
  //     }
  //   );
  // };

  const handleDelete = (items, name) => {
    const params = {
      id: items.id,
    };
    axiosPost(`${URL.deleteNoticesAndCircular}`, params, (response) => {
      if (response.status === 200) {
        swal("Success", ` Deleted successfully.`, "success");
        getAllNoticesList(items.type);
        // getOwnNoticesList(items.type);
      }
    });
  };

  useEffect(() => {
    if (sendAll === true) {
      setSelectedData(null);
      setStaffValue(null);
    }
  }, [sendAll]);

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Notices </div>
        {storePermission ? (
          <div className="row">
            <div className="col">
              <Button name="Create Notice" onClick={toggleModal} />
            </div>
          </div>
        ) : null}
        <div className="row no-gutters cborder p-4">
          <div className=" col-12">
            <div className="p-0 border-bottom-0">
              <Nav tabs fill>
                {viewPermission ? (
                  <>
                    <NavItem>
                      <NavLink
                        active={activeTab == "1"}
                        onClick={() => {
                          setActiveTab("1");
                          // getAllNoticesList("Notice");
                        }}
                        className="tab-text"
                        style={{ cursor: "pointer", color: "#495057" }}
                      >
                        Notices
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        active={activeTab == "2"}
                        onClick={() => {
                          setActiveTab("2");
                          // getAllNoticesList("Circular");
                        }}
                        className="tab-text"
                        style={{ cursor: "pointer", color: "#495057" }}
                      >
                        Circular
                      </NavLink>
                    </NavItem>
                  </>
                ) : null}
              </Nav>
            </div>
            <TabContent activeTab={activeTab}>
              {viewPermission ? (
                <>
                  <TabPane tabId="1">
                    <NoticesList
                      allNotices={allNotices}
                      handleDelete={handleDelete}
                      deletePermission={deletePermission}
                      loading={loading}
                      type="Notice"
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <NoticesList
                      allNotices={allNotices}
                      handleDelete={handleDelete}
                      deletePermission={deletePermission}
                      loading={loading}
                      type="Circular"
                    />
                  </TabPane>
                </>
              ) : null}
            </TabContent>
          </div>
        </div>
      </div>

      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        modalHeader={"Create Notice"}
        size="xl"
        modalBody={
          <CreateNoticeForm
            handleChange={handleChange}
            selectedData={selectedData}
            company={company}
            department={department}
            departmentStaff={departmentStaff}
            type={type}
            handleTextChange={handleTextChange}
            title={title}
            description={description}
            noticeType={noticeType}
            handleStaffChange={handleStaffChange}
            staffValue={staffValue}
            setSendAll={setSendAll}
            sendAll={sendAll}
          />
        }
        modalFooter={
          <div>
            <Button
              disabled={spinner}
              name={
                spinner ? (
                  <div className="text-center">
                    <Spinner
                      color="white"
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                    ></Spinner>{" "}
                  </div>
                ) : (
                  "Submit"
                )
              }
              onClick={() => handleSubmit()}
              className="ms-2"
            />
            <Button
              name="Cancel"
              onClick={() => toggleModal()}
              className="ms-2"
            />
          </div>
        }
      />
    </>
  );
};
export default Notices;
