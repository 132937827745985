import React, { useEffect, useState } from "react";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { moduleName, URL } from "../../utils/Constants";
import { Spinner } from "reactstrap";
import CommonModal from "../../components/common/Modal";
import Button from "../../components/common/Button";
import FullPageSpinner from "../../components/common/FullPageSpinner";
import swal from "sweetalert";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import moment from "moment";
import { checkRolePermissions } from "../../utils/PermissionChecker";
import Pagination from "react-js-pagination";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

const FeedbackResponseForm = ({
  isResponse,
  setIsSubmitting,
  toggleModal,
  fetchFeedback,
}) => {
  const [responseData, setResponseData] = useState(null);
  const submitResponse = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let params = {
      id: isResponse?.index,
      response: responseData,
    };
    axiosPost(
      URL.respondToFeedback,
      params,
      (response) => {
        if (response.status === 200) {
          toggleModal();
          fetchFeedback();
          setResponseData(null);
          swal("Success", "Response added succesfully", "success");
          setIsSubmitting(false);
        }
      },
      (err) => {
        setIsSubmitting(false);
        displayErrorAlert(err);
      }
    );
  };
  return (
    <div className="whiteContainer p-2">
      <form id="msform" onSubmit={submitResponse}>
        <div className="row mt-2">
          <div className="col">
            <label className="floating">Response</label>
            <textarea
              rows={5}
              required
              className="textArea"
              placeholder="Enter your response"
              name="response"
              onChange={(e) => setResponseData(e.target.value)}
              value={responseData?.response}
            />
          </div>
        </div>

        <div className="text-end mt-2">
          <Button name="Respond Feedback/Complain" type="submit" />
        </div>
      </form>
    </div>
  );
};

const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState(null);
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResponse, setIsResponse] = useState(null);
  const [updatePermission, setUpdatePermission] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    fetchFeedback();
    permissionConfig();
  }, []);

  const permissionConfig = () => {
    setUpdatePermission(
      checkRolePermissions("update", moduleName.feedbackComplain)
    );
  };

  const fetchFeedback = (pageNumber = 1) => {
    setLoader(true);
    axiosGet(
      URL.getAssociatedFeedbacks + "?" + "page=" + pageNumber,
      (response) => {
        if (response.status === 200) {
          setFeedbacks(response.data);
          setLoader(false);
        }
      }
    );
  };

  if (feedbacks) {
    var { current_page, per_page, total } = feedbacks;
  }

  return (
    <>
      {isSubmitting ? <FullPageSpinner text=" Processing ..." /> : null}
      <div className="container-fluid mt-4">
        <div className="pageTitle"> Feedback / Complain </div>
      </div>
      <div className="table-responsive whiteContainer p-4">
        <table className="table table-sm table-custom">
          <thead>
            <tr>
              <th>SN.</th>
              <th>Name</th>
              <th>Module</th>
              <th>Problem Type</th>
              <th>Description</th>
              <th>Response</th>
              <th>Response By</th>
              <th>Responded Time</th>
              {updatePermission ? <th>Actions</th> : null}
            </tr>
          </thead>
          <tbody>
            {feedbacks && feedbacks?.data && feedbacks.data.length > 0 ? (
              feedbacks.data.map((fb, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    {fb.is_anonymous ? (
                      <td>Anonymous</td>
                    ) : (
                      <td>
                        {fb.user.first_name} {fb.user.last_name}
                      </td>
                    )}
                    <td>{fb.module.name}</td>
                    <td>{fb.type}</td>
                    <td>{fb.description}</td>
                    <td>{fb.response ?? "-"}</td>
                    <td>{fb?.approve_by?.first_name ?? "-"}</td>
                    <td>
                      {fb.responded_time
                        ? moment.utc(fb.responded_time).local().format("ll")
                        : "-"}
                    </td>
                    {updatePermission ? (
                      <td>
                        {fb.response ? (
                          "-"
                        ) : (
                          <button
                            type="button"
                            rel="tooltip"
                            className="btn btn-outline-info rounded-circle btn-sm me-2"
                            data-original-title=""
                            title="Response"
                            onClick={(e) => {
                              toggleModal();
                              setIsResponse((prev) => ({
                                ...prev,
                                index: fb.id,
                              }));
                            }}
                          >
                            <i className="material-symbols-outlined">
                              <span className="material-symbols-outlined">
                                google_plus_reshare
                              </span>
                            </i>
                          </button>
                        )}
                      </td>
                    ) : null}
                  </tr>
                );
              })
            ) : (
              <tr className="text-center">
                <td colSpan={updatePermission ? 9 : 8}>
                  {loader ? (
                    <LoadingAnimation spinner />
                  ) : (
                    "No any feedbacks received !!"
                  )}{" "}
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {feedbacks?.data?.length || feedbacks?.total <= 5 ? null : (
          <div className="paginationContainer">
            <Pagination
              activePage={current_page}
              itemsCountPerPage={per_page}
              totalItemsCount={total}
              onChange={(pageNumber) => fetchFeedback(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              firstPageText="First"
              lastPageText="Last"
            />
          </div>
        )}
      </div>

      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        modalHeader={"Add Response"}
        size="lg"
        modalBody={
          <div className="container p-0 m-0">
            <FeedbackResponseForm
              isResponse={isResponse}
              setIsSubmitting={setIsSubmitting}
              toggleModal={toggleModal}
              fetchFeedback={fetchFeedback}
            />
          </div>
        }
      />
    </>
  );
};

export default Feedback;
