import React, { useState } from "react";
import CommonModal from "../../components/common/Modal";
import { ViewDepartmentLeaveDetails } from "./viewDepartmentLeaveDetails";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

const ListDepartmentLeave = ({ dataLeave, isLoading }) => {
  const [modal, setModal] = useState(false);
  const [selectedLeaveToView, setselectedLeaveToView] = useState(null);
  const toggleModal = () => {
    setModal(!modal);
  };

  const handleViewLeave = (e, id) => {
    const selectedLeave = dataLeave?.find(
      (dLeav) => dLeav?.id === Number(id)
    )?.leave_report;
    setselectedLeaveToView(selectedLeave);
    setModal(true);
  };
  return (
    <>
      <div className="table-responsive whiteContainer p-4">
        <table className="table table-sm table-custom">
          <thead>
            <tr>
              <th>SN.</th>
              <th>Name</th>
              <th>Job Title</th>
              <th>Email</th>
              <th>Total Leave Requests</th>
              <th>Accepted Leave Days</th>
            </tr>
          </thead>
          <tbody>
            {dataLeave && dataLeave.length > 0 ? (
              dataLeave.map((leave, id) => (
                <tr key={id}>
                  <td>{id + 1}</td>
                  <td>
                    {leave?.first_name}{" "}
                    {leave?.middle_name && leave?.middle_name}{" "}
                    {leave?.last_name}
                  </td>
                  <td>{leave?.job_title}</td>

                  <td>{leave?.office_email}</td>
                  <td>
                    {leave.leave_report.leaves.length > 0
                      ? leave.leave_report.leaves.length
                      : 0}
                  </td>
                  <td>{leave?.leave_report?.total_accepted_days}</td>

                  {/* <td>
                          <button
                            type="button"
                            rel="tooltip"
                            className="btn btn-primary btn-just-icon btn-sm"
                            style={{ marginLeft: "0.75rem" }}
                            data-original-title=""
                            title="Delete"
                            onClick={(e) => handleViewLeave(e, leave?.id)}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                        </td> */}
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan={6}>
                  {isLoading ? (
                    <LoadingAnimation spinner />
                  ) : (
                    "No Leave request have been created yet."
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        size="lg"
        modalHeader="Leave Details"
        modalBody={
          <ViewDepartmentLeaveDetails
            selectedLeaveToView={selectedLeaveToView}
          />
        }
      />
    </>
  );
};

export default ListDepartmentLeave;
