import React, { Component } from "react";

class GatePassDepartmentList extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div className="table-responsive whiteContainer p-4">
        <table className="table table-sm table-custom">
          <thead>
            <tr>
              <th>S.N.</th>
              <th>Name</th>
              <th>Job Title</th>
              <th>Email</th>
              <th>Total Gatepass Requests</th>
              <th>Accepted Gatepass Time (in Hours)</th>
            </tr>
          </thead>
          <tbody>
            {this.props.dataGatepass && this.props.dataGatepass.length > 0 ? (
              this.props.dataGatepass.map((gP, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>
                      {gP?.first_name} {gP?.middle_name && gP?.middle_name}{" "}
                      {gP?.last_name}
                    </td>
                    <td>{gP?.job_title}</td>
                    <td>{gP?.office_email}</td>
                    <td>
                      {gP.pass_report.gatepasses.length > 0
                        ? gP.pass_report.gatepasses.length
                        : 0}
                    </td>
                    <td>{gP.pass_report.total_accepted_hours}</td>
                  </tr>
                );
              })
            ) : (
              <tr className="text-center">
                <td colSpan={6}>No Gatepass request have been created yet </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default GatePassDepartmentList;
