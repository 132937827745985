import React from "react";
import { fileUrl } from "../../utils/Constants";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

const NoticesList = (props) => {
  const { allNotices, handleDelete, deletePermission, loading, type } = props;

  return (
    <>
      <div className="table-responsive whiteContainer p-4">
        <table className="table table-sm table-custom">
          <thead>
            <tr>
              <th>Title</th>
              <th>Company</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {allNotices && allNotices.length > 0 ? (
              allNotices.map((items, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>{items.title}</td>
                      <td>{items?.company?.name}</td>
                      <td>{items.description}</td>
                      <td>
                        <div>
                          <div>
                            {items.media && items.media.length ? (
                              <button
                                type="button"
                                rel="tooltip"
                                className="btn btn-outline-primary rounded-circle btn-sm"
                                data-original-title=""
                                title=""
                                // onClick={e => handleView(e, items.id)}
                              >
                                <i className="material-symbols-outlined">
                                  <a
                                    href={
                                      fileUrl +
                                      "/" +
                                      items.media[0]?.id +
                                      "/" +
                                      items.media[0]?.file_name
                                    }
                                    target={"_blank"}
                                  >
                                    {" "}
                                    visibility
                                  </a>
                                </i>
                              </button>
                            ) : null}
                          </div>

                          {deletePermission && items.company_id==localStorage.getItem('companyId') ? (
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-outline-danger rounded-circle btn-sm mt-2"
                              data-original-title=""
                              title="Delete"
                              onClick={(e) => {
                                handleDelete(items);
                              }}
                            >
                              <i className="material-symbols-outlined">
                                delete
                              </i>
                            </button>
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr className="text-center">
                {loading ? (
                  <td colSpan="4">
                    <LoadingAnimation spinner />
                  </td>
                ) : (
                  <td colSpan="4">{`No ${type} found.`}</td>
                )}
              </tr>
            )}
            <tr></tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default NoticesList;
