import React, { useState, useEffect } from "react";
import { Spinner, Label } from "reactstrap";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL, moduleName } from "../../utils/Constants";
import { checkRolePermissions } from "../../utils/PermissionChecker";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import Button from "../../components/common/Button";
import swal from "sweetalert";
import moment from "moment";
import GatePassDepartmentList from "./GatePassDepartmentList";
import CommonModal from "../../components/common/Modal";

const ViewAllGatePass = () => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [company, setCompany] = useState(null);
  const [department, setDepartment] = useState(null);
  const [departmentStaff, setDepartmentStaff] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [gatePassData, setGatePassData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [assignPermission, setAssignPermission] = useState(false);
  const [gatePassDepartmentData, setGatepassDepartmentData] = useState(null);
  const [inTime, setInTime] = useState();
  const [outTime, setOutTime] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [actialTimeId, setActualTimeId] = useState();

  const toggleUpdateModal = (id) => {
    setIsOpen(!isOpen);
    setActualTimeId(id);
  };

  useEffect(() => {
    if (isOpen === false) {
      setInTime("");
      setOutTime("");
    }
  }, [isOpen]);

  // const companyId = JSON.parse(localStorage.getItem("companyId"));

  const isParentCompany = JSON.parse(localStorage.getItem("parent_company"));

  const handleInTimeChange = (e) => {
    setInTime(e.target.value);
  };

  const handleOutTimeChange = (e) => {
    setOutTime(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      actual_time_to: inTime,
      actual_time_from: outTime,
      id: actialTimeId,
    };
    axiosPost(URL.gatePassUpdateActualTime, params, (response) => {
      if (response.status === 200) {
        swal("Success", "Gate Pass Acutal Time Added Successfully", "success");
        setIsOpen(!isOpen);
      }
    });
  };

  const fetchGatePass = () => {
    setGatePassData(null);
    setIsFetching(true);
    setGatepassDepartmentData(null);
    axiosPost(
      URL.gatePassFilter,
      {
        staff_id: selectedData?.departmentStaff ?? null,
        company_id: selectedData?.company ?? null,
        pass_from: selectedData?.from ?? null,
        pass_to: selectedData?.to ?? null,
        department_id: selectedData?.department ?? null,
      },
      (response) => {
        if (response.status === 200) {
          setIsFetching(false);
          let modules = response.data;
          setGatePassData(modules);
        }
      },
      (err) => {
        setIsFetching(false);
        displayErrorAlert(err);
      }
    );
  };

  const GatePassList = () => {
    const handleApprove = (e, id) => {
      setSubmitSpinner(true);
      let params = { id, approval_status: "Accepted" };
      axiosPost(
        URL.acceptRejectGatePass,
        params,
        (response) => {
          if (response.status === 200) {
            swal("Success", "Gate Pass approved succesfully", "success");
            fetchGatePass();
            setSubmitSpinner(false);
          }
        },
        (err) => {
          setSubmitSpinner(false);
          displayErrorAlert(err);
        }
      );
    };

    const handleReject = (e, id) => {
      setSubmitSpinner(true);
      let params = { id, approval_status: "Rejected" };
      axiosPost(
        URL.acceptRejectGatePass,
        params,
        (response) => {
          if (response.status === 200) {
            swal("Success", "Gate Pass rejected succesfully", "success");
            fetchGatePass();
            setSubmitSpinner(false);
          }
        },
        (err) => {
          setSubmitSpinner(false);
          displayErrorAlert(err);
        }
      );
    };
    return (
      <>
        <div className="row">
          <div className="col-lg-12 col-md-12 ml-auto mr-auto">
            <div className="table-responsive whiteContainer p-4">
              <table className="table table-sm table-custom text-start">
                <thead>
                  <tr>
                    <th>SN.</th>
                    <th>Requested By</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Reason</th>
                    <th>Duration (Hours:Minute)</th>

                    <th>Status</th>

                    <th>Responded By</th>
                    {updatePermission || deletePermission ? (
                      <th>Actions</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {gatePassData && gatePassData.length > 0 ? (
                    gatePassData.map((gP, id) => (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td>
                          {gP.user.first_name}
                          {gP.user.middle_name
                            ? ` ${gP.user.middle_name} `
                            : " "}
                          {gP.user.last_name}
                        </td>
                        <td>{gP.pass_date}</td>
                        <td>
                          {moment(gP.time_from, "HH:mm:ss").format("hh:mm A")} -{" "}
                          {moment(gP.time_to, "HH:mm:ss").format("hh:mm A")}
                        </td>
                        <td>{gP.reason}</td>
                        <td>
                          {`0${(gP.duration / 60) ^ 0}`.slice(-2) +
                            ":" +
                            ("0" + (gP.duration % 60)).slice(-2)}
                        </td>
                        <td>{gP.approval_status}</td>
                        <td>
                          {gP.approve_by?.first_name
                            ? gP.approve_by.first_name
                            : "-"}
                        </td>
                        {updatePermission ||
                        deletePermission ||
                        assignPermission ? (
                          <td>
                            {updatePermission &&
                            gP.approval_status === "Pending" ? (
                              gP.approval_status === "Accepted" ? null : (
                                <div>
                                  <button
                                    type="button"
                                    rel="tooltip"
                                    className="btn btn-outline-info rounded-circle btn-sm me-2"
                                    data-original-title=""
                                    title="Approve"
                                    onClick={(e) =>
                                      handleApprove(e, gP.gatepass_id)
                                    }
                                  >
                                    <i className="material-symbols-outlined">
                                      done_all
                                    </i>
                                  </button>
                                </div>
                              )
                            ) : null}

                            {assignPermission ? (
                              <button
                                type="button"
                                rel="tooltip"
                                className="btn btn-outline-info rounded-circle btn-sm me-2"
                                data-original-title=""
                                title="UPDATE"
                                onClick={() => toggleUpdateModal(gP.id)}
                              >
                                <i className="material-symbols-outlined">
                                  update
                                </i>
                              </button>
                            ) : null}

                            {deletePermission ||
                            gP.approval_status === "Pending" ? (
                              gP.approval_status === "Rejected" ? null : (
                                <button
                                  type="button"
                                  rel="tooltip"
                                  className="btn btn-outline-danger rounded-circle btn-sm me-2"
                                  data-original-title=""
                                  title="Reject"
                                  onClick={(e) =>
                                    handleReject(e, gP.gatepass_id)
                                  }
                                >
                                  <i className="material-symbols-outlined">
                                    remove_done
                                  </i>
                                </button>
                              )
                            ) : null}
                          </td>
                        ) : null}
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td
                        colSpan={updatePermission || deletePermission ? 10 : 9}
                      >
                        {isFetching ? (
                          <div className="text-center">
                            <Spinner color="whiteSmoke" size="sm" />
                          </div>
                        ) : (
                          "No Gate Pass found"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };

  const getCompany = () => {
    axiosGet(
      URL.getRespectiveCompany,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;
          setCompany(modules);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const getDepartment = () => {
    let url =
      selectedData?.company === undefined
        ? URL.getDepartment
        : `${URL.getResDepartment}/${selectedData?.company}`;
    axiosGet(
      url,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;
          setDepartment(modules);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const getDepartmentFilterGatepass = () => {
    let param = {
      dept_id: Number(selectedData?.department),
      from_date: null,
      to_date: null,
    };
    axiosPost(
      URL.getPassFilterByDepartment,
      param,
      (res) => {
        if (res.status === 200) {
          setGatepassDepartmentData(res.data);
          setDepartmentStaff(res.data);
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedData((prev) => ({ ...prev, [name]: value }));
  };

  const permissionConfig = () => {
    setUpdatePermission(checkRolePermissions("update", moduleName.gatePass));
    setDeletePermission(checkRolePermissions("delete", moduleName.gatePass));
    setAssignPermission(
      checkRolePermissions("assignActualTime", moduleName.gatePass)
    );
  };

  const resetData = () => {
    setIsFilter(false);
    setSelectedData(null);
    setGatepassDepartmentData(null);
    // setLeaveDate(null);
    // setLeaveData(null);
    // setLeaveDepartmentData(null);
    // setSelectedLeaveToView(null);
    // isParentCompany && setDepartment(null);
  };

  useEffect(() => {
    getCompany();
    getDepartment();
    permissionConfig();
  }, []);

  useEffect(() => {
    if (selectedData?.departmentStaff) {
      fetchGatePass();
    }
  }, [selectedData?.departmentStaff]);

  useEffect(() => {
    if (selectedData?.department) {
      setGatepassDepartmentData(null);
      setSelectedData((prevState) => ({
        ...prevState,
        departmentStaff: null,
      }));
      getDepartmentFilterGatepass();
    }
  }, [selectedData?.department]);

  useEffect(() => {
    if (selectedData?.company) {
      setSelectedData((prevState) => ({
        ...prevState,
        department: null,
        departmentStaff: null,
      }));
      getDepartment();
    }
  }, [selectedData?.company]);

  useEffect(() => {
    fetchGatePass();
  }, [selectedData?.department]);

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">View Gate Pass </div>

        {submitSpinner && (
          <div
            className="container"
            style={{ padding: "1rem", height: "100%" }}
          >
            <div className="fullWindow-Spinner">
              <div>
                <Spinner color="white"></Spinner>
              </div>
              <div style={{ fontSize: "16px", marginTop: "15px" }}>
                Please Wait. Submitting ...
              </div>
            </div>
          </div>
        )}

        <div className="whiteContainer p-4">
          <div className="row justify-content-center d-flex">
            {isParentCompany && (
              <div className="col" style={{ position: "relative" }}>
                <label className="floating">Company</label>
                <select
                  className="formSelect"
                  name="company"
                  onChange={handleChange}
                  value={selectedData?.company ?? ""}
                >
                  <option value="" disabled selected>
                    Choose Company
                  </option>
                  {company?.map((cmp, idx) => (
                    <option value={cmp?.id} key={idx}>
                      {cmp?.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="col" style={{ position: "relative" }}>
              <label className="floating">Department</label>
              <select
                className="formSelect"
                name="department"
                onChange={handleChange}
                value={selectedData?.department ?? ""}
                // disabled={
                //   isParentCompany
                //     ? selectedData?.company === undefined && true
                //     : false
                // }
                disabled={false}
              >
                <option value="" disabled selected>
                  Choose Department
                </option>
                {department?.map((dpt, idx) => (
                  <option value={dpt?.id} key={idx}>
                    {dpt?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col" style={{ position: "relative" }}>
              <label className="floating">Department staff</label>
              <select
                className="formSelect"
                name="departmentStaff"
                onChange={handleChange}
                value={selectedData?.departmentStaff ?? ""}
                disabled={selectedData?.department === undefined ? true : false}
              >
                <option value="" disabled selected>
                  Choose DepartmentStaff
                </option>
                {departmentStaff?.map((dptStf, idx) => (
                  <option value={dptStf?.user_id} key={idx}>
                    {dptStf?.first_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {isFilter && (
            <div className="row">
              <div className="col text-start" style={{ position: "relative" }}>
                <label className="floating">From</label>
                <input
                  type="date"
                  style={myInput}
                  className="form-control"
                  name="from"
                  onChange={handleChange}
                  value={selectedData?.from ?? ""}
                ></input>
              </div>

              <div className="col text-start" style={{ position: "relative" }}>
                <label className="floating">To</label>
                <input
                  type="date"
                  style={myInput}
                  className="form-control"
                  name="to"
                  onChange={handleChange}
                  value={selectedData?.to ?? ""}
                ></input>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col text-end">
              <Button name="Filter" onClick={() => setIsFilter(!isFilter)} />

              {/* {selectedData?.department && selectedData?.departmentStaff ? (
                <Button
                  name="Export"
                  onClick={() => handleExportLeave("individual")}
                  className="ms-2"
                />
              ) : (
                selectedData?.department &&
                selectedData?.departmentStaff === undefined && (
                  <Button
                    name="Export All"
                    onClick={() => handleExportLeave("all")}
                    className="ms-2"
                  />
                )
              )} */}
              <Button
                name="Reset"
                onClick={() => resetData()}
                className="ms-2"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-center d-flex text-center">
          <div className="mt-2">
            {gatePassDepartmentData &&
            (selectedData?.departmentStaff === null ||
              selectedData?.departmentStaff === undefined) ? (
              <GatePassDepartmentList dataGatepass={gatePassDepartmentData} />
            ) : (
              <GatePassList />
            )}
          </div>
        </div>
      </div>
      <CommonModal
        modal={isOpen}
        toggleModal={() => toggleUpdateModal()}
        modalHeader="In Out Time"
        modalBody={
          <>
            <form onSubmit={handleSubmit} id="msform">
              <div className="row">
                <div className="col-md-6 text-start">
                  <label className="floating">Actual Out Time</label>
                  <input
                    type="time"
                    className="form-control"
                    placeholder="Out Time"
                    name="outTime"
                    onChange={(e) => handleOutTimeChange(e)}
                    value={outTime}
                  />
                </div>
                <div className="col-md-6 text-start">
                  <label className="floating">Actual In Time</label>
                  <input
                    type="time"
                    className="form-control"
                    placeholder="In Time"
                    name="inTime"
                    onChange={(e) => handleInTimeChange(e)}
                    value={inTime}
                    min={outTime}
                  />
                </div>
                <div style={{ float: "right", marginTop: "0.5rem" }}>
                  <Button
                    name={"Submit"}
                    type="submit"
                    className="custom-btn all btn-9 enableBtn"
                  />
                  <button
                    name={"Cancel"}
                    type="Reset"
                    className="custom-btn all btn-9 enableBtn"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </>
        }
      ></CommonModal>
    </>
  );
};

const myInput = {
  padding: "25px 20px 12px 15px",
  marginBottom: "12px",
};

export default ViewAllGatePass;
