import React, { useEffect, useState } from "react";
import { axiosPost ,axiosGet } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import Button from "../../components/common/Button";
import ItemsList from "./ItemsList";
import CommonModal from "../../components/common/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";

const Items = () => {
  const [itemsList, setItemsList] = useState();
  const [formDetails, setFormDetails] = useState([
    {
      name: "",
      sub_categories: [
        {
          name: "",
          description: "",
          quantity: "",
          inventory_items: [
            {
              name: "",
              quantity: "",
              description: ""
            }
          ]
        }
      ]
    }
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [subCategoryRow, setSubCategoryRow] = useState(false);
  const [itemsRow, setItemsRow] = useState(false);
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [isEditSubCategory, setIsEditSubCategory] = useState(false);
  const [isEditItems, setIsEditSubItems] = useState(false);
  const [categoryId,setCategoryId] = useState();

  const toggleCreateModal = items => {
    getAllCategory();
    setIsOpen(!isOpen);
    

    // setCategoryList(items.name);
    //for edit
    if (items) {
      // const d= [];
      // d.push(items);
      setFormDetails([items]);
    }
    setCategoryId(items.id)

    // items.sub_categories &&
    //   items?.sub_categories.length > 0 &&
    //   items?.sub_categories?.map(values => {
    //     setSubCategoryList(values.name);
    //     values.inventory_items &&
    //       values.inventory_items.length > 0 &&
    //       values.inventory_items.map(value => setSubItemsList(value.name));
    //   });
  };

  const toggleItemsRow = ({ id, idx }) => {
    setItemsRow(!itemsRow);
    let itemsList1 = [...itemsList];
    itemsList1[id].sub_categories.forEach((item, index) => {
      if (idx === index) {
        item.collapse = !item.collapse;
      } else {
        item.collapse = false;
      }
    });
    setItemsList(itemsList1);
  };

  const toggleSubRow = index => {
    setSubCategoryRow(!subCategoryRow);
    let itemsList1 = [...itemsList];

    itemsList1.forEach((item, idx) => {
      if (idx === index) {
        item.collapse = !item.collapse;
      } else {
        item.collapse = false;
      }
    });
    setItemsList(itemsList1);
  };

  const handleAdd = status => {
    const formData = [...formDetails];
    formData?.push({
      name: "",
      sub_categories: [
        {
          name: "",
          description: "",
          quantity: "",
          inventory_items: [
            {
              name: "",
              description: "",
              quantity: ""
            }
          ]
        }
      ]
    });
    setFormDetails(formData);
  };

  const handleSubCategoryAdd = (status, index) => {
    const formData = [...formDetails];
    formData[index].sub_categories.push({
      name: "",
      description: "",
      quantity: "",
      inventory_items: [
        {
          name: "",
          description: "",
          quantity: ""
        }
      ]
    });
    setFormDetails(formData);
  };

  const handleItemsAdd = (status, { index, idx }) => {
    const formData = [...formDetails];
    formData[index].sub_categories[idx].inventory_items.push({
      name: "",
      description: "",
      quantity: ""
    });
    setFormDetails(formData);
  };

  const handleRemove = id => {
    const formData = [...formDetails];
    formData.splice(id, 1);
    setFormDetails(formData);
  };

  const handleSubCategoryRemove = ({ index, id }) => {
    const formData = [...formDetails];
    formData[index].sub_categories.splice(id, 1);
    setFormDetails(formData);
  };

  const handleItemsRemove = ({ index, idx, id }) => {
    const formData = [...formDetails];
    formData[index].sub_categories[idx].inventory_items.splice(id, 1);
    setFormDetails(formData);
  };

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    const formData = [...formDetails];
    formData[id][name] = value;
    setFormDetails(formData);
  };

  const handleSubCategoryChange = (e, id, index) => {
    const { name, value } = e.target;
    const formData = [...formDetails];
    formData[index].sub_categories[id][name] = value;
    setFormDetails(formData);
  };

  const handleItemsChange = (e, id, idx, index) => {
    const { name, value } = e.target;
    const formData = [...formDetails];
    formData[index].sub_categories[idx].inventory_items[id][name] = value;
    setFormDetails(formData);
  };
 

  const handleSubmit = e => {
   
    let params = {
      ...formDetails
    };
     let paramsCategory={
       
       name : formDetails[0].name,
       id: categoryId
     }
    let url = isEditCategory ? URL.updateCatgory : isEditSubCategory ? URL.updateSubCategory : isEditItems ? URL.updateCategoryItems : URL.createItemsCategory;
    e.preventDefault();
    axiosPost(url, paramsCategory, response => {
      if (response.status === 200 || 200) {
        swal("Items Created Successfully");
      }
    });
  };

  const getAllCategory = () => {
    axiosGet(URL.getAllItemsCategory, response => {
      if (response.status === 200) {
        const collapse = response.data.data.items.forEach(
          item => (item.collapse = false)
        );
        const collapseItems = response.data.data.items.map(value =>
          value.sub_categories.forEach(values => (values.collapse = false))
        );
        setItemsList(response.data.data.items, collapse, collapseItems);
      }
    });
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  useEffect(() => {
    if (isOpen === false) {
      setFormDetails([
        {
          name: "",
          sub_categories: [
            {
              name: "",
              description: "",
              quantity: "",
              inventory_items: [
                {
                  name: "",
                  quantity: "",
                  description: ""
                }
              ]
            }
          ]
        }
      ]);
      setIsEdit(false);
    }
  }, [isOpen]);

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Items List</div>
        <div>
          <Button
            name="Add Items"
            onClick={() => {
              setIsEditCategory(true);
              setIsEditSubCategory(true);
              setIsEditSubItems(true);
              toggleCreateModal();
            }}
          />
        </div>
        <ItemsList
          itemsList={itemsList ? itemsList : ""}
          toggleSubRow={toggleSubRow}
          subCategoryRow={subCategoryRow}
          itemsRow={itemsRow}
          toggleItemsRow={toggleItemsRow}
          setIsEdit={setIsEdit}
          toggleModal={toggleCreateModal}
          setIsEditCategory={setIsEditCategory}
          setIsEditSubCategory={setIsEditSubCategory}
          setIsEditSubItems={setIsEditSubItems}
        />
      </div>

      <CommonModal
        modal={isOpen}
        toggleModal={toggleCreateModal}
        size={"lg"}
        modalHeader={
          !isEdit
            ? "Create Category"
            : isEditCategory
            ? "Edit Category"
            : isEditSubCategory
            ? "Edit SubCategory"
            : "Edit Items"
        }
        modalBody={
          <>
            <form onSubmit={e => handleSubmit(e)}>
              {formDetails &&
                formDetails?.map((items, index) => {
                  return (
                    <>
                      <div className="row my-2">
                        <div className="col-md-10">
                          <div className="input-group ">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="name"
                              name="name"
                              onChange={e => handleChange(e, index, "name")}
                              value={items.name}
                              disabled={isEditCategory === false}
                            />
                          </div>
                        </div>
                        {isEdit === false ? (
                          <div className="col-2">
                            <div className="">
                              {index === formDetails.length - 1 ? (
                                <FontAwesomeIcon
                                  icon={faPlusCircle}
                                  onClick={() => handleAdd("To")}
                                  className="addListBtn me-2"
                                />
                              ) : null}
                              {index !== 0 ? (
                                <FontAwesomeIcon
                                  icon={faMinusCircle}
                                  onClick={() => handleRemove(index, "To")}
                                  className="removeListBtn"
                                  style={{ cursor: "pointer" }}
                                />
                              ) : null}
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {items.name &&
                        items?.sub_categories?.map((item, idx) => {
                          return (
                            <>
                              <div
                                className="row mt-2"
                                style={{ marginLeft: "5px" }}
                              >
                                <div className="col-md-9">
                                  <div className="input-group ">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Sub Category"
                                      name="name"
                                      disabled={isEditSubCategory === false}
                                      onChange={e =>
                                        handleSubCategoryChange(
                                          e,
                                          idx,
                                          index,
                                          "name"
                                        )
                                      }
                                      value={item.name}
                                    />
                                  </div>
                                </div>
                                {
                                  isEdit === false ? (
                                    <div className="col-2">
                                    <div className="">
                                      {idx ===
                                      formDetails[index].sub_categories.length -
                                        1 ? (
                                        <FontAwesomeIcon
                                          icon={faPlusCircle}
                                          onClick={() =>
                                            handleSubCategoryAdd("name", index)
                                          }
                                          className="addListBtn me-2"
                                        />
                                      ) : null}
                                      {idx !== 0 ? (
                                        <FontAwesomeIcon
                                          icon={faMinusCircle}
                                          onClick={() =>
                                            handleSubCategoryRemove({
                                              index,
                                              idx
                                            })
                                          }
                                          className="removeListBtn"
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                  ) : null
                                }
                              
                              </div>
                              {item.name &&
                                item.inventory_items.map((values, id) => {
                                  return (
                                    <>
                                      <div
                                        className="row mt-2"
                                        style={{ marginLeft: "35px" }}
                                      >
                                        <div className="col-md-8">
                                          <div className="input-group ">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Items"
                                              name="name"
                                              disabled={isEditItems === false}
                                              onChange={e =>
                                                handleItemsChange(
                                                  e,
                                                  id,
                                                  idx,
                                                  index,
                                                  "name"
                                                )
                                              }
                                              value={values.name}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-2">
                                          <div className="">
                                            {id ===
                                            formDetails[index].sub_categories[
                                              idx
                                            ].inventory_items.length -
                                              1 ? (
                                              <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                onClick={() =>
                                                  handleItemsAdd("name", {
                                                    index,
                                                    idx
                                                  })
                                                }
                                                className="addListBtn me-2"
                                              />
                                            ) : null}
                                            {id !== 0 ? (
                                              <FontAwesomeIcon
                                                icon={faMinusCircle}
                                                onClick={() =>
                                                  handleItemsRemove({
                                                    index,
                                                    idx,
                                                    id
                                                  })
                                                }
                                                className="removeListBtn"
                                                style={{
                                                  cursor: "pointer"
                                                }}
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                            </>
                          );
                        })}
                    </>
                  );
                })}
              <div className="mt-2">
                <Button name="Submit" type="submit" />
              </div>
            </form>
          </>
        }
      />
    </>
  );
};
export default Items;
