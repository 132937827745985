import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const PasswordEye = ({
  user,
  handleChange,
  name,
  placeholder,
  label,
  errors,
  PasswordChange
  // passwordChange
}) => {
  const [inputType, setInputType] = useState("password");
  const [capsOn, setCapsOn] = useState(false);

  const handleKeydown = e => {
    if (e.key === "Enter") {
      //   handleSubmit();
    }
    if (e.getModifierState("CapsLock")) {
      setCapsOn(true);
    } else {
      setCapsOn(false);
    }
  };

  const handleInputTypeChange = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };
  return (
    <div className="col" style={{ position: "relative" }}>
      <label className="floating">
        {/* <label className={passwordChange ? null : "floating"}> */}
        {label}
        <span>*</span>{" "}
      </label>
      <input
        type={inputType}
        className={PasswordChange ? "form-control" : null}
        name={name}
        placeholder={placeholder}
        value={user?.confirmPassword}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        disabled={user?.password ? false : true}
      />

      {user?.confirmPassword && errors?.isErrorCpwd ? (
        <small
          style={{
            color: "red",
            marginTop: "-20px",
            display: "block"
          }}
        >
          {errors?.validationMsg}
        </small>
      ) : null}
      {/* <span></span> */}
      <span>
        <FontAwesomeIcon
          icon={inputType === "password" ? faEye : faEyeSlash}
          style={{
            position: "absolute",
            right: "20px",
            top: "24px"
          }}
          onClick={handleInputTypeChange}
        />
      </span>

      {capsOn ? (
        <div>
          <strong>Caps Lock is On</strong>
        </div>
      ) : null}
    </div>
  );
};

export default PasswordEye;
