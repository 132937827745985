import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../assets/scss/modal.scss";

const CommonModal = props => {
  return (
    <div>
      <React.Fragment>
        <div className="container justify-content-center d-flex">
          <Modal
            isOpen={props.modal}
            toggle={props.toggleModal}
            // fullscreen={true}
            size={props?.size}
            aria-labelledby="contained-modal-title-vcenter"
            centered={true}
            scrollable
            className={props.className}
            contentClassName={props.contentClassName}
            keyboard={props.useKeyBoard === "no" ? false : true}
          >
            <ModalHeader toggle={props.toggleModal}>
              {props.modalHeader}
            </ModalHeader>
            <ModalBody
              style={{
                overflowX: "hidden",
                overflowY: "auto"
              }}
            >
              {props.modalBody}
            </ModalBody>
            {props.modalFooter ? (
              <ModalFooter>{props.modalFooter}</ModalFooter>
            ) : null}
          </Modal>
        </div>
      </React.Fragment>
    </div>
  );
};

export default CommonModal;
