import React, { useState } from "react";
import CommonModal from "../../components/common/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import DepartmentRoleView from "./DepartmentRoleView";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";
import { cloneDeep } from "lodash";

const DepartmentList = (props) => {
  const {
    department,
    handleView,
    isLoading,
    getDepartment,
    permission: permissions,
  } = props;
  const [modal, setModal] = useState(false);
  const [viewParam, setViewParam] = useState({
    isView: false,
    selectedDepartment: null,
  });

  const toggleModal = () => {
    const { isView } = viewParam;
    if (modal && isView) {
      setViewParam((prevState) => ({ ...prevState, isView: false }));
    }
    setModal(!modal);
  };

  const handleDepartmentView = (id) => {
    const selectedDep = department?.find((role) => role?.id === Number(id));
    if (!!selectedDep) {
      selectedDep.roles.forEach((r) => {
        r.allPermissions = cloneDeep(permissions);
        r.allPermissions.forEach((p) => {
          p.permissions.forEach((ps) => {
            ps.isChecked = calc(r, p, ps);
          });
        });
      });
    }
    setViewParam((prevState) => ({
      ...prevState,
      selectedDepartment: selectedDep,
    }));
    toggleModal();
  };

  const calc = (r, p, ps) => {
    const permission = r.permissions.find((per) => per.name === p.name);
    if (!!permission) {
      const p = permission.permissions.find((pers) => pers.id === ps.id);
      return !!p;
    } else {
      return false;
    }
  };

  const handleCheckBox = (roleIndex, moduleIndex, permissionIndex, e) => {
    let newSelectedDepartment = { ...viewParam.selectedDepartment };
    newSelectedDepartment.roles[roleIndex].allPermissions[
      moduleIndex
    ].permissions[permissionIndex].isChecked = e.target.checked;
    setViewParam((prevState) => ({
      ...prevState,
      selectedDepartment: newSelectedDepartment,
    }));
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="table-responsive whiteContainer p-4">
            <table className="table table-sm table-custom">
              <thead>
                <tr>
                  <th className="text-center">SN.</th>
                  <th>Name</th>
                  <th>Company Name</th>
                  <th className="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                {department && department.length > 0 ? (
                  department.map((dpt, id) => {
                    return (
                      <React.Fragment key={dpt.id}>
                        <tr>
                          <td className="text-center">{id + 1}</td>
                          <td>{dpt.name}</td>
                          <td>{dpt?.company.name}</td>
                          <td className="td-actions text-right">
                            <button
                              type="button"
                              rel="tooltip"
                              className="btn btn-outline-primary rounded-circle btn-sm me-2"
                              data-original-title=""
                              title="View"
                              onClick={() => handleDepartmentView(dpt?.id)}
                            >
                              <FontAwesomeIcon icon={faEye} />
                            </button>
                            {props.storePermission ? (
                              <button
                                type="button"
                                rel="tooltip"
                                className="btn btn-outline-info rounded-pill btn-sm m-0 me-2"
                                style={{ marginLeft: "0.75rem" }}
                                data-original-title=""
                                onClick={(e) => handleView(e, id)}
                                title="Add Roles"
                              >
                                Add Role
                              </button>
                            ) : null}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr className="text-center">
                    {isLoading ? (
                      <td colSpan={4}>
                        <LoadingAnimation spinner />
                      </td>
                    ) : (
                      <td colSpan={4}>No Department Found</td>
                    )}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        size="xl"
        modalHeader={viewParam?.selectedDepartment?.name + " Department"}
        modalBody={
          <div>
            <DepartmentRoleView
              toggleModal={toggleModal}
              viewParam={viewParam}
              getDepartment={getDepartment}
              permissions={permissions}
              handleCheckBox={handleCheckBox}
            />
          </div>
        }
      />
    </>
  );
};

export default DepartmentList;
