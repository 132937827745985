import React, { Component } from "react";
import swal from "sweetalert";
import { bytesToMegaBytes, URL } from "../../utils/Constants";
import Button from "../../components/common/Button";
import { axiosPost } from "../../utils/AxiosApi";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CustomFullSpinner from "../../components/common/FullPageSpinner";

class Contract extends Component {
  state = {
    contractFile: null,
    spinner: false,
  };

  handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    let data = files;
    if (data && data[0] && bytesToMegaBytes(data[0].size) > 10) {
      swal("Warning", "File size must be equal or less than 10 MB.", "warning");
      return;
    }
    if (data && data[0]) {
      this.setState({
        [name]: data[0],
      });
    }
  };

  handleSubmit = () => {
    if (this.state.contractFile) {
      let formData = new FormData();
      this.setState({ spinner: true }, function () {
        formData.append("file", this.state.contractFile);
        if (this.props.fromStaff) {
          formData.append("user_id", this.props.userId);
        }
        axiosPost(
          URL.storeContract,
          formData,
          (response) => {
            this.setState({ spinner: false });
            if (response.status === 200) {
              this.props.toggleContractModal();
              this.props.getMyContract();
            }
          },
          (err) => {
            this.setState({ spinner: false });
            displayErrorAlert(err);
          }
        );
      });
    } else {
      swal("Error", "Please select a file", "error");
    }
  };

  render() {
    return (
      <div className="container">
        {this.state.spinner ? (
          <CustomFullSpinner text="Please wait. Processing ..."></CustomFullSpinner>
        ) : null}
        <div className="row">
          <div className="col">
            <form id="msform" className="mb-0">
              <label className="floating">
                Contract File
                <span className="required">*</span>
              </label>
              <input
                type="file"
                name="contractFile"
                accept="image/*,.pdf"
                onChange={this.handleChange}
              />
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col text-end">
            <Button name="Upload" onClick={this.handleSubmit}></Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Contract;
