import React, { useState } from "react";
import { bytesToMegaBytes, fileUrl } from "../../../utils/Constants";
import swal from "sweetalert";
import Button from "../../../components/common/Button";
import CommonModal from "../../../components/common/Modal";
import CustomFileViewer from "../../../components/common/CustomFileViewer";

const Experience = (props) => {
  const [modal, setmodal] = useState({
    fileModal: false,
    fileToBeViewed: null,
  });

  const toggleModalFile = (e, exp) => {
    if (exp === undefined) {
      setmodal((prevState) => ({
        ...prevState,
        fileModal: false,
        fileToBeViewed: null,
      }));
    } else {
      setmodal((prevState) => ({
        ...prevState,
        fileModal: true,
        fileToBeViewed:
          exp?.media.length === 0 ? exp?.uploadedMedia : exp?.media,
      }));
    }
  };

  const {
    experience,
    setExperience,
    handleNext,
    handlePrevious,
    editParam,
    addProfile,
    editableDptStf,
    setEditableDptStf,
    userInfo,
    setUserInfo,
  } = props;

  const expDetReq = [
    {
      organisation_name: null,
      nature_of_organisation: null,
      organisation_location: null,
      start_date: null,
      end_date: null,
      job_title: null,
      job_level: null,
      duties: null,
    },
  ];

  const expToBeEdited =
    editableDptStf &&
    editableDptStf[editParam?.selectedDepartment]?.staff[
      editParam?.selectedIndex
    ]?.experience;

  const expForProfile = userInfo?.experience;

  const requiredExperience =
    editParam?.isEdit && !addProfile
      ? expToBeEdited.length === 0
        ? expDetReq
        : expToBeEdited
      : addProfile
      ? expForProfile.length === 0
        ? expDetReq
        : expForProfile
      : experience;

  const requiredExperienceSetterFunc =
    editParam?.isEdit && !addProfile
      ? setEditableDptStf
      : addProfile
      ? setUserInfo
      : setExperience;

  const staffDetails =
    editParam?.isEdit &&
    editableDptStf[editParam.selectedDepartment].staff[editParam.selectedIndex];

  const handleAddExp = (e) => {
    e.preventDefault();
    let cloneExp = [...requiredExperience];
    if (editParam?.isEdit || addProfile) {
      cloneExp.push({
        organisation_name: null,
        nature_of_organisation: null,
        organisation_location: null,
        start_date: null,
        end_date: null,
        job_title: null,
        job_level: null,
        duties: null,
        // media: null
      });
    } else {
      cloneExp.push({
        organisationName: null,
        natureOfOrganisation: null,
        organisationLocation: null,
        startDate: null,
        endDate: null,
        jobTitle: null,
        jobLevel: null,
        duties: null,
        experience_docs: null,
      });
    }
    if (editParam?.isEdit) {
      const cloneUserInfo = [...editableDptStf];
      cloneUserInfo[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].experience = cloneExp;
      setEditableDptStf(cloneUserInfo);
    } else {
      requiredExperienceSetterFunc(cloneExp, "exp");
    }
  };

  const handleRemoveCustomExpBtnClick = (e, index) => {
    let cloneExp = [...requiredExperience];
    cloneExp.splice(index, 1);
    if (editParam?.isEdit) {
      const cloneUserInfo = [...editableDptStf];
      cloneUserInfo[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].experience = cloneExp;
      setEditableDptStf(cloneUserInfo);
    } else {
      requiredExperienceSetterFunc(cloneExp, "exp");
    }
  };

  const handleCustomInputChange = (e, index, date, datename) => {
    let cloneExp = [...requiredExperience];
    const { name, value } = e.target;
    const fileName = ["experience_docs", "uploadedMedia"];
    if (!fileName.includes(name)) {
      cloneExp[index][name] = value;
    } else {
      let data = e.target.files;
      if (data && data[0] && bytesToMegaBytes(data[0].size) > 4) {
        swal(
          "warning",
          "File size must be equal or less than 4 MB.",
          "warning"
        );
        return;
      }
      data && data[0] && (cloneExp[index][name] = data[0]);
    }
    if (editParam?.isEdit) {
      const cloneUserInfo = [...editableDptStf];
      cloneUserInfo[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].experience = cloneExp;
      setEditableDptStf(cloneUserInfo);
    } else {
      requiredExperienceSetterFunc(cloneExp, "exp");
    }
  };

  return (
    <>
      <h2 className="fs-title">Experience Details</h2>
      <h3 className="fs-subtitle">All fields are required </h3>

      <div className="container border" style={{ paddingTop: "20px" }}>
        {requiredExperience && requiredExperience.length > 0
          ? requiredExperience.map((exp, id) => {
              return (
                <React.Fragment key={id}>
                  <div className="row">
                    {id != 0 ? (
                      <div className="col text-end">
                        <Button
                          name="Remove"
                          className="mb-3"
                          onClick={(e) => handleRemoveCustomExpBtnClick(e, id)}
                        ></Button>
                      </div>
                    ) : null}
                  </div>

                  <div className="row">
                    <div className="col">
                      <label className="floating">
                        Organisation Name
                        {staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>

                      <input
                        type="text"
                        name={
                          editParam?.isEdit || addProfile
                            ? "organisation_name"
                            : "organisationName"
                        }
                        placeholder="Organisation Name"
                        value={exp.organisationName ?? exp.organisation_name}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">
                        Nature of Organisation{" "}
                        {staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name={
                          editParam?.isEdit || addProfile
                            ? "nature_of_organisation"
                            : "natureOfOrganisation"
                        }
                        placeholder="Nature of Organisation"
                        value={
                          exp.natureOfOrganisation ?? exp.nature_of_organisation
                        }
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">
                        Organisation Location
                        {staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name={
                          editParam?.isEdit || addProfile
                            ? "organisation_location"
                            : "organisationLocation"
                        }
                        placeholder="Organisation Location"
                        value={
                          exp.organisationLocation ?? exp.organisation_location
                        }
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col text-start">
                      <label className="floating">
                        Start Date
                        {staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name={
                          editParam?.isEdit || addProfile
                            ? "start_date"
                            : "startDate"
                        }
                        value={exp.startDate ?? exp.start_date}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                    <div className="col text-start">
                      <label className="floating">
                        End Date
                        {staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name={
                          editParam?.isEdit || addProfile
                            ? "end_date"
                            : "endDate"
                        }
                        value={exp.endDate ?? exp.end_date}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <label className="floating">
                        Job Title
                        {staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name={
                          editParam?.isEdit || addProfile
                            ? "job_title"
                            : "jobTitle"
                        }
                        placeholder="Job Title"
                        value={exp.jobTitle ?? exp.job_title}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">
                        Job Level
                        {staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name={
                          editParam?.isEdit || addProfile
                            ? "job_level"
                            : "jobLevel"
                        }
                        placeholder="Job Level"
                        value={exp.jobLevel ?? exp.job_level}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">
                        Duties
                        {staffDetails?.need_to_be_filled === 1 ? (
                          <span className="required">*</span>
                        ) : null}
                      </label>
                      <input
                        type="text"
                        name="duties"
                        placeholder="Duties"
                        value={exp.duties}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <label className="floating">
                        File
                        <span>*</span>
                      </label>
                      <input
                        type="file"
                        name={
                          editParam?.isEdit || addProfile
                            ? "uploadedMedia"
                            : "experience_docs"
                        }
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>

                    {((exp?.media &&
                      (exp?.media?.length !== 0 ?? exp?.media !== "")) ||
                      (exp?.experience_docs && exp?.experience_docs !== "") ||
                      (exp?.uploadedMedia && exp?.uploadedMedia !== "")) && (
                      <div className="col-4 mt-4">
                        <Button
                          name="View Experience Doc file"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleModalFile(e, exp);
                          }}
                        ></Button>
                      </div>
                    )}
                  </div>
                  <hr />
                </React.Fragment>
              );
            })
          : null}
        <div className="row mb-3">
          <div className="col text-end">
            <Button name="Add Experience" onClick={handleAddExp}></Button>
          </div>
        </div>
      </div>

      <input
        type="button"
        name="previous"
        className="action-button-previous"
        value="Previous"
        onClick={handlePrevious}
      />
      <input
        type="button"
        name="next"
        className="next action-button"
        value="Next"
        onClick={(e) => handleNext(e, editParam)}
      />

      <CommonModal
        modal={modal.fileModal}
        modalHeader={"Experience Doc"}
        modalBody={
          modal?.fileModal && (
            <CustomFileViewer
              fileExtension={
                Array.isArray(modal?.fileToBeViewed)
                  ? modal?.fileToBeViewed[0]?.file_name?.split(".").pop()
                  : modal?.fileToBeViewed?.name?.split(".").pop()
              }
              filePath={
                Array.isArray(modal?.fileToBeViewed)
                  ? `${fileUrl}/${modal?.fileToBeViewed[0]?.id}/${modal?.fileToBeViewed[0]?.file_name}`
                  : modal?.fileToBeViewed &&
                    URL.createObjectURL(modal?.fileToBeViewed)
              }
            />
          )
        }
        size="xl"
        toggleModal={toggleModalFile}
      />
    </>
  );
};

export default Experience;
