import React, { useState, useEffect } from "react";
import Button from "../../components/common/Button";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosPost, axiosGet } from "../../utils/AxiosApi";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CommonModal from "../../components/common/Modal";
import ListLeaveCategory from "./ListLeaveCategory";
import { URL } from "../../utils/Constants";
import { checkModulePermission } from "../../utils/PermissionChecker";

const AddLeaveCategory = () => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [leaveCategory, setLeaveCategory] = useState(null);
  const [allLeaveCategory, setAllLeaveCategory] = useState(null);
  const [assignedPermission, setAssignedPermission] = useState(null);
  const [leaveDataToBeUpdated, setLeaveDataToBeUpdated] = useState(null);
  const [editParam, setEditParam] = useState({
    isEdit: false,
    leaveToBeUpdated: null,
  });

  const toggleModal = () => {
    if (modal && editParam?.isEdit) {
      setEditParam((prevState) => ({ ...prevState, isEdit: false }));
    }

    setModal(!modal);
  };

  useEffect(() => {
    getLeaveCategory();
    // required later after permission being added on the backend for leave module
    let value = checkModulePermission("leaveCategory");
    // if (value?.view) {
    //   getLeaveCategory();
    // }
    setAssignedPermission(value);
  }, []);

  const getLeaveCategory = () => {
    setIsLoading(true);
    axiosGet(
      URL.getLeaveCategory,
      (res) => {
        if (res.data.success) {
          let modules = res.data.data?.items;
          setIsLoading(false);
          setAllLeaveCategory(modules);
        }
      },
      (err) => {}
    );
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (editParam?.isEdit) {
      const cloneLeaveData = { ...editParam?.leaveToBeUpdated };
      cloneLeaveData[name] = value;
      setEditParam((prevState) => ({
        ...prevState,
        leaveToBeUpdated: cloneLeaveData,
      }));
    } else {
      setLeaveCategory((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isEdit, leaveToBeUpdated } = editParam;
    if (isEdit) {
      if (leaveToBeUpdated?.leave_type === "") {
        swal("Warning", "Leave type field is empty", "warning");
        return;
      }
    } else {
      if (!leaveCategory) {
        swal("Warning", "Leave type must be insterted", "warning");
        return;
      }
      if (leaveCategory && leaveCategory.leaveType === "") {
        swal("Warning", "Leave type field is empty", "warning");
        return;
      }
    }

    setSubmitSpinner(true);

    let params;
    if (editParam?.isEdit) {
      params = {
        leaveType: leaveToBeUpdated.leave_type,
        basis: leaveToBeUpdated.basis,
        numberOfDays: leaveToBeUpdated.number_of_days,
        id: editParam?.leaveToBeUpdated?.id,
      };
    } else {
      params = {
        leaveType: leaveCategory.leaveType,
        basis: leaveCategory.basis,
        numberOfDays: leaveCategory.leaveDays,
      };
    }

    let url =
      editParam && editParam?.isEdit
        ? URL.updateLeaveCategory
        : URL.insertLeaveCategory;

    axiosPost(
      url,
      params,
      (response) => {
        if (response.data.success) {
          setSubmitSpinner(false);

          let msg = editParam?.isEdit
            ? "Leave category updated successfully"
            : "Leave category inserted successfully";
          swal("Success", msg, "success");
          toggleModal();
          // if (isEdit) {
          // }
          getLeaveCategory();
          setLeaveCategory((prevState) => ({
            ...prevState,
            leaveType: "",
            basis: "",
            leaveDays: "",
          }));
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleDeleteLeaveCategory = (id) => {
    let params = { id };
    setSubmitSpinner(true);

    axiosPost(
      URL.deleteLeaveCategory,
      params,
      (response) => {
        if (response.data.success) {
          setSubmitSpinner(false);
          swal("Success", "Leave category deleted successfully", "success");
          getLeaveCategory();
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  const handleEditLeaveCategory = (id) => {
    const data = allLeaveCategory?.find((leave) => leave?.id === Number(id));
    setEditParam((prevState) => ({
      ...prevState,
      leaveToBeUpdated: data,
      isEdit: true,
    }));
    toggleModal();
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">Leave Category</div>
      </div>
      <div
        className="container-fluid m-0 p-0"
        style={{ padding: "1rem", height: "100%" }}
      >
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}

        {assignedPermission && assignedPermission?.store ? (
          <div className="">
            <Button name="Add Leave Category" onClick={toggleModal} />
          </div>
        ) : null}
        <ListLeaveCategory
          assignedPermission={assignedPermission}
          leaveCategory={allLeaveCategory}
          toggleModal={toggleModal}
          modal={modal}
          handleDelete={handleDeleteLeaveCategory}
          handleEdit={handleEditLeaveCategory}
          isLoading={isLoading}
        />
      </div>

      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        modalHeader={
          editParam?.isEdit
            ? "Edit Leave Category"
            : !editParam?.Edit && "Add Leave Category"
        }
        modalBody={
          <div className="container p-0 m-0">
            <form onSubmit={handleSubmit} id="msform">
              <div className="row">
                <div className="col text-start">
                  <label className="floating"> Leave Type:</label>
                  <input
                    type="text"
                    name={editParam?.isEdit ? "leave_type" : "leaveType"}
                    className="form-control"
                    onChange={handleChange}
                    value={
                      editParam?.isEdit
                        ? editParam?.leaveToBeUpdated?.leave_type
                        : leaveCategory?.leaveType
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="col text-center">
                  <label className="floating">Basis</label>
                  <select
                    className="formSelect"
                    name="basis"
                    onChange={handleChange}
                    value={
                      editParam?.isEdit
                        ? editParam?.leaveToBeUpdated?.basis
                        : leaveCategory
                        ? leaveCategory.basis
                        : ""
                    }
                  >
                    <option value="" disabled>
                      Choose Leave Basis
                    </option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col text-start">
                  <label className="floating">Days:</label>
                  <input
                    type="number"
                    name={editParam?.isEdit ? "number_of_days" : "leaveDays"}
                    className="form-control"
                    onChange={handleChange}
                    placeholder="Leave Days"
                    value={
                      editParam?.isEdit
                        ? editParam?.leaveToBeUpdated?.number_of_days
                        : leaveCategory?.leaveDays
                    }
                    min={0}
                    onKeyDown={preventMinus}
                    onPaste={preventPasteNegative}
                  />
                </div>
              </div>

              <div style={{ float: "right", marginTop: "0.5rem" }}>
                <Button
                  name={editParam?.isEdit ? "Update" : "Add"}
                  type="submit"
                />
              </div>
            </form>
          </div>
        }
      />
    </>
  );
};

export default AddLeaveCategory;
