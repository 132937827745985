import React, { useState, useEffect } from "react";
import Checkbox from "../../components/common/Checkbox";
import Button from "../../components/common/Button";
import { axiosPost } from "../../utils/AxiosApi";
import { URL, moduleName } from "../../utils/Constants";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import { checkRolePermissions } from "../../utils/PermissionChecker";

const DepartmentRoleView = ({
  viewParam,
  toggleModal,
  getDepartment,
  permissions,
  handleCheckBox,
}) => {
  const [isUpdate, setIsUpdate] = useState({ update: false, id: null });
  const [roleName, setRoleName] = useState(null);
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [storePermission, setStorePermission] = useState(false);
  const { selectedDepartment } = viewParam;

  useEffect(() => {
    let store = checkRolePermissions("update", moduleName.department);
    setStorePermission(store);
  }, []);

  const showAcquiredPermission = (cmp) => {
    return cmp.allPermissions?.map((m, idx) => {
      return (
        <tr>
          <td width="30%">{m?.name}</td>
          <td width="70%">
            {m?.permissions?.map((p, pIdx) => (
              <div className="d-flex flex-row mt-2">
                <Checkbox
                  type="checkbox"
                  checked={p?.isChecked}
                  className="mr-3"
                  disabled={true}
                />
                <span
                  className="form-check-label"
                  style={{ marginLeft: "10px" }}
                >
                  {p?.display_name}
                </span>
              </div>
            ))}
          </td>
        </tr>
      );
    });
  };

  const showUpdatePermission = (cmp, rIdx) => {
    return cmp.allPermissions?.map((m, pIdx) => {
      return (
        <tr>
          <td width="30%">{m?.name}</td>
          <td width="70%">
            {m?.permissions?.map((p, psIdx) => (
              <div className="d-flex flex-row mt-2">
                <Checkbox
                  type="checkbox"
                  handleChange={(e) => handleCheckBox(rIdx, pIdx, psIdx, e)}
                  checked={p?.isChecked}
                  className="mr-3"
                />
                <span
                  className="form-check-label"
                  style={{ marginLeft: "10px" }}
                >
                  {p?.display_name}
                </span>
              </div>
            ))}
          </td>
        </tr>
      );
    });
  };

  const handleUpdatePermission = (cmp) => {
    //find the module having permissions-check fpr has_permission flag
    if (roleName === "") {
      swal("Warning", "Role name is missing", "warning");
      return;
    }
    let params = {
      name:
        !roleName || roleName === ""
          ? cmp.name
          : `${selectedDepartment.name} ${roleName}`,
      role: cmp.id,
      permissions: [],
    };
    cmp.allPermissions.forEach((allP) => {
      allP.permissions.forEach((p) => {
        if (p.isChecked) {
          params.permissions.push(p.id);
        }
      });
    });

    setSubmitSpinner(true);
    axiosPost(
      URL.updateRoleandPermission,
      params,
      (response) => {
        if (response && response.status === 200) {
          toggleModal();
          setSubmitSpinner(false);
          getDepartment();
          swal(
            "Success",
            "Role and permissions updated successfully",
            "success"
          );
        }
      },
      (err) => {
        setSubmitSpinner(false);
        swal(err.response.statusText, err.response.data.message);
        return;
      }
    );
  };

  return (
    <div className="container-fluid m-0 p-0" style={{ height: "100%" }}>
      {submitSpinner ? (
        <div className="fullWindow-Spinner">
          <div>
            <Spinner color="white"></Spinner>
          </div>
          <div style={{ fontSize: "16px", marginTop: "15px" }}>
            Please Wait. Submitting ...
          </div>
        </div>
      ) : null}

      {selectedDepartment?.roles.length > 0
        ? selectedDepartment?.roles.map((cmp, rIdx) => {
            return (
              <React.Fragment key={rIdx}>
                <table className="table table-sm table-custom">
                  <thead>
                    <tr>
                      <th colSpan={5}>
                        {!isUpdate.update
                          ? // ? cmp?.permissions?.permissions?.display_name
                            cmp?.name
                          : Number(cmp?.id) === Number(isUpdate.id) && (
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon3"
                                  >
                                    {selectedDepartment?.name}
                                  </span>
                                </div>
                                <input
                                  placeholder="Role name"
                                  className="form-control mb-1"
                                  name="roleName"
                                  id="basic-url"
                                  aria-describedby="basic-addon3"
                                  value={
                                    roleName ??
                                    cmp?.name
                                      .replace(selectedDepartment?.name, "")
                                      .trim()
                                  }
                                  onChange={(e) => setRoleName(e.target.value)}
                                />
                              </div>
                            )}
                      </th>
                      <th className="text-end">
                        {cmp?.name ===
                        "Company Admin1" ? null : !isUpdate?.update ? (
                          storePermission ? (
                            <Button
                              className="m-auto"
                              name="Update"
                              onClick={(e) => {
                                setIsUpdate((prevState) => ({
                                  ...prevState,
                                  update: !isUpdate.update,
                                  id: cmp?.id,
                                }));
                                // getRolePermisson(cmp.id);
                              }}
                            />
                          ) : null
                        ) : (
                          Number(cmp?.id) === Number(isUpdate.id) && (
                            <Button
                              className="m-auto"
                              name="Cancel"
                              onClick={(e) => {
                                setIsUpdate((prevState) => ({
                                  ...prevState,
                                  update: !isUpdate.update,
                                  id: cmp?.id,
                                }));
                              }}
                            />
                          )
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isUpdate.update
                      ? Number(cmp?.id) === Number(isUpdate.id) &&
                        showUpdatePermission(cmp, rIdx)
                      : showAcquiredPermission(cmp)}
                  </tbody>
                </table>
                {isUpdate?.update &&
                  Number(cmp?.id) === Number(isUpdate.id) && (
                    <div className="text-end">
                      <Button
                        className="mt-1"
                        name="update"
                        onClick={() => handleUpdatePermission(cmp)}
                      />
                    </div>
                  )}
              </React.Fragment>
            );
          })
        : "No Department Roles"}
    </div>
  );
};

export default DepartmentRoleView;
