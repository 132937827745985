import React, { useState } from "react";
import swal from "sweetalert";
import loginIcon from "../../assets/images/loginIconWhite.png";
import Button from "../../components/common/Button";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import FullPageSpinner from "../../components/common/FullPageSpinner";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email !== "") {
      let data = {
        email: email,
      };
      setLoader(true);

      axiosPost(URL.forgotPasswordURL, data, (response) => {
        if (response.status === 200) {
          swal("Success", response.data.message);
          setLoader(false);
          setEmail("");
        }
      },
        (error) => {
          if (error.response) {
            setLoader(false);
            if (error.response.status === 400) {
              swal("Error", error.response.data.message, "error");
            } else {
              swal("Error", error.response.data.message, "error");
            }
          } else {
            swal("Error", error.message);
          }
        });
    } else {
      swal("Error", "Please enter email first", "error");
    }
  };

  return (
    <div>
      <div className="container-fluid p-0 m-0">
        {loader ? (
          <FullPageSpinner text=" Password reset link is being forwarded to your email ..." />
        ) : null}

        <div className="justify-content-center d-flex loginBox">
          <div className="row main-content text-center  ">
            <div className="col-md-4 text-center company__info">
              <span className="company__logo">
                <img src={loginIcon} />
              </span>
            </div>
            <div className="col-md-8 col-xs-12 col-sm-12 login_form ">
              <div className="loginFormHolder">
                <div className="container-fluid">
                  <div className="row">
                    <h2 className="sectionTitle">Forgot Password</h2>
                  </div>
                  <div className="row">
                    <form
                      className="form-group"
                      onSubmit={(e) => handleSubmit(e)}
                    >
                      <div className="row">
                        <input
                          type="email"
                          name="email"
                          value={email}
                          className="form__input"
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                        // onKeyDown={handleKeydown}
                        />
                      </div>

                      <div className="row">
                        <Button type="submit" name="Submit" />
                      </div>
                    </form>
                  </div>
                  <div className="gobacktologin mt-2 text-end">
                    <a href="/login" className="text-blue">
                      Go Back to Login
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
