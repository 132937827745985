import React, { useState } from "react";
import BranchUser from "./BranchUser";
import BranchDetails from "./BranchDetails";
import swal from "sweetalert";
import {
  checkIfEmpty,
  emailValidate,
  CheckIsValidDomain,
  capitalizeFirstLetter,
  checkIsValidPassword,
} from "../../../utils/Validation";
import { displayErrorAlert } from "../../../utils/DisplayMessage";
import { Spinner } from "reactstrap";
import { axiosPost } from "../../../utils/AxiosApi";
import { URL } from "../../../utils/Constants";

const BranchForm = ({ toggleModal }) => {
  const [step, setStep] = useState(1);
  const [branch, setBranch] = useState(null);
  const [branchUser, setBranchUser] = useState(null);
  const [submitSpinner, setSubmitSpinner] = useState(false);

  const handlePrevious = () => {
    setStep((prevState) => prevState - 1);
  };
  const handleNext = () => {
    if (step === 1) {
      if (branch === null) {
        swal("Warning", "Field with asterisk(*) are mandatory", "warning");
        return;
      }
      //need to be changed -validated according to the api provided
      if (Object.keys(branch).length < 5) {
        swal("Warning", "Field with asterisk(*) are mandatory", "warning");
        return;
      }

      if (branch.email !== null || branch.email !== "") {
        if (!emailValidate(branch.email)) {
          swal("Warning", "Invalid email ", "warning");
          return;
        }
      }

      let reqBranch;
      if (!branch?.isDomain) {
        if (branch.domainName !== null || branch.domainName !== "") {
          if (!CheckIsValidDomain(branch.domainName)) {
            swal("Warning", "Invalid domain name", "warning");
            return;
          }
        }
        const { isDomain, ...finalBranchData } = branch;
        reqBranch = finalBranchData;
      } else {
        const { isDomain, domainName, ...finalBranchData } = branch;
        reqBranch = finalBranchData;
      }

      let reqValue = checkIfEmpty(reqBranch);

      if (reqValue?.isEmpty) {
        let msg = capitalizeFirstLetter(reqValue?.emptyKey);
        swal("Warning", `${msg} field missing`, "warning");
      } else {
        setStep((prevState) => prevState + 1);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (branchUser === null) {
      swal("Warning", "Field with asterisk(*) are mandatory", "warning");
      return;
    }

    const { email, password } = branchUser;

    if (email !== "" || email !== null) {
      if (!emailValidate(email)) {
        swal("Warning", "Invalid email ", "warning");
        return;
      }
    }

    if (password !== null && password !== "") {
      if (password.length < 8) {
        swal("Warning", "Password must be 8 characters long", "warning");
        return;
      }

      if (!checkIsValidPassword(password)) {
        swal(
          "Warning",
          "Password must contain Special characters, Numbers, Uppercase and Lowercase letter",
          "warning"
        );
        return;
      }
    }
    const { middleName, ...finalBranchUser } = branchUser;
    let reqValue = checkIfEmpty(finalBranchUser);
    if (reqValue?.isEmpty) {
      let msg = capitalizeFirstLetter(reqValue?.emptyKey);
      swal("Warning", `${msg} field missing`, "warning");
      return;
    }

    const params = {
      ...branch,
      user: branchUser,
    };
    setSubmitSpinner(true);
    axiosPost(
      URL.insertBranch,
      params,
      (res) => {
        if (res.data.success) {
          setSubmitSpinner(false);
          toggleModal();
          swal("Success", "Company branch created successfully", "success");
          // getCompany();
        } else {
          setSubmitSpinner(false);
          displayErrorAlert(res);
        }
      },
      (err) => {
        setSubmitSpinner(false);
        displayErrorAlert(err);
      }
    );
  };

  const getComponent = () => {
    switch (step) {
      case 1:
        return (
          <BranchDetails
            handleNext={handleNext}
            branch={branch}
            setBranch={setBranch}
          />
        );

      case 2:
        return (
          <BranchUser
            handlePrevious={handlePrevious}
            handleSubmit={handleSubmit}
            branchUser={branchUser}
            setBranchUser={setBranchUser}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className="container" style={{ padding: "1rem", height: "100vh" }}>
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-md-offset-3">
              <form id="msform">
                <ul id="progressbar" className="ps-4 pe-4 ">
                  <li
                    className={`${step === 1 ? "active" : ""}`}
                    // onClick={() => {
                    //   setStep(1);
                    // }}
                  >
                    Branch Details
                  </li>
                  <li
                    className={`${step === 2 ? "active" : null}`}
                    // onClick={() => {
                    //   setStep(2);
                    // }}
                  >
                    User
                  </li>
                </ul>
                {/* /* Switch the components as rerquired  */}
                <fieldset>{getComponent()}</fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchForm;
