import React from "react";
import Button from "../../../components/common/Button";

const Skill = (props) => {
  const {
    skills,
    setSkills,
    handleNext,
    handlePrevious,
    editParam,
    addProfile,
    editableDptStf,
    setEditableDptStf,
    userInfo,
    setUserInfo,
  } = props;

  const sklDetReq = [
    {
      skill_name: null,
      skill_level: null,
      experience_duration: null,
      description: null,
    },
  ];

  const sklToBeEdited =
    editableDptStf &&
    editableDptStf[editParam.selectedDepartment]?.staff[editParam.selectedIndex]
      ?.skill;
  const sklForProfile = userInfo?.skill;

  const requiredSkill =
    editParam?.isEdit && !addProfile
      ? sklToBeEdited.length === 0
        ? sklDetReq
        : sklToBeEdited
      : addProfile
      ? sklForProfile.length === 0
        ? sklDetReq
        : sklForProfile
      : skills;

  const reqSkillSetterFunc = editParam?.isEdit
    ? setEditableDptStf
    : addProfile
    ? setUserInfo
    : setSkills;

  const handleAddSkill = (e) => {
    e.preventDefault();
    let cloneSkl = [...requiredSkill];
    if (editParam?.isEdit || addProfile) {
      cloneSkl.push({
        skill_name: null,
        skill_level: null,
        experience_duration: null,
        description: null,
      });
    } else {
      cloneSkl.push({
        skillName: null,
        skillLevel: null,
        experienceDuration: null,
        description: null,
      });
    }
    if (editParam?.isEdit) {
      const cloneUserInfo = [...editableDptStf];
      cloneUserInfo[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].skill = cloneSkl;
      setEditableDptStf(cloneUserInfo);
    } else {
      reqSkillSetterFunc(cloneSkl, "skl");
    }
  };
  const handleRemoveCustomSklBtnClick = (e, index) => {
    let cloneSkl = [...requiredSkill];
    cloneSkl.splice(index, 1);
    if (editParam?.isEdit) {
      const cloneUserInfo = [...editableDptStf];
      cloneUserInfo[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].skill = cloneSkl;
      setEditableDptStf(cloneUserInfo);
    } else {
      reqSkillSetterFunc(cloneSkl, "skl");
    }
  };

  const handleCustomInputChange = (e, index) => {
    const { name, value } = e.target;
    let cloneSkl = [...requiredSkill];
    cloneSkl[index][name] = value;
    if (editParam?.isEdit) {
      const cloneUserInfo = [...editableDptStf];
      cloneUserInfo[editParam.selectedDepartment].staff[
        editParam.selectedIndex
      ].skill = cloneSkl;
      setEditableDptStf(cloneUserInfo);
    } else {
      reqSkillSetterFunc(cloneSkl, "skl");
    }
  };

  return (
    <>
      <h2 className="fs-title">Skills Details</h2>
      <h3 className="fs-subtitle">All fields are required </h3>

      <div className="container border" style={{ paddingTop: "20px" }}>
        {requiredSkill && requiredSkill.length > 0
          ? requiredSkill.map((skl, id) => {
              return (
                <React.Fragment key={id}>
                  <div className="row">
                    {id != 0 ? (
                      <div className="col text-end">
                        <Button
                          name="Remove"
                          className="mb-3"
                          onClick={(e) => handleRemoveCustomSklBtnClick(e, id)}
                        ></Button>
                        {/* <FontAwesomeIcon
                          icon={faMinusCircle}
                          className="removeBtn"
                          style={{
                            color: "red",
                            cursor: "pointer"
                          }}
                          id="delete"
                          onClick={e => handleRemoveCustomSklBtnClick(e, id)}
                        /> */}
                      </div>
                    ) : null}
                  </div>

                  <div className="row">
                    <div className="col">
                      <label className="floating">Skill Name</label>
                      <input
                        type="text"
                        name={
                          editParam?.isEdit || addProfile
                            ? "skill_name"
                            : "skillName"
                        }
                        placeholder="Skill Name"
                        onChange={(e) => handleCustomInputChange(e, id)}
                        value={skl.skillName ?? skl.skill_name}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">Skill Level</label>
                      <input
                        type="text"
                        name={
                          editParam?.isEdit || addProfile
                            ? "skill_level"
                            : "skillLevel"
                        }
                        placeholder="Skill Level"
                        value={skl.skillLevel ?? skl.skill_level}
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <label className="floating">Experience Duration</label>
                      <input
                        type="text"
                        name={
                          editParam?.isEdit || addProfile
                            ? "experience_duration"
                            : "experienceDuration"
                        }
                        value={
                          skl.experienceDuration ?? skl.experience_duration
                        }
                        placeholder="Experience Duration"
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                    <div className="col">
                      <label className="floating">Description</label>
                      <input
                        type="text"
                        name="description"
                        value={skl.description}
                        placeholder="Description"
                        onChange={(e) => handleCustomInputChange(e, id)}
                      />
                    </div>
                  </div>

                  <hr />
                </React.Fragment>
              );
            })
          : null}
        <div className="row mb-3">
          <div className="col text-end">
            <Button name="Add Skill" onClick={handleAddSkill}></Button>
          </div>
        </div>
      </div>

      {/* <input
        type="button"
        name="Add"
        className="next action-button"
        value="Add"
        onClick={handleAddSkill}
      /> */}

      <input
        type="button"
        name="previous"
        className="action-button-previous"
        value="Previous"
        onClick={handlePrevious}
      />

      <input
        type="button"
        name="next"
        className="next action-button"
        value="Next"
        onClick={(e) => handleNext(e, editParam)}
      />
    </>
  );
};

export default Skill;
