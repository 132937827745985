import moment from "moment";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import CustomFullSpinner from "../../components/common/FullPageSpinner";

class ViewRequestedOvertime extends Component {
  state = {
    loader: false,
    overtimes: [],
    submitSpinner: false,
  };

  componentDidMount() {
    this.getRequestedOvertime();
  }

  getRequestedOvertime = () => {
    this.setState({ loader: true }, function () {
      axiosGet(
        URL.getRequestedOvertime,
        (res) => {
          if (res.status === 200) {
            this.setState({
              loader: false,
              overtimes: res.data,
            });
          }
        },
        (err) => {
          displayErrorAlert(err);
        }
      );
    });
  };

  handleApprove = (e, id) => {
    this.setState({ submitSpinner: true }, function () {
      let params = { id, approval_status: "Accepted" };
      axiosPost(
        URL.updateOverTimeCard,
        params,
        (response) => {
          if (response.status === 200) {
            swal("Success", "Overtime approved successfully", "success");
            this.getRequestedOvertime();
            this.setState({ submitSpinner: false });
          }
        },
        (err) => {
          this.setState({ submitSpinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleReject = (e, id) => {
    this.setState({ submitSpinner: true }, function () {
      let params = { id, approval_status: "Rejected" };
      axiosPost(
        URL.updateOverTimeCard,
        params,
        (response) => {
          if (response.status === 200) {
            swal("Success", "Gate Pass rejected succesfully", "success");
            this.getRequestedOvertime();
            this.setState({ submitSpinner: false });
          }
        },
        (err) => {
          this.setState({ submitSpinner: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  render() {
    return (
      <>
        <div className="mt-2">
          <div className="pageTitle">View Requested Overtime Cards </div>
          {this.state.submitSpinner ? (
            <CustomFullSpinner text="Please wait. Processing ..."></CustomFullSpinner>
          ) : null}
          <div className="row justify-content-center d-flex text-center">
            {this.state.overtimes.length > 0 ? (
              <div className="row">
                <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                  <div className="table-responsive whiteContainer p-4">
                    <table className="table table-sm table-custom">
                      <thead>
                        <tr>
                          <th>Employee Name</th>
                          <th>Time From</th>
                          <th>Time To</th>
                          <th>Duration (in Hours)</th>
                          <th>Description</th>
                          {/* <th>Status</th> */}
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.overtimes.map((oT, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                {oT.user.first_name} {oT.user.last_name}
                              </td>
                              <td>
                                {moment(oT.time_from, "HH:mm:ss").format(
                                  "hh:mm A"
                                )}
                              </td>
                              <td>
                                {moment(oT.time_to, "HH:mm:ss").format(
                                  "hh:mm A"
                                )}
                              </td>
                              <td>
                                {`0${(oT.duration / 60) ^ 0}`.slice(-2) +
                                  ":" +
                                  ("0" + (oT.duration % 60)).slice(-2)}
                              </td>
                              <td>{oT.ot_work_desc}</td>
                              <td>
                                {oT.approval_status === "Accepted" ? null : (
                                  <button
                                    type="button"
                                    rel="tooltip"
                                    className="btn btn-outline-info rounded-circle btn-sm me-2"
                                    data-original-title=""
                                    title="Approve"
                                    onClick={(e) =>
                                      this.handleApprove(e, oT.id)
                                    }
                                  >
                                    <i className="material-symbols-outlined">
                                      done_all
                                    </i>
                                  </button>
                                )}

                                {oT.approval_status === "Rejected" ? null : (
                                  <button
                                    type="button"
                                    rel="tooltip"
                                    className="btn btn-outline-danger rounded-circle btn-sm me-2"
                                    data-original-title=""
                                    title="Reject"
                                    onClick={(e) => this.handleReject(e, oT.id)}
                                  >
                                    <i className="material-symbols-outlined">
                                      remove_done
                                    </i>
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : this.state.loader ? (
              <div className="text-center">
                <Spinner></Spinner>
              </div>
            ) : (
              <div className="newTextColor text-center">
                No Pending Overtime Cards
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ViewRequestedOvertime;
