import React, { useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { URL, fileUrl } from "../../utils/Constants";
import CommonModal from "../../components/common/Modal";
import Button from "../../components/common/Button";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";

const VendorList = props => {
  const { VendorData, getAllVendorList, setIsEdit, toggleModal } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggleDeleteModal = () => setIsOpen(!isOpen);
  const [deleteId, setDeleteId] = useState();

  const handleDelete = id => {
    const params = {
      id: id
    };
    axiosPost(URL.deleteVendor, params, response => {
      if (response.status === 200) {
        swal("Data delete successfully");
        toggleDeleteModal();
        getAllVendorList();
      }
    });
  };

  return (
    <>
      <div className="table-responsive whiteContainer p-4">
        <table className="table table-sm table-custom">
          <thead>
            <tr>
              <th>SN.</th>
              <th>Logo</th>
              <th>Name</th>
              <th>Contact Number</th>
              <th>Contact Person</th>
              <th>Nature of Business</th>
              <th>Pan / Vat Number</th>
              <th>Payment Terms</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {VendorData && VendorData.length > 0 ? (
              VendorData?.map((items, id) => {
                return (
                  <>
                    <tr key={id}>
                      <td>{id + 1}</td>
                      <td>
                        <img
                          src={`${fileUrl}/${items?.media[0]?.id}/${items?.media[0]?.file_name}`}
                          alt="logo"
                          height="60px"
                          width="60px"
                        />
                      </td>

                      <td>{items?.name}</td>
                      <td>{items?.contact_number}</td>
                      <td>{items?.contact_person}</td>
                      <td>{items?.nature_of_business}</td>
                      <td>{items?.pan_vat_no}</td>
                      <td>{items?.payment_terms}</td>
                      <td>
                        <button
                          type="button"
                          rel="tooltip"
                          className="btn btn-danger btn-just-icon btn-sm"
                          style={{ marginLeft: "0.75rem" }}
                          data-original-title=""
                          title="delete"
                          onClick={e => {
                            toggleDeleteModal();
                            setDeleteId(items.id);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                        <button
                          type="button"
                          rel="tooltip"
                          className="btn btn-secondary btn-just-icon btn-sm"
                          style={{ marginLeft: "0.75rem" }}
                          data-original-title=""
                          title="update"
                          onClick={e => {
                            setIsEdit(true);
                            toggleModal(items);
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <span>No Data Found</span>
            )}
          </tbody>
        </table>
      </div>
      <CommonModal
        modal={isOpen}
        toggleModal={toggleDeleteModal}
        size={"md"}
        modalHeader={"Delete"}
        modalBody={
          <>
            Are you sure want to delete ?
            <div className="d-flex align-items-center mt-2 ">
              <Button name="Delete" onClick={e => handleDelete(deleteId)} />
              <Button name="Cancel" onClick={toggleDeleteModal} />
            </div>
          </>
        }
      />
    </>
  );
};
export default VendorList;
