export const ViewLeaveDetails = ({ selectedLeaveToView }) => {
  return (
    // <div className="container mt-3">
    <div className="container p-0 m-0">
      <div className="row m-0">
        {selectedLeaveToView ? (
          <>
            <div className="col-md ps-md-0">
              <div className="table-responsive whiteContainer p-1 mt-0">
                <table className="table table-sm table-custom">
                  <thead>
                    <tr>
                      <td>Leave Details</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Status</strong>
                      </td>
                      <td>:</td>
                      <td>{selectedLeaveToView.status}</td>
                    </tr>
                    {selectedLeaveToView.user ? (
                      <tr>
                        <td>
                          <strong>Requested By</strong>
                        </td>
                        <td>:</td>
                        <td>
                          {`${selectedLeaveToView.user.first_name} ${
                            selectedLeaveToView.user.middle_name !== null
                              ? selectedLeaveToView.user.middle_name
                              : ""
                          } ${selectedLeaveToView.user.last_name}`}
                        </td>
                      </tr>
                    ) : null}

                    <tr>
                      <td>
                        <strong>Subject</strong>
                      </td>
                      <td>:</td>
                      <td>{selectedLeaveToView.subject}</td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Duration</strong>
                      </td>
                      <td>:</td>
                      <td>
                        {selectedLeaveToView.duration}{" "}
                        {Number(selectedLeaveToView.duration) === 1
                          ? "day"
                          : "days"}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Leave From</strong>
                      </td>
                      <td>:</td>
                      <td>
                        {selectedLeaveToView.from_date}{" "}
                        {selectedLeaveToView.from_half_leave === 1 ? (
                          <strong>[ Half Day ]</strong>
                        ) : null}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Leave To</strong>
                      </td>
                      <td>:</td>
                      <td>
                        {selectedLeaveToView.to_date}{" "}
                        {selectedLeaveToView.to_half_leave === 1 ? (
                          <strong>[ Half Day ]</strong>
                        ) : null}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Reason</strong>
                      </td>
                      <td>:</td>
                      <td>{selectedLeaveToView.reason}</td>
                    </tr>

                    {selectedLeaveToView?.additional_description && (
                      <tr>
                        <td>
                          <strong>Additional Details</strong>
                        </td>
                        <td>:</td>
                        <td>{selectedLeaveToView.additional_description}</td>
                      </tr>
                    )}

                    {selectedLeaveToView?.remarks && (
                      <tr>
                        <td>
                          <strong>Remarks</strong>
                        </td>
                        <td>:</td>
                        <td>{selectedLeaveToView.remarks}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
