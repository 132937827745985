import React from "react";
// import { CheckIsValidDomain } from "../../utils/Validation";
// import swal from "sweetalert";

const BranchDetails = (prop) => {
  const { branch, setBranch, handleNext } = prop;
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (type === "checkbox" && name === "isDomain" && checked) {
      setBranch((prevState) => ({ ...prevState, domainName: "" }));
    }
    setBranch((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  return (
    <>
      <h2 className="fs-title">Branch Details</h2>
      <h3 className="fs-subtitle">Fill the required field</h3>
      <div className="row">
        <div className="col">
          <label className="floating">
            Branch Name<span className="required">*</span>{" "}
          </label>
          <input
            type="text"
            name="name"
            placeholder="Branch Name"
            value={branch?.name}
            onChange={handleChange}
          />
        </div>
        <div className="col">
          <label className="floating">
            Contact Number<span className="required">*</span>{" "}
          </label>
          <input
            type="number"
            name="contactNumber"
            placeholder="Contact Number"
            // value={company?.contactNumber}
            // onChange={handleChange}
          />
        </div>

        <div className="col">
          <label className="floating">
            Address<span className="required">*</span>{" "}
          </label>
          <input
            type="text"
            name="address"
            placeholder="Address"
            value={branch?.address}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label className="floating">
            EmailAddress<span className="required">*</span>{" "}
          </label>
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={branch?.email}
            onChange={handleChange}
          />
        </div>
        <div className="col">
          <label className="floating">
            Website<span className="required">*</span>{" "}
          </label>
          <input
            type="text"
            name="domainName"
            placeholder="Website Name"
            disabled={branch?.isDomain ? true : false}
            value={branch?.isDomain ? "" : branch?.domainName}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <label>Don't have website </label>
        </div>
        <div className="col-4">
          <input
            type="checkbox"
            name="isDomain"
            // checked={
            //   isEdit && isEdit
            //     ? companyData[selectedIndex].is_domain
            //     : company?.isDomain
            // }
            checked={branch?.isDomain}
            onChange={handleChange}
          />
        </div>
        <div className="col"></div>
      </div>

      <input
        type="button"
        name="next"
        className="next action-button"
        value="Next"
        onClick={handleNext}
      />
    </>
  );
};

export default BranchDetails;
