import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Collapse, CardBody, Card, CardHeader, Spinner } from "reactstrap";
import CommonModal from "../../components/common/Modal";
import Button from "../../components/common/Button";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import { URL, cryptoSecretKey } from "../../utils/Constants";
import swal from "sweetalert";
import { axiosPost, axiosGet } from "../../utils/AxiosApi";
import { checkModulePermission } from "../../utils/PermissionChecker";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import CryptoJS from "crypto-js";
import Pagination from "react-js-pagination";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

const FAQ = () => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [modal, setModal] = useState(false);
  const [faqData, setFaqData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [assignedPermission, setAssignedPermission] = useState(null);
  const [isUpdate, setIsUpdate] = useState(null);

  const role = localStorage.getItem("roles");
  const userRole = ["Developer Admin", "Super Admin"];
  const roles = JSON.parse(
    CryptoJS.AES.decrypt(role, cryptoSecretKey).toString(CryptoJS.enc.Utf8)
  );
  const [isDeveloperAdmin, setIsDeveloperAdmin] = useState(false);

  useEffect(() => {
    fetchFaq();
    let value = checkModulePermission("faq");
    setAssignedPermission(value);
    setIsDeveloperAdmin(roles.includes("Developer Admin"));
  }, []);

  const toggleModal = () => {
    setModal(!modal);
    setIsUpdate(null);
  };

  const fetchFaq = (pageNumber = 1) => {
    setIsFetching(true);
    setFaqData(null);
    let url = userRole.includes(roles[0]) ? URL.devFAQ : URL.FAQ;
    axiosGet(
      url + "?" + "page=" + pageNumber,
      (res) => {
        if (res.status === 200) {
          setIsFetching(false);
          let modules = res.data;
          setFaqData(modules);
        }
      },
      (err) => {
        setIsFetching(false);
        displayErrorAlert(err);
      }
    );
  };

  const FaqForm = () => {
    const [faq, setFaq] = useState(null);
    const [FAQToUpdate, setFAQToUpdate] = useState(isUpdate);
    const handleChange = (e) => {
      const { name, value } = e.target;
      if (FAQToUpdate?.faqToBeUpdated) {
        const cloneUpdatedFaq = { ...FAQToUpdate?.faqToBeUpdated };
        cloneUpdatedFaq[name] = value;
        setFAQToUpdate({ faqToBeUpdated: cloneUpdatedFaq });
      } else {
        setFaq((prev) => ({ ...prev, [name]: value }));
      }
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      setSubmitSpinner(true);

      let params = FAQToUpdate?.faqToBeUpdated
        ? {
            id: FAQToUpdate.faqToBeUpdated.id,
            answer: FAQToUpdate.faqToBeUpdated.answer,
            question: FAQToUpdate.faqToBeUpdated.question,
          }
        : faq;

      let url = FAQToUpdate?.faqToBeUpdated ? URL.updateFAQ : URL.FAQ;

      axiosPost(
        url,
        params,
        (response) => {
          if (response.status === 200) {
            toggleModal();
            let msg = FAQToUpdate?.faqToBeUpdated
              ? "FAQ updated Successfully"
              : "FAQ inserted successfully";
            swal("Success", msg, "success");
            setSubmitSpinner(false);
            fetchFaq();
            if (!FAQToUpdate?.faqToBeUpdated) {
              setFaq({
                answer: "",
                question: "",
              });
            } else {
              setIsUpdate(null);
            }
          }
        },
        (err) => {
          setSubmitSpinner(false);
          displayErrorAlert(err);
        }
      );
    };

    return (
      <>
        <form onSubmit={handleSubmit} id="msform">
          <div className="row">
            <div className="col text-start">
              <label className="floating">Question</label>
              <input
                required
                type="text"
                name="question"
                className="form-control"
                onChange={handleChange}
                value={FAQToUpdate?.faqToBeUpdated?.question ?? faq?.question}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col editor">
              <CKEditor
                data={FAQToUpdate?.faqToBeUpdated?.answer ?? faq?.answer}
                editor={ClassicEditor}
                name="answer"
                onChange={(event, editor) => {
                  let data = editor.getData();
                  if (FAQToUpdate?.faqToBeUpdated) {
                    const cloneUpdatedFaq = { ...FAQToUpdate?.faqToBeUpdated };
                    cloneUpdatedFaq.answer = data;
                    setFAQToUpdate({ faqToBeUpdated: cloneUpdatedFaq });
                  } else {
                    setFaq((prevState) => ({
                      ...prevState,
                      answer: data,
                    }));
                  }
                }}
              />
            </div>
          </div>

          <div style={{ float: "right", marginTop: "0.5rem" }}>
            <Button
              name={FAQToUpdate?.faqToBeUpdated ? "Update" : "Submit"}
              type="submit"
            />
          </div>
        </form>
      </>
    );
  };

  const ListFAQs = () => {
    const [toogleFaq, setToogleFaq] = useState(0);
    const handleUpdateFAQ = (e, faq) => {
      toggleModal();
      setIsUpdate((prev) => ({ ...prev, faqToBeUpdated: faq }));
    };
    const handleDeleteFAQ = (e, id) => {
      swal({
        text: "Are you sure, you want to delete this FAQ from the system? ",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSubmitSpinner(true);
          axiosPost(
            URL.deleteFAQ,
            { id },
            (response) => {
              if (response.data.success) {
                swal("Success", "FAQ deleted successfully", "success");
                setSubmitSpinner(false);
                fetchFaq();
              }
            },
            (err) => {
              displayErrorAlert(err);
              setSubmitSpinner(false);
            }
          );
        }
      });
    };

    if (faqData) {
      var { current_page, per_page, total } = faqData;
    }

    return (
      <div className="whiteContainer p-4 mt-3">
        <div>
          {faqData?.data?.length > 0 ? (
            faqData?.data?.map((fq, id) => {
              return (
                <Card className="faqCard" key={id}>
                  <CardHeader
                    onClick={(e) => setToogleFaq(id)}
                    className="faqTitle"
                  >
                    <div>
                      <span className="font-weight-bold bg-green">
                        {fq.question}
                      </span>
                    </div>
                    <div className="flexCenter">
                      {assignedPermission?.delete && (
                        <span className="me-2 table-custom">
                          <button
                            type="button"
                            rel="tooltip"
                            className="btn btn-outline-danger rounded-circle btn-sm me-2"
                            data-original-title=""
                            title="Delete"
                            onClick={(e) => handleDeleteFAQ(e, fq.id)}
                          >
                            <i className="material-symbols-outlined">delete</i>
                          </button>
                        </span>
                      )}

                      {assignedPermission?.update && (
                        <span className="me-2 table-custom">
                          <button
                            type="button"
                            rel="tooltip"
                            className="btn btn-outline-secondary rounded-circle btn-sm me-2"
                            data-original-title=""
                            title="Edit"
                            onClick={(e) => handleUpdateFAQ(e, fq)}
                          >
                            <i className="material-symbols-outlined">edit</i>
                          </button>
                        </span>
                      )}

                      {toogleFaq === id ? (
                        <span>
                          <FontAwesomeIcon
                            icon={faAngleUp}
                            style={{
                              cursor: "disabled",
                              float: "right",
                              color: "#2a5276",
                            }}
                            className="mr-2 "
                          />
                        </span>
                      ) : (
                        <span className="float-right">
                          <FontAwesomeIcon
                            icon={faAngleDown}
                            style={{
                              cursor: "pointer",
                              float: "right",
                              color: "#2a5276",
                            }}
                            className="mr-2 "
                          />
                        </span>
                      )}
                    </div>
                  </CardHeader>
                  <Collapse isOpen={toogleFaq === id}>
                    <>
                      <CardBody className="faqContent p-3">
                        {parse(`${fq.answer}`)}
                      </CardBody>
                    </>
                  </Collapse>
                </Card>
              );
            })
          ) : isFetching ? (
            <div className="text-center">
              <LoadingAnimation spinner />
            </div>
          ) : (
            <div className="text-center">No FAQ added</div>
          )}
        </div>

        {faqData?.total <= 5 ? (
          <></>
        ) : (
          <>
            {faqData && (
              <div className="paginationContainer">
                <Pagination
                  activePage={current_page}
                  itemsCountPerPage={per_page}
                  totalItemsCount={total}
                  onChange={(pageNumber) => fetchFaq(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First"
                  lastPageText="Last"
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="mt-2">
        <div className="pageTitle">
          {isDeveloperAdmin ? "HRM System FAQ's" : "Company FAQ's"}
        </div>
      </div>
      <div
        className="container-fluid m-0 p-0"
        style={{ padding: "1rem", height: "100%" }}
      >
        {submitSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting ...
            </div>
          </div>
        ) : null}

        {assignedPermission?.store || isDeveloperAdmin ? (
          <div className="">
            <Button name="Add FAQ's" onClick={toggleModal} />
          </div>
        ) : null}

        <ListFAQs />
      </div>

      <CommonModal
        modal={modal}
        toggleModal={toggleModal}
        modalHeader={isUpdate?.faqToBeUpdated ? "Update FAQ" : "Add FAQ"}
        size="lg"
        modalBody={
          <div className="container p-0 m-0">
            <FaqForm />
          </div>
        }
      />
    </>
  );
};

export default FAQ;
