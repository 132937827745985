import { faSadTear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router";

const ContactSupport = () => {
  let navigate = useNavigate();
  return (
    <div className="container" style={{ padding: "0.75rem", height: "100%" }}>
      <div className="row">
        <div className="container mt-4">
          <div className="col-12">
            <div className="mt-3">
              <ul className="list list-inline">
                <li className="d-flex justify-content-between">
                  <div className="d-flex flex-row align-items-center">
                    <i className="fa fa-check-circle checkicon"></i>
                    <div className="ml-2">
                      <h6 className="mb-0">Contact Support Team</h6>
                      <div className="d-flex flex-row mt-1 text-black-50 date-time"></div>
                    </div>
                  </div>
                  <div className="text-right">
                    <h6
                      className="mb-0 text-right"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        localStorage.clear();
                        navigate("/");
                      }}
                    >
                      LogOut
                    </h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col text-center newTextColor">
          <FontAwesomeIcon icon={faSadTear} size="8x" />
          <div className="mt-3" style={{ fontSize: "24px" }}>
            Oops! Sorry, there is no Company Guidelines available at the
            moment.Please contact support team or Company admin.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSupport;
