import React, { useEffect, useState } from "react";
import { Collapse, CardBody, Card, CardHeader, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { axiosGet } from "../utils/AxiosApi";
import { URL } from "../utils/Constants";
import { useNavigate } from "react-router";
import parse from "html-react-parser";
import Pagination from "react-js-pagination";

const DevFaq = () => {
  const [faqData, setFaqData] = useState(null);
  const [toogleFaq, setToogleFaq] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  let navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("token");

  useEffect(() => {
    fetchFaq();
  }, []);
  const fetchFaq = (pageNumber = 1) => {
    setIsFetching(true);
    setFaqData(null);

    axiosGet(
      URL.devFAQ + "?" + "page=" + pageNumber,
      (res) => {
        if (res.status === 200) {
          let modules = res.data;
          setIsFetching(false);
          setFaqData(modules);
        }
      },
      (err) => {
        setIsFetching(false);
      }
    );
  };

  if (faqData) {
    var { current_page, per_page, total } = faqData;
  }

  return (
    <>
      <div className="col-12">
        <div className="">
          <ul className="list list-inline">
            <li className="d-flex justify-content-between">
              <div className="d-flex flex-row align-items-center">
                <i className="fa fa-check-circle checkicon"></i>
                <div className="ml-2">
                  <h6 className="mb-0 text-center">
                    Frequently Asked Questions
                  </h6>
                  <div className="d-flex flex-row mt-1 text-black-50 date-time"></div>
                </div>
              </div>

              <div className="text-right">
                {isLoggedIn ? (
                  <h6
                    className="mb-0 text-right"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // localStorage.clear();
                      navigate("/");
                    }}
                  >
                    Back To Dashboard
                  </h6>
                ) : (
                  <h6
                    className="mb-0 text-right"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // localStorage.clear();
                      navigate("/");
                    }}
                  >
                    LogIn
                  </h6>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="my-5">
        <div className="container">
          <div className="whiteContainer p-4 mt-3">
            {faqData?.data?.length > 0 ? (
              faqData?.data?.map((fq, id) => {
                return (
                  <div className="row" key={id}>
                    <div className="col">
                      <Card className="faqCard">
                        <CardHeader
                          onClick={(e) => setToogleFaq(id)}
                          className="faqTitle"
                        >
                          <span className="font-weight-bold bg-green">
                            {fq.question}
                          </span>

                          {toogleFaq === id ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faAngleUp}
                                style={{
                                  cursor: "disabled",
                                  float: "right",
                                  color: "#2a5276",
                                }}
                                className="mr-2 "
                              />
                            </span>
                          ) : (
                            <span className="float-right">
                              <FontAwesomeIcon
                                icon={faAngleDown}
                                style={{
                                  cursor: "pointer",
                                  float: "right",
                                  color: "#2a5276",
                                }}
                                className="mr-2 "
                              />
                            </span>
                          )}
                        </CardHeader>
                        <Collapse isOpen={toogleFaq === id}>
                          <>
                            <CardBody className="faqContent p-3">
                              {parse(`${fq.answer}`)}
                            </CardBody>
                          </>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center">
                {isFetching ? (
                  <Spinner />
                ) : (
                  <div className="text-center">No FAQ's added</div>
                )}
              </div>
            )}
          </div>
          {faqData?.total <= 5 ? (
            <></>
          ) : (
            <div className="paginationContainer">
              <Pagination
                activePage={current_page}
                itemsCountPerPage={per_page}
                totalItemsCount={total}
                onChange={(pageNumber) => fetchFaq(pageNumber)}
                itemClass="page-item"
                linkClass="page-link"
                firstPageText="First"
                lastPageText="Last"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DevFaq;
