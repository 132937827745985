import moment from "moment";
import React, { Component } from "react";
import { Spinner } from "reactstrap";
import { axiosGet, axiosPost } from "../../utils/AxiosApi";
import { fileUrl, URL, cryptoSecretKey } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/DisplayMessage";
import logo from "../../assets/images/user-image.png";
import CryptoJS from "crypto-js";
import Button from "../../components/common/Button";
import CommonModal from "../../components/common/Modal";
import DepartmentStaffForm from "../DepartmentStaff/DepartmentStaffForm/DepartmentStaffForm";
import PasswordChange from "../DepartmentStaff/DepartmentStaffForm/PasswordChange";
import CustomFileViewer from "../../components/common/CustomFileViewer";
import CompanyForm from "../Company/CompanyForm/CompanyForm";
import { checkRoles } from "../../utils/PermissionChecker";
import errorImage from "../../assets/images/user-image.png";
import Contract from "../Contract/Contract";
import LoadingAnimation from "../../components/LoaderAnimation/LoaderAnimation";

class MyProfile extends Component {
  state = {
    loader: false,
    userInfo: null,
    modal: false,
    selectedId: 0,
    modalPwd: true,
    selectedDoc: null,
    modalFile: { modal: false, selected: null },
    isAdmin: false,
    status: false,
    fileModal: { modal: false, media: null },
    originalUserInfo: null,
    companyModal: false,
    imageError: false,
    myContract: null,
    contractModal: false,
    editParam: {
      selectedIndex: null,
      isEdit: false,
      selectedDepartment: null,
    },
    isMyProfile: false,
    designationlist: null
  };
  componentDidMount() {
    this.checkRolePermissions();
    this.getDesignation();
  }

  changeImageError = () => {
    this.setState({ imageError: false });
  };

  checkRolePermissions = () => {
    let rolePermission = checkRoles("Developer Admin");
    this.setState({ isAdmin: rolePermission }, function () {
      this.getUserInfo();
      this.getMyContract();
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userInfo !== this.state.userInfo) {
      if (!this.state.isAdmin && !this.state.status) {
        this.getDoc();
      }
    }
  }


  getDesignation = () => {
    axiosGet(
      URL.getDesignationStaff,
      (response) => {
        if (response.data.success) {
          let modules = response.data.data.items;
          this.setState({ designationlist: modules })
        }
      },
      (err) => {
        displayErrorAlert(err);
      }
    );
  };

  getDoc = () => {
    //find if doc available
    const ctzDoc = this.state.userInfo?.media?.find(
      (uf) => uf.collection_name === "citizenship_files"
    );
    if (ctzDoc) {
      const { id, file_name } = ctzDoc;
      this.setState((prevState) => ({
        selectedDoc: { ...prevState.selectedDoc, ctz: { id, file_name } },
      }));
    }

    const panDoc = this.state.userInfo?.media?.find(
      (uf) => uf.collection_name === "pan_card_files"
    );
    if (panDoc) {
      const { id, file_name } = panDoc;
      this.setState((prevState) => ({
        selectedDoc: { ...prevState.selectedDoc, pan: { id, file_name } },
      }));
    }
    const sigDoc = this.state.userInfo?.media?.find(
      (uf) => uf.collection_name === "signature_files"
    );
    if (sigDoc) {
      const { id, file_name } = sigDoc;
      this.setState((prevState) => ({
        selectedDoc: { ...prevState.selectedDoc, sig: { id, file_name } },
      }));
    }

    const profilePic = this.state.userInfo?.media?.find(
      (uf) => uf.collection_name === "profile_picture_files"
    );

    if (profilePic) {
      const { id, file_name } = profilePic;
      this.setState((prevState) => ({
        selectedDoc: {
          ...prevState.selectedDoc,
          proficePic: { id, file_name },
        },
      }));
    }

    // const expDoc = this.state.userInfo?.media.filter(
    //   uf => uf.collection_name === "experience_docs_files"
    // );

    // expDoc &&
    //   this.setState(prevState => ({
    //     selectedDoc: { ...prevState.selectedDoc, exp: expDoc }
    //   }));

    // const traDoc = this.state.userInfo?.media.filter(
    //   uf => uf.collection_name === "training_docs_files"
    // );

    // traDoc &&
    //   this.setState(prevState => ({
    //     selectedDoc: { ...prevState.selectedDoc, tra: traDoc }
    //   }));
  };

  getMyContract = () => {
    axiosPost(URL.getMyContract, {}, (response) => {
      if (response.status === 200) {
        if (response.data.data) {
          this.setState({ myContract: response.data.data.item });
        }
      }
    });
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
      isMyProfile: !this.state.modal
    }, () => {
      this.state.modal && this.getDoc();
    });
    // this.getUserInfo();
  };

  toggleCompanyModal = () => {
    this.setState({ companyModal: !this.state.companyModal });
    this.getUserInfo();
  };

  toggleModalPwd = () => {
    this.setState({ modalPwd: !this.state.modalPwd });
  };

  toggleFileModal = (status) => {
    this.setState({
      modalFile: { modal: !this.state.modalFile.modal, selected: status },
    });
  };

  toggleModalFile = (value) => {
    this.setState({
      fileModal: {
        modal: !this.state.fileModal.modal,
        media: value,
      },
    });
  };

  toggleContractModal = () => {
    this.setState({ contractModal: !this.state.contractModal });
  };

  getUserInfo = () => {
    this.setState({ loader: true }, function () {
      axiosGet(
        URL.userInfo,
        (response) => {
          if (response.data.success) {
            this.setState(
              { userInfo: response.data.data.item, loader: false },
              () => {
                this.setState({ originalUserInfo: response.data.data.item });
              }
            );
          }
        },
        (err) => {
          this.setState({ loader: false });
          displayErrorAlert(err);
        }
      );
    });
  };

  handleImageError = (e) => {
    this.setState({ imageError: true });
  };

  companyInfoSetterFunc = (value) => {
    const cloneCompanyInfo = { ...this.state.originalUserInfo };
    cloneCompanyInfo.company = value;
    this.setState({ originalUserInfo: cloneCompanyInfo });
  };

  userInfoSetterFunc = (value, status) => {
    //make changes to the userInfo data accordingly
    const cloneUserInfo = { ...this.state.userInfo };
    status === "edu" && (cloneUserInfo.education = value);
    status === "exp" && (cloneUserInfo.experience = value);
    status === "tra" && (cloneUserInfo.training = value);
    status === "skl" && (cloneUserInfo.skill = value);

    const fileName = [
      "first_name",
      "last_name",
      "job_title",
      "personal_email",
      "middle_name",
    ];
    if (fileName.includes(status)) {
      cloneUserInfo[status] = value;
    }
    this.setState({ userInfo: cloneUserInfo });
  };

  userInfoSetterStaffDetails = () => { };

  statusSetterFunc = () => {
    this.setState({ status: true });
  };
  //make changes on userInfo File Name
  makeChangesOnFile = (status) => {
    // const cloneUserInfoFileName = { ...this.state.selectedDoc };
    const cloneUserInfoFileName = JSON.parse(
      JSON.stringify(this.state.selectedDoc)
    );
    if (status === "citizenship" && "ctz" in cloneUserInfoFileName) {
      delete cloneUserInfoFileName["ctz"];
    }
    if (status === "panCard" && "pan" in cloneUserInfoFileName) {
      delete cloneUserInfoFileName["pan"];
    }
    if (status === "digitalSignature" && "sig" in cloneUserInfoFileName) {
      delete cloneUserInfoFileName["sig"];
    }

    if (status === "academics_docs" && "edu" in cloneUserInfoFileName) {
      delete cloneUserInfoFileName["edu"];
    }

    this.setState({ selectedDoc: cloneUserInfoFileName });
  };

  controlParam = () => {
    this.setState({
      editParam: {
        ...this.state.editParam,
        isEdit: true,
        selectedDepartment: null,
        selectedIndex: null,
      },
    });
  };

  render() {
    const isAdmin = this.state.isAdmin;
    const data = localStorage.getItem("roles");
    const roles = JSON.parse(
      CryptoJS.AES.decrypt(data, cryptoSecretKey).toString(CryptoJS.enc.Utf8)
    );
    const userRole = ["Developer Admin", "Super Admin"];
    const isDepartmentStaff = !userRole.includes(roles[0]);

    return (
      <>
        <div className="mt-2">
          <div className="row">
            <div className="col">
              <div className="pageTitle">My Profile</div>
            </div>
          </div>
        </div>
        {!!this.state.userInfo && !this.state.loader ? (
          <>
            <div className="container-fluid p-0 m-0">
              <div className="row m-0">
                {this.state.userInfo ? (
                  <>
                    <div className="col-md-8 ps-md-0">
                      <div className="table-responsive whiteContainer p-4 mt-0">
                        <div className="row mb-3">
                          {!isAdmin ? (
                            <div className="col">
                              <Button
                                name="Update Profile Details"
                                onClick={() => {
                                  this.toggleModal();
                                }}
                                style={{ float: "right" }}
                              />
                            </div>
                          ) : null}

                          {isDepartmentStaff ? null : (
                            <div className="col">
                              <Button
                                name="Update Company Profile"
                                onClick={() => {
                                  this.toggleCompanyModal();
                                }}
                                style={{ float: "right" }}
                              />
                            </div>
                          )}
                        </div>

                        <table className="table table-sm table-custom">
                          <thead>
                            <tr>
                              <td>My Information</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <strong>Name</strong>
                              </td>
                              <td>:</td>
                              <td>
                                {this.state.userInfo.first_name}
                                {this.state.userInfo.middle_name
                                  ? ` ${this.state.userInfo.middle_name} `
                                  : " "}
                                {this.state.userInfo.last_name}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Job Title</strong>
                              </td>
                              <td>:</td>
                              <td>{this.state.userInfo.job_title}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Designation</strong>
                              </td>
                              <td>:</td>
                              <td>{this.state.userInfo?.designation_id ? this.state?.designationlist?.filter((item) => item.id === this.state.userInfo?.designation_id)[0]?.name : "-"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Email</strong>
                              </td>
                              <td>:</td>
                              <td>
                                {this.state.userInfo.office_email ??
                                  this.state.userInfo.email}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Role</strong>
                              </td>
                              <td>:</td>
                              {/* <td>{roles[0]}</td> */}
                              <td>
                                {roles && roles.length > 1
                                  ? roles.map((el) => {
                                    return `${el}, `;
                                  })
                                  : roles[0]}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Contact Number</strong>
                              </td>
                              <td>:</td>
                              <td>
                                {this.state.userInfo.personal_number}{" "}
                                <strong>[Personal Number]</strong> <br />
                                {this.state.userInfo.corporate_number}{" "}
                                <strong>[Corporate Number]</strong>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <strong>Personal Email</strong>
                              </td>
                              <td>:</td>
                              <td>{this.state.userInfo.personal_email}</td>
                            </tr>
                            {this.state.userInfo.temporary_address &&
                              this.state.userInfo.temporary_address !== "" ? (
                              <tr>
                                <td>
                                  <strong>Temporary Address</strong>
                                </td>
                                <td>:</td>
                                <td>{this.state.userInfo.temporary_address}</td>
                              </tr>
                            ) : null}
                            {this.state.userInfo.permanent_address &&
                              this.state.userInfo.permanent_address !== "" ? (
                              <tr>
                                <td>
                                  <strong>Permanent Address</strong>
                                </td>
                                <td>:</td>
                                <td>{this.state.userInfo.permanent_address}</td>
                              </tr>
                            ) : null}
                            {this.state.userInfo.blood_group &&
                              this.state.userInfo.blood_group !== "" ? (
                              <tr>
                                <td>
                                  <strong>Blood Group</strong>
                                </td>
                                <td>:</td>
                                <td>{this.state.userInfo.blood_group}</td>
                              </tr>
                            ) : null}
                            {this.state.userInfo.marital_status &&
                              this.state.userInfo.marital_status !== "" ? (
                              <tr>
                                <td>
                                  <strong>Marital Status</strong>
                                </td>
                                <td>:</td>
                                <td>{this.state.userInfo.marital_status}</td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>
                      </div>

                      {this.state.userInfo.company ? (
                        <div className="table-responsive whiteContainer p-4 mt-2">
                          <table className="table table-sm table-custom">
                            <thead>
                              <tr>
                                <td>Company Information</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <strong>Name</strong>
                                </td>
                                <td>:</td>
                                <td>{this.state.userInfo.company.name}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Address</strong>
                                </td>
                                <td>:</td>
                                <td>
                                  {this.state.userInfo.company.address_line}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Email Address</strong>
                                </td>
                                <td>:</td>
                                <td>
                                  {this.state.userInfo.company.email_address}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Contact Number</strong>
                                </td>
                                <td>:</td>
                                <td>
                                  {this.state.userInfo.company.contact_number}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                    </div>

                    {this.state.userInfo ? (
                      <>
                        <div className="col-md-4 pe-md-0">
                          {!isDepartmentStaff && (
                            <div className="userInfoLogo whiteContainer mt-0 p-4">
                              {this.state.userInfo.company ? (
                                this.state.userInfo.company.media.map(
                                  (logos, idx) => {
                                    if (
                                      logos.collection_name === "company_logo"
                                    ) {
                                      return (
                                        <img
                                          key={idx}
                                          alt="logo"
                                          className="img-fluid"
                                          src={
                                            fileUrl +
                                            "/" +
                                            logos.id +
                                            "/" +
                                            logos.file_name
                                          }
                                        />
                                      );
                                    }
                                  }
                                )
                              ) : (
                                <img src={logo} alt="logo" />
                              )}
                            </div>
                          )}
                          {!isDepartmentStaff && (
                            <div className="text-center whiteContainer mt-2 p-3">
                              <h5 className="sectionTitle mb-0">
                                Company Logo
                              </h5>
                            </div>
                          )}
                          <div className="">
                            {isDepartmentStaff &&
                              this.state.userInfo?.media &&
                              this.state.userInfo.media.map((logos, id) => {
                                if (
                                  logos.collection_name ===
                                  "profile_picture_files"
                                ) {
                                  return (
                                    <div className="col pe-md-0" key={id}>
                                      <div className="userInfoLogo whiteContainer mt-0 p-4">
                                        <img
                                          alt="logo"
                                          className="img-fluid"
                                          key={logos.id}
                                          src={
                                            this.state.imageError
                                              ? errorImage
                                              : fileUrl +
                                              "/" +
                                              logos.id +
                                              "/" +
                                              logos.file_name
                                          }
                                          onError={this.handleImageError}
                                        />
                                      </div>

                                      {/* <div className="text-center whiteContainer mt-2 p-3">
                                    <h5 className="sectionTitle mb-0">
                                      Profile Image
                                    </h5>
                                  </div> */}

                                      {this.state.selectedDoc?.ctz && (
                                        <div className="text-center whiteContainer mt-2 p-3">
                                          <Button
                                            name="View Citizenship Document "
                                            onClick={() =>
                                              this.toggleFileModal("ctz")
                                            }
                                          ></Button>
                                        </div>
                                      )}

                                      {this.state.selectedDoc?.pan && (
                                        <div className="text-center whiteContainer mt-2 p-3">
                                          <Button
                                            name="View Pan Document "
                                            onClick={() =>
                                              this.toggleFileModal("pan")
                                            }
                                          ></Button>
                                        </div>
                                      )}

                                      {this.state.selectedDoc?.sig && (
                                        <div className="text-center whiteContainer mt-2 p-3 mb-1">
                                          <Button
                                            name="View Digital Signature  "
                                            onClick={() =>
                                              this.toggleFileModal("sig")
                                            }
                                          ></Button>
                                        </div>
                                      )}
                                      {this.state.myContract ? (
                                        <div className="text-center whiteContainer mt-2 p-3 mb-1">
                                          <Button
                                            name="View Contract"
                                            onClick={() =>
                                              this.toggleFileModal("contract")
                                            }
                                          ></Button>
                                        </div>
                                      ) : (
                                        <div className="text-center whiteContainer mt-2 p-3 mb-1">
                                          <Button
                                            name="Upload Contract"
                                            onClick={() =>
                                              this.toggleContractModal()
                                            }
                                          ></Button>
                                        </div>
                                      )}
                                      {this.state.userInfo.emergency_name ||
                                        this.state.userInfo.emergency_number ||
                                        this.state.userInfo.emergency_relation ? (
                                        <div className="whiteContainer p-4 mt-2">
                                          <table className="table table-sm table-custom">
                                            <thead>
                                              <tr>
                                                <td colSpan={3}>
                                                  Emergency Contact
                                                </td>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <strong>Name</strong>
                                                </td>
                                                <td>:</td>
                                                <td>
                                                  {
                                                    this.state.userInfo
                                                      .emergency_name
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  <strong>Number</strong>
                                                </td>
                                                <td>:</td>
                                                <td>
                                                  {
                                                    this.state.userInfo
                                                      .emergency_number
                                                  }
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>
                                                  <strong>Relation</strong>
                                                </td>
                                                <td>:</td>
                                                <td>
                                                  {
                                                    this.state.userInfo
                                                      .emergency_relation
                                                  }
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      ) : null}
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>

            {!isAdmin &&
              this.state.userInfo &&
              this.state.userInfo?.education?.length > 0 ? (
              <div className="container-fluid p-0 m-0">
                <div className="row">
                  <div className="col">
                    <div className="pageTitle mb-1">Educational Details</div>
                  </div>
                </div>
                <div className="row">
                  {this.state.userInfo?.education?.map((ed) => (
                    <div
                      key={ed.id}
                      className={
                        "mb-3 " +
                        (this.state.userInfo?.education?.length > 1
                          ? "col-md-6"
                          : "col-md-12")
                      }
                    >
                      <div className="table-responsive whiteContainer p-4 mt-2">
                        <table className="table table-sm table-custom">
                          <thead>
                            <tr>
                              <td>{ed.degree ?? "Not available"}</td>
                            </tr>
                          </thead>
                          <tbody className="mb-2">
                            <tr>
                              <td>
                                <strong>Board</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.board ?? "Not available"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Name of the Institution</strong>
                              </td>
                              <td>:</td>
                              <td>
                                {ed.name_of_institution ?? "Not available"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Program</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.program ?? "Not available"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Obtained Marks</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.obtained_marks ?? "Not available"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Enrolled Year</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.enrolled_year ?? "Not available"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Graduation Year</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.graduation_year ?? "Running"}</td>
                            </tr>
                          </tbody>
                        </table>
                        {ed?.media?.length > 0 && (
                          <Button
                            name="View Educational Documents "
                            onClick={() => this.toggleModalFile(ed.media[0])}
                          ></Button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            {!isAdmin &&
              this.state.userInfo &&
              this.state.userInfo?.experience?.length > 0 ? (
              <div className="container-fluid p-0 m-0">
                <div className="row">
                  <div className="col">
                    <div className="pageTitle mb-1">Experience Details</div>
                  </div>
                </div>
                <div className="row">
                  {this.state.userInfo?.experience.map((ed) => (
                    <div
                      key={ed.id}
                      className={
                        "mb-3 " +
                        (this.state.userInfo?.experience?.length > 1
                          ? "col-md-6"
                          : "col-md-12")
                      }
                    >
                      <div className="table-responsive whiteContainer p-4 mt-2">
                        <table className="table table-sm table-custom">
                          <thead>
                            <tr>
                              <td>{ed.job_title ?? "Not available"}</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <strong>Job Level</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.job_level ?? "Not available"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Duties</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.duties ?? "Not available"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Nature of Organisation</strong>
                              </td>
                              <td>:</td>
                              <td>
                                {ed.nature_of_organisation ?? "Not available"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Organisation name</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.organisation_name ?? "Not available"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Organisation Location</strong>
                              </td>
                              <td>:</td>
                              <td>
                                {ed.organisation_location ?? "Not available"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Start Date</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.start_date ?? "Not available"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>End Date</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.end_date ?? "Not available"}</td>
                            </tr>
                          </tbody>
                        </table>

                        {ed?.media?.length !== 0 && (
                          <Button
                            name="View Experience Documents "
                            onClick={() => this.toggleModalFile(ed.media[0])}
                          ></Button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            {!isAdmin &&
              this.state.userInfo &&
              this.state.userInfo?.training?.length > 0 ? (
              <div className="container-fluid m-0 p-0">
                <div className="row">
                  <div className="col">
                    <div className="pageTitle mb-1">Training Details</div>
                  </div>
                </div>
                <div className="row">
                  {this.state.userInfo?.training.map((ed) => (
                    <div
                      key={ed.id}
                      className={
                        "mb-3 " +
                        (this.state.userInfo?.training?.length > 1
                          ? "col-md-6"
                          : "col-md-12")
                      }
                    >
                      <div className="table-responsive whiteContainer p-4 mt-2">
                        <table className="table table-sm table-custom">
                          <thead>
                            <tr>
                              <td>{ed.training_name ?? "Not available"}</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <strong>Training Institute</strong>
                              </td>
                              <td>:</td>
                              <td>
                                {ed.training_institute ?? "Not available"}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Duration</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.duration ?? "Not available"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Completed Date</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.completed_date ?? "Not available"}</td>
                            </tr>
                          </tbody>
                        </table>
                        {ed?.media?.length !== 0 && (
                          <Button
                            name="View Training Documents "
                            onClick={() => this.toggleModalFile(ed.media[0])}
                          ></Button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            {!isAdmin &&
              this.state.userInfo &&
              this.state.userInfo?.skill?.length > 0 ? (
              <div className="container-fluid m-0 p-0">
                <div className="row">
                  <div className="col">
                    <div className="pageTitle mb-1">Skill Details</div>
                  </div>
                </div>
                <div className="row">
                  {this.state.userInfo?.skill.map((ed) => (
                    <div
                      key={ed.id}
                      className={
                        "mb-3 " +
                        (this.state.userInfo?.skill?.length > 1
                          ? "col-md-6"
                          : "col-md-12")
                      }
                    >
                      <div className="table-responsive whiteContainer p-4 mt-2">
                        <table className="table table-sm table-custom">
                          <thead>
                            <tr>
                              <td>{ed.skill_name ?? "Not available"}</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <strong>Skill level</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.skill_level ?? "Not available"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Description</strong>
                              </td>
                              <td>:</td>
                              <td>{ed.description ?? "Not available"}</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Experience duration</strong>
                              </td>
                              <td>:</td>
                              <td>
                                {ed.experience_duration ?? "Not available"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
            {/* {!isAdmin ? ( */}
            <CommonModal
              modal={this.state.modal}
              toggleModal={() => this.toggleModal()}
              size="xl"
              modalHeader={"Update Profile Details"}
              modalBody={
                <>
                  <DepartmentStaffForm
                    toggleModal={this.toggleModal}
                    editParam={this.state.editParam}
                    controlParam={this.controlParam}
                    addProfile
                    userInfo={this.state.userInfo}
                    getDepartmentStaff={this.getUserInfo}
                    userInfoFile={this.state.selectedDoc}
                    setterFunction={this.makeChangesOnFile}
                    setUserInfo={this.userInfoSetterFunc}
                    status={this.state.status}
                    setStatus={this.statusSetterFunc}
                    originalUserInfo={this.state.originalUserInfo}
                    changeImageError={this.changeImageError}
                    isMyProfile={this.state.isMyProfile}
                  />
                </>
              }
            />
            <CommonModal
              modal={this.state.companyModal}
              toggleModal={() => this.toggleCompanyModal()}
              size="xl"
              modalHeader={"Update Company Details"}
              modalBody={
                <>
                  <CompanyForm
                    toggleModal={this.toggleCompanyModal}
                    updateCompany
                    updateCompanyProfile
                    companyInfo={this.state.originalUserInfo}
                    fetchCompanyInfo={this.getUserInfo}
                    SetCompanyProfileInfo={this.companyInfoSetterFunc}
                  />
                </>
              }
            />
            {isDepartmentStaff &&
              !JSON.parse(localStorage.getItem("isPasswordChanged")) ? (
              <CommonModal
                modal={this.state.modalPwd}
                toggleModal={this.toggleModalPwd}
                size="lg"
                modalHeader="Change Password"
                modalBody={<PasswordChange toggleModal={this.toggleModalPwd} />}
              />
            ) : null}
            {!isAdmin &&
              this.state.selectedDoc?.edu &&
              this.state.modalFile.selected === "edu" && (
                <CommonModal
                  modal={this.state.modalFile.modal}
                  modalHeader="Academic Document"
                  modalBody={
                    <CustomFileViewer
                      fileExtension={this.state.selectedDoc.edu.file_name
                        ?.split(".")
                        .pop()}
                      filePath={`${fileUrl}/${this.state.selectedDoc.edu.id}/${this.state.selectedDoc.edu.file_name}`}
                    />
                  }
                  size="xl"
                  toggleModal={this.toggleFileModal}
                />
              )}
            {!isAdmin &&
              this.state.selectedDoc?.ctz &&
              this.state.modalFile.selected === "ctz" && (
                <CommonModal
                  modal={this.state.modalFile.modal}
                  modalHeader="Citizenship"
                  modalBody={
                    <CustomFileViewer
                      fileExtension={this.state.selectedDoc.ctz.file_name
                        ?.split(".")
                        .pop()}
                      filePath={`${fileUrl}/${this.state.selectedDoc.ctz.id}/${this.state.selectedDoc.ctz.file_name}`}
                    />
                  }
                  size="xl"
                  toggleModal={this.toggleFileModal}
                />
              )}
            {!isAdmin &&
              this.state.selectedDoc?.sig &&
              this.state.modalFile.selected === "sig" && (
                <CommonModal
                  modal={this.state.modalFile.modal}
                  modalHeader="Digital Signature"
                  modalBody={
                    <CustomFileViewer
                      fileExtension={this.state.selectedDoc.sig.file_name
                        ?.split(".")
                        .pop()}
                      filePath={`${fileUrl}/${this.state.selectedDoc.sig.id}/${this.state.selectedDoc.sig.file_name}`}
                    />
                  }
                  size="xl"
                  toggleModal={this.toggleFileModal}
                />
              )}
            {!isAdmin &&
              this.state.selectedDoc?.pan &&
              this.state.modalFile?.selected === "pan" && (
                <CommonModal
                  modal={this.state.modalFile.modal}
                  modalHeader="PAN Card"
                  modalBody={
                    <CustomFileViewer
                      fileExtension={this.state.selectedDoc.pan.file_name
                        ?.split(".")
                        .pop()}
                      filePath={`${fileUrl}/${this.state.selectedDoc.pan.id}/${this.state.selectedDoc.pan.file_name}`}
                    />
                  }
                  size="xl"
                  toggleModal={this.toggleFileModal}
                />
              )}
            {!isAdmin && this.state.modalFile?.selected === "contract" && (
              <CommonModal
                modal={this.state.modalFile.modal}
                modalHeader="Contract"
                modalBody={
                  <CustomFileViewer
                    fileExtension={this.state.myContract.media[0].file_name
                      ?.split(".")
                      .pop()}
                    filePath={`${fileUrl}/${this.state.myContract.media[0].id}/${this.state.myContract.media[0].file_name}`}
                  />
                }
                size="xl"
                toggleModal={this.toggleFileModal}
              />
            )}
            {!isAdmin && this.state.fileModal.modal && (
              <CommonModal
                modal={this.state.fileModal.modal}
                modalHeader="File"
                modalBody={
                  <CustomFileViewer
                    fileExtension={this.state.fileModal.media.file_name
                      ?.split(".")
                      .pop()}
                    filePath={`${fileUrl}/${this.state.fileModal.media.id}/${this.state.fileModal.media.file_name}`}
                  />
                }
                size="xl"
                toggleModal={this.toggleModalFile}
              />
            )}
            <CommonModal
              modal={this.state.contractModal}
              modalHeader="Upload Contract"
              modalBody={
                <Contract
                  toggleContractModal={this.toggleContractModal}
                  getMyContract={this.getMyContract}
                ></Contract>
              }
              toggleModal={this.toggleContractModal}
            ></CommonModal>
          </>
        ) : this.state.loader ? (
          <LoadingAnimation spinner />
        ) : (
          "No user information available"
        )}
      </>
    );
  }
}

export default MyProfile;
