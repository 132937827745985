import React, { Component } from "react";
import Button from "../../components/common/Button";
import Select from "react-select";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { Label } from "reactstrap";

class DepartmentView extends Component {
  state = {
    departmentRoles: [{ role: "", selectedPermission: [] }],
  };

  resetForm = () => {
    this.setState({ departmentRole: [{ roles: "", selectedPermission: [] }] });
  };

  handleAdd = () => {
    let departmentRole = { role: "", selectedPermission: [] };
    let departmentRoles = [...this.state.departmentRoles];
    departmentRoles.push(departmentRole);
    this.setState({ departmentRoles });
  };

  handleChange = (e, idx) => {
    let departmentRoles = [...this.state.departmentRoles];
    if (e.target.name === "role") {
      let { value } = e.target;
      departmentRoles[idx].role = value;
    }
    // departmentRoles[idx][name] = value;
    this.setState({ departmentRoles });
  };

  handleSelectChange = (value, idx) => {
    let departmentRoles = [...this.state.departmentRoles];
    departmentRoles[idx].selectedPermission = value;
    this.setState({ departmentRoles });
  };

  handleRemove = (idx) => {
    let departmentRoles = [...this.state.departmentRoles];
    departmentRoles.splice(idx, 1);
    this.setState({ departmentRoles });
  };

  handleSubmit = (selectedDepartment) => {
    let departmentRoles = [...this.state.departmentRoles];

    //check if keys are empty

    const isEmpty = departmentRoles.some(
      (dpt) => dpt.role === "" || dpt.selectedPermission.length === 0
    );

    if (isEmpty) {
      swal("Warning", "Role or Permission field is empty", "warning");
      return;
    }

    let roleParams = [];
    if (departmentRoles.length > 0) {
      departmentRoles.forEach((el) => {
        let temp = {
          role_name: `${this.props?.isViewDepartment?.selectedDepartment?.name} ${el.role} `,
          permission_ids: [],
        };
        if (el.selectedPermission.length > 0) {
          let permission = [];
          el.selectedPermission.forEach((p) => {
            permission.push(p.value);
          });
          temp.permission_ids = permission;
        } else {
          swal(
            "Warning",
            "No permission selected for Assigned Role",
            "warning"
          );
          return;
        }
        roleParams.push(temp);
      });
    }
    let data = {
      department_id: selectedDepartment.id,
      param: roleParams,
    };
    axiosPost(
      URL.addDepartmentRoles,
      data,
      (response) => {
        if (response.status === 200) {
          swal("Success", "Roles successfully added", "success");
          this.resetForm();
          this.props.getDepartment();
          this.props.toggleModal();
        }
      },
      (err) => {
        swal("Warning", `${err?.response?.data?.message}`, "warning");
      }
    );
  };

  render() {
    const {
      isViewDepartment: { isView, selectedDepartment },
      permission, formattedPermissions
    } = this.props;

    return (
      <div style={{ minHeight: "40vh", maxHeight: "85vh" }}>
        <div className="row mb-2">
          <div className="container mt-2">
            <div className="table-responsive">
              <table className="table table-sm table-custom">
                <thead>
                  <tr>
                    <th style={{ width: "450px" }}>Role</th>
                    <th scope="col-10">Permission</th>
                    <th style={{ width: "100px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.departmentRoles &&
                    this.state.departmentRoles.map((dptR, id) => {
                      return (
                        <tr key={id}>
                          <td>
                            <div className="col">
                              <div className="input-group ">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon3"
                                  >
                                    {selectedDepartment?.name}
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="basic-url"
                                  name="role"
                                  aria-describedby="basic-addon3"
                                  placeholder="Role"
                                  value={dptR.role}
                                  onChange={(e) => this.handleChange(e, id)}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="col">
                              <Select
                                closeMenuOnSelect={false}
                                classNamePrefix="select"
                                menuPosition={"fixed"}
                                placeholder="Permission"
                                isMulti={true}
                                isClearable={true}
                                value={dptR.selectedPermission}
                                options={formattedPermissions}
                                onChange={(e) => {
                                  this.handleSelectChange(e, id)
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="col">
                              {id === this.state.departmentRoles.length - 1 ? (
                                <button
                                  className="removeListBtn btn btn-outline-info rounded-circle btn-sm ms-2"
                                  onClick={this.handleAdd}
                                >
                                  <span className="material-symbols-outlined">
                                    add
                                  </span>
                                </button>
                              ) : null}
                              {id !== 0 ? (
                                <button
                                  onClick={() => this.handleRemove(id)}
                                  className="removeListBtn btn btn-outline-info rounded-circle btn-sm ms-2"
                                >
                                  <span className="material-symbols-outlined">
                                    remove
                                  </span>
                                </button>
                              ) : null}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="mt-2">
          <Button
            name="Save Role"
            onClick={() => this.handleSubmit(selectedDepartment)}
          />
        </div>
        {/* </div> */}
      </div>
    );
  }
}

export default DepartmentView;
