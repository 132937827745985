import React, { useState } from "react";
import "../../assets/scss/companyView.scss";
import { fileUrl } from "../../utils/Constants";
import Button from "../../components/common/Button";
import "../../assets/scss/companyView.scss";
import CommonModal from "../../components/common/Modal";
import CustomFileViewer from "../../components/common/CustomFileViewer";

const CompanyView = (prop) => {
  const { companyToBeViewed } = prop;
  const [fileModal, setFileModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const getImage = (cmp) => {
    const img = cmp?.media?.find((c) => c.collection_name === "company_logo");

    if (img) {
      let src = fileUrl + "/" + img.id + "/" + img.file_name;
      return src;
    }

    return "https://imgs.search.brave.com/fg2C8KWs0ztoktGIawgMn1N06rE_xSyD0Mmdu-ALL-8/rs:fit:250:250:1/g:ce/aHR0cDovL3FwbGV4/dXMuY29tL3dwLWNv/bnRlbnQvdXBsb2Fk/cy8yMDE2LzAyL2Rl/ZmF1bHQtbG9nby5w/bmc";
  };

  const getRegistrationFile = (cmp) => {
    const file = cmp?.media?.find(
      (c) => c.collection_name === "company_registration"
    );
    let src = fileUrl + "/" + file.id + "/" + file.file_name;
    return src;
  };

  const toggleFileModal = (cmp) => {
    setSelectedCompany(cmp);
    setFileModal(!fileModal);
  };

  return (
    <div>
      {companyToBeViewed && companyToBeViewed.length > 0 ? (
        companyToBeViewed.map((cmp, id) => {
          return (
            <div className="container p-0" key={id}>
              <div className="whiteContainer p-4 pt-1 mt-1">
                <div className="row align-items-center">
                  <div className="pageTitle">Company Details</div>
                  <div className="col-4">
                    <div className="profile-img">
                      <img src={getImage(cmp)} alt="company-logo" />
                    </div>
                  </div>
                  <div className="col-8">
                    <table className="table">
                      <tbody>
                        <tr className="textColor">
                          <td>
                            <strong>Company Name</strong>
                          </td>
                          <td>:</td>
                          <td>{cmp.name}</td>
                        </tr>
                        <tr className="textColor">
                          <td>
                            <strong>Company Short Name</strong>
                          </td>
                          <td>:</td>
                          <td>{cmp.short_name}</td>
                        </tr>
                        <tr className="textColor">
                          <td>
                            <strong>Company Website</strong>
                          </td>
                          <td>:</td>
                          <td>{cmp.domain_name}</td>
                        </tr>
                        <tr className="textColor">
                          <td>
                            <strong>Address</strong>
                          </td>
                          <td>:</td>
                          <td>{cmp.address_line}</td>
                        </tr>
                        <tr className="textColor">
                          <td>
                            <strong>Email Address</strong>
                          </td>
                          <td>:</td>
                          <td>{cmp.email_address}</td>
                        </tr>
                        <tr className="textColor">
                          <td>
                            <strong>Contact Phone Number</strong>
                          </td>
                          <td>:</td>
                          <td>{cmp.contact_number}</td>
                        </tr>
                      </tbody>
                    </table>
                    <Button
                      name="View Registration File"
                      onClick={() => toggleFileModal(cmp)}
                    ></Button>
                  </div>
                </div>
              </div>
              <div className="whiteContainer p-4 pt-1 mt-1">
                <div className="row">
                  <div className="pageTitle">Contact Details</div>

                  {cmp.contact.length > 0 ? (
                    cmp.contact.map((cnt, idx) => {
                      return (
                        <div className="col-md-12" key={idx}>
                          <div className="row contactDetailBoxHolder g-0">
                            <div className="col">
                              <div className="detailBoxTitle">
                                <span>DESIGNATION</span> : {cnt.designation}
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="detailImageHolder">
                                  <img
                                    src={
                                      fileUrl +
                                      "/" +
                                      cnt.media[0]?.id +
                                      "/" +
                                      cnt.media[0].file_name
                                    }
                                    alt="company-logo"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="contactDetailTable">
                                  <div className="name">
                                    {`${cnt?.first_name} ${
                                      cnt?.middle_name ? cnt?.middle_name : ""
                                    } ${cnt?.last_name}`}
                                  </div>
                                  <div className="info">
                                    <p>
                                      <i className="material-symbols-outlined">
                                        home_pin
                                      </i>

                                      <span>{cnt.address}</span>
                                    </p>
                                    <p>
                                      <i className="material-symbols-outlined">
                                        call
                                      </i>
                                      <span>{cnt.person_contact_number}</span>
                                    </p>
                                    <p>
                                      <i className="material-symbols-outlined">
                                        alternate_email
                                      </i>
                                      <span>{cnt.email}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col text-center p-3 textColor">
                      No contact Details
                    </div>
                  )}
                </div>
              </div>
              <div className="whiteContainer p-4 pt-1 mt-1">
                <div className="row">
                  <div className="pageTitle">User Details</div>
                  {cmp.user.length > 0 ? (
                    cmp.user.map((usr, uId) => {
                      return (
                        <div className="col-md-6" key={uId}>
                          <div className="userDetailHolder">
                            <div>
                              <strong>{`${usr?.first_name} ${
                                usr?.middle_name ? usr?.middle_name : ""
                              } ${usr?.last_name}`}</strong>
                            </div>
                            <div style={{ fontSize: "14px" }}>
                              [ {usr.email} ]
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col text-center p-3 textColor">
                      No users
                    </div>
                  )}
                </div>
              </div>
              <div className="whiteContainer p-4 pt-1 mt-1">
                <div className="row">
                  <div className="pageTitle">Company Settings</div>
                  <div className="col">
                    <ul className="textColor">
                      <li>
                        {cmp.setting.has_sister_concern == 0
                          ? "The company is not allowed to have Sister Concern Companies"
                          : "The company is allowed to have Sister Concern Companies"}
                      </li>
                      <li>
                        {cmp.setting.can_have_branch == 0
                          ? "The company is not allowed to have Branches"
                          : "The company is allowed to have Branches"}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="col text-center p-3 textColor">
          No company to display
        </div>
      )}
      {selectedCompany ? (
        <CommonModal
          modal={fileModal}
          modalHeader="Registration File"
          modalBody={
            <CustomFileViewer
              fileExtension={getRegistrationFile(selectedCompany)
                ?.split(".")
                .pop()}
              filePath={getRegistrationFile(selectedCompany)}
            />
          }
          size="xl"
          toggleModal={() => toggleFileModal(null)}
        />
      ) : null}
    </div>
  );
};

export default CompanyView;
