import React from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/hrm-loader.json";

const LoadingAnimation = (props) => {
  const { spinner, message } = props;
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const styleSetting = spinner
    ? {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "inherit",
      }
    : {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
      };

  return (
    <div style={styleSetting}>
      <Lottie options={lottieOptions} height={200} width={200} />
      {message && (
        <p
          className="text-center"
          style={{ flex: "initial", color: "#2b5364", fontWeight: "bold" }}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default LoadingAnimation;
