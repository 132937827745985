import React from "react";
import TermsandCondition from "../Company/TermsandCondition";

const CompanyGuidelineForm = ({
  toggleModal,
  getCompanyGuideline,
  companyGuideline,
  type,
  toggle,
}) => {
  return (
    <>
      <TermsandCondition
        isCG={true}
        toggleModal={toggleModal}
        getCompanyGuideline={getCompanyGuideline}
        companyGuideline={companyGuideline}
        type={type}
        toggle={toggle}
      />
    </>
  );
};

export default CompanyGuidelineForm;
